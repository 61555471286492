import {
  Autocomplete,
  Box,
  Button,
  Dialog,
  Divider,
  Grid,
  Tab,
  Tabs,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";
import MuiLink from "@mui/material/Link";
import { Link } from "react-router-dom";
import { matchPath, useLocation } from "react-router-dom";
import {
  convertTo12Hour,
  getURLWithQueryParams,
  isEmpty,
} from "../../../utils/utils";
import TrainingCard, { CourseType, TrainingData } from "./TrainingCard";
import MHSlider, {
  MHSliderItem,
} from "../../Common/SliderCarousel/Slider/MHSlider";
import MHButton from "../../Common/Button/MHButton";
import moment from "moment";
import { EmployeeData } from "../../../models/employee.model";
import { HttpResponse } from "../../../models/api.interface";
import AuthContext from "../../../store/context/auth-context";
import SnackbarContext from "../../../store/context/snackbar.context";
import useHttp from "../../../hooks/use-http";
import useDialog from "../../../hooks/use-dialog";
type Props = {
  selectedRole?: string | any;
  dataIn?: TrainingData[];
};
interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}
function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-module--tabpanel-${index}`}
      aria-labelledby={`simple-module--tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}
function a11yProps(index: number) {
  return {
    id: `simple-module--tab-${index}`,
    "aria-controls": `simple-module--tabpanel-${index}`,
  };
}
const ReusableTabPanel = ({
  value,
  trainingData,
  dashboardMatch,
}: {
  value?: string | any;
  trainingData?: string | any;
  dashboardMatch?: string | any;
}) => {
  const { openDialog, handleOpenDialog, handleCloseDialog } = useDialog();

  const [selectedChecks, setSelectedChecks] = React.useState<CourseType[]>([]);
  const handleAssignCheck = (course: any) => {
    console.log("course", course);
    setSelectedChecks((prevSelectedChecks) => {
      if (prevSelectedChecks.some((c) => c.id === course.id)) {
        return prevSelectedChecks.filter((c) => c.id !== course.id);
      } else {
        return [...prevSelectedChecks, course];
      }
    });
  };

  const authCtx = React.useContext(AuthContext);
  const { user } = authCtx;
  const snackbarCtx = React.useContext(SnackbarContext);
  const { toast } = snackbarCtx;
  const { loading, error, sendHttpRequest } = useHttp();

  const [employees, setEmployees] = React.useState<EmployeeData[]>([]);

  React.useEffect(() => {
    sendHttpRequest(
      getURLWithQueryParams(
        process.env.REACT_APP_API_BASE_URL + "/employee/employer_refId",
        {
          employerRefId: String(user?.employerRefId),
        }
      ),
      {
        method: "GET",
      },
      (response: HttpResponse<EmployeeData[]>) => {
        setEmployees(response.data);
      }
    );
  }, []);

  // console.log(employees);

  // const groupAssign = () => {
  //   const references = selectedChecks.map((course) => alert(course.reference));
  //   // alert(trainingData.title);

  //   // alert(selectedChecks);
  //   console.log("selectedChecks", selectedChecks);
  // };

  const url = process.env.REACT_APP_API_BASE_URL + `/training/employee`;

  const groupAssign = async (employee: any) => {
    try {
      const postRequests = selectedChecks.map(async (course) => {
        const requestBody = {
          reference: course.reference, // Assign the reference from the course
          employerRefId: Number(employee.employerRefId),
          customerId: Number(employee.uuid),
          uuid: Number(employee.uuid),
          firstName: employee.firstName,
          lastName: employee.lastName,
          attendanceStatus: "ASSIGNED",
          image: "https://image.png",
          date: course.createdDate,
          startDate: course.startDate,
          endDate: course.endDate,
        };

        console.log("requestBody", requestBody);

        const response = await fetch(url, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(requestBody),
        });

        if (!response.ok) {
          const errorData = await response.json();
          throw new Error(` ${errorData.error}`);
        }

        return response.json(); // Return the response data if needed
      });

      // Wait for all POST requests to complete
      await Promise.all(postRequests);

      // Handle success after all requests are done
      handleCloseDialog();
      toast({
        message: "Training assigned to Employee",
        variant: "success",
      });
    } catch (error) {
      console.error(error);
      handleCloseDialog();
      toast({
        message: `${error}`,
        variant: "error",
      });
    }
  };

  React.useEffect(() => {
    console.log("Selected checks updated:", selectedChecks);
  }, [selectedChecks]);
  return (
    <>
      <Grid container spacing={2} className="">
        <Grid item xs={12} md={4} className="flex justify-evenly">
          <Box
            className=" mx-auto lg:text-left text-center"
            alignSelf={"center"}
            width={{ xs: "100%", sm: "100%", md: "264px" }}
          >
            <Typography
              variant="h1"
              fontSize={{ xs: "28px", sm: "34px", md: "34px" }}
              lineHeight={{ xs: "29px", sm: "29px" }}
              width={{ xs: "250px", sm: "90%", md: "264px" }}
              textAlign={{ xs: "center", sm: "center", md: "start" }}
              className=" mx-auto text-center lg:text-start"
              color="primary"
              gutterBottom
            >
              Module
            </Typography>
            <Typography
              variant="caption"
              fontSize={{ xs: "14px", sm: "16px", md: "16px" }}
              lineHeight={{ xs: "29px", sm: "29px" }}
              width={{ xs: "250px", sm: "90%", md: "264px" }}
              textAlign={{ xs: "start", sm: "center", md: "start" }}
              className="mx-auto text-center lg:text-start"
              color="#A9A9A9"
              gutterBottom
            >
              Level {value}
            </Typography>
            <Typography
              variant="body2"
              fontSize={{ xs: "15px", sm: "17px", md: "17px" }}
              lineHeight={{ xs: "29px", sm: "29px" }}
              width={{ xs: "90%", sm: "90%", md: "264px" }}
              textAlign={{ xs: "center", sm: "center", md: "start" }}
              className=" mx-auto text-center lg:text-start"
              color="primary"
              gutterBottom
            >
              This module aims to introduce nannies and sitters to standard
              practices to better prepare them for their responsibilities
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} md={8} className="spaceEvenly">
          <Box
            mx={{ xs: "10px", sm: "40px" }}
            height={{ xs: "full", sm: "622px" }}
            width={"100%"}
            bgcolor={"#F9F9F7"}
            sx={{
              borderRadius: 3,
              border: 1,
              borderColor: "#D9D9D9",
            }}
            className=" mx-auto"
          >
            <Box padding={{ xs: 2, sm: 4 }}>
              <Box className=" centerItems" my={2}>
                <MuiLink
                  component={Link}
                  underline="always"
                  to="/organization/training/all"
                  // display="block"
                  // align="left"
                  className="text-blue-100 removeUnderline"
                >
                  View All
                </MuiLink>
              </Box>
              {trainingData.length < 1 ? (
                "Coming Soon"
              ) : (
                <MHSlider
                  slidesToShow={dashboardMatch ? 1 : 2}
                  slidesToScroll={1}
                >
                  {trainingData.map((course: any, index: any) => (
                    <MHSliderItem key={index} width="100%">
                      <TrainingCard
                        trainingMode={course.trainingMode}
                        title={course.title}
                        createdDate={course.date}
                        reference={course.reference}
                        startDate={convertTo12Hour(course.startDate)}
                        endDate={convertTo12Hour(course.endDate)}
                        objectives={course.objectives}
                        price={course.price}
                        handleChanger={() => handleAssignCheck(course)}
                      />
                    </MHSliderItem>
                  ))}
                </MHSlider>
              )}
            </Box>
            <Divider className=" my-4 w-full" />
            <Box className=" centerItems" height={{ xs: "65px", sm: "full" }}>
              <Box
                width={{
                  xs: "80%",
                  sm: "256px",
                }}
              >
                {trainingData.length < 1 ? (
                  <MHButton
                    color="primary"
                    sx={{ borderRadius: "5px" }}
                    type="submit"
                    className="  "
                    fullWidth
                    disabled={true}
                  >
                    Assign
                  </MHButton>
                ) : (
                  <MHButton
                    color="primary"
                    sx={{ borderRadius: "5px" }}
                    type="submit"
                    className="  "
                    fullWidth
                    onClick={handleOpenDialog}
                  >
                    Assign
                  </MHButton>
                )}
              </Box>
            </Box>
          </Box>
        </Grid>
      </Grid>
      <Dialog
        disableEscapeKeyDown
        open={openDialog}
        onClose={handleCloseDialog}
        sx={{ height: "100%" }}
      >
        {openDialog && (
          <Box
            height={{ xs: "full", sm: "100%" }}
            width={{ xs: "full", sm: "350px" }}
            p={5}
            mx={"auto"}
          >
            <Typography
              variant="subtitle1"
              fontSize={{ xs: "12px", sm: "14px", md: "14px" }}
              lineHeight={{ xs: "29px", sm: "29px" }}
              // width={{ xs: "300px", sm: "264px", md: "264px" }}
              textAlign={{ xs: "start", sm: "start" }}
              color="primary"
              className=""
              gutterBottom
            >
              Assign course(s) to Household
            </Typography>
            <Typography
              variant="subtitle2"
              fontSize={{ xs: "10px", sm: "12px", md: "12px" }}
              lineHeight={{ xs: "21px", sm: "21px" }}
              // width={{ xs: "300px", sm: "264px", md: "264px" }}
              textAlign={{ xs: "start", sm: "start" }}
              color="#A9A9A9"
              className=""
              gutterBottom
            >
              Search for staff by name and assign them to the selected course{" "}
            </Typography>
            <Autocomplete
              id="employee-select-1sdd"
              sx={{
                width: "fit",
                mt: 2,
                height: "100%",
              }}
              options={employees}
              autoHighlight
              getOptionLabel={(option) => option.firstName || ""}
              renderOption={(item, option) => (
                <Box
                  component="li"
                  //   height={{ xs: "200px", sm: "200px" }}
                  sx={{
                    display: "flex",
                    gap: 2,
                    justifyContent: "space-between",
                  }}
                  padding={2}
                  {...item}
                  className=" space-x-6"
                >
                  <Typography
                    variant="subtitle2"
                    fontSize={{ xs: "12px", sm: "12px", md: "12px" }}
                    lineHeight={{ xs: "21px", sm: "21px" }}
                    textAlign={{ xs: "start", sm: "start" }}
                    color="primary"
                    className=""
                    gutterBottom
                  >
                    {option.firstName}
                  </Typography>
                  <Button
                    // onClick={() => handleSingleAssign(option.uuid || 0)}
                    onClick={() => groupAssign(option)}
                    className="ring-1 removeUnderline"
                    sx={{ border: 1, borderRadius: 3, fontSize: "12px" }}
                  >
                    Assign
                  </Button>
                </Box>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={
                    <Typography
                      variant="subtitle2"
                      fontSize={{ xs: "10px", sm: "10px", md: "10px" }}
                      lineHeight={{ xs: "21px", sm: "21px" }}
                      // width={{ xs: "300px", sm: "264px", md: "264px" }}
                      textAlign={{ xs: "start", sm: "start" }}
                      color="#A9A9A9"
                      className=""
                      gutterBottom
                    >
                      Search for employee
                    </Typography>
                  }
                  inputProps={{
                    ...params.inputProps,
                    autoComplete: "employee-to-be-assigned", // disable autocomplete and autofill
                  }}
                  sx={{}}
                />
              )}
            />
          </Box>
        )}
      </Dialog>
    </>
  );
};

const TrainingModule = (props: Props) => {
  const [value, setValue] = React.useState(0);
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  const location = useLocation();
  const dashboardMatch = matchPath(location.pathname, {
    path: "/organization/dashboard",
    exact: true,
    strict: true,
  });
  let slicedAllTrainings = props.dataIn
    ? props.dataIn.filter((training) => {
        if (!training) return false; // Add a null check for training
        return (
          training.trainingMode &&
          training.category &&
          training.category.toLowerCase().includes(props.selectedRole) &&
          training.level === value
        );
      })
    : [];
  // console.log(props.dataIn);
  // console.log(props.selectedRole);
  // console.log("value", value);
  // console.log("slicedAllTrainings", slicedAllTrainings);

  return (
    <React.Fragment>
      {isEmpty(props.selectedRole) ? (
        ""
      ) : (
        <Box
          mx={{ xs: "10px", sm: "40px" }}
          height={{ xs: "full", md: "full" }}
          width={"100%"}
          bgcolor={"#F9F9F7"}
          padding={{ xs: 1, sm: 4 }}
          sx={{ borderRadius: 3, border: 1, borderColor: "#D2E0CB" }}
          className=" mx-auto"
        >
          <Typography
            variant="caption"
            color={"primary"}
            fontSize={{ xs: "16px", sm: "20px", md: "24px" }}
            lineHeight={{ xs: "100%", sm: "40px", md: "53px" }}
            textAlign={{ xs: "center", sm: "start" }}
            letterSpacing={"0.01em"}
            mb={2}
            className=" capitalize"
          >
            Outline Childcare Training for {props.selectedRole}
          </Typography>
          <Box sx={{ width: "100%" }} className="">
            <Tabs
              value={value}
              className="font-areaNorm"
              onChange={handleChange}
              sx={{ width: "100%", display: "flex", bgcolor: "#EEEEEE" }}
            >
              <Tab
                className={`text-xs md:text-sm font-areaExt capitalize leading-[102%] tracking-[0.04em] w-full  flex-1 ${
                  value === 0
                    ? "bg-[#194049] text-white"
                    : "bg-[#EEEEEE] text-[#194049]"
                }`}
                label="Onboarding"
                disableRipple
                {...a11yProps(0)}
              />
              <Tab
                className={`text-xs md:text-sm font-areaExt capitalize leading-[102%] tracking-[0.04em] w-full  flex-1 ${
                  value === 1
                    ? "bg-[#194049] text-white"
                    : "bg-[#EEEEEE] text-[#194049]"
                }`}
                label="Level 1"
                disableRipple
                {...a11yProps(1)}
              />
              <Tab
                className={`text-xs md:text-sm font-areaExt capitalize leading-[102%] tracking-[0.04em] w-full  flex-1 ${
                  value === 2
                    ? "bg-[#194049] text-white"
                    : "bg-[#EEEEEE] text-[#194049]"
                }`}
                label="Level 2"
                disableRipple
                {...a11yProps(2)}
              />
              {/* <Tab
                className={`text-xs md:text-sm font-areaExt capitalize leading-[102%] tracking-[0.04em] w-full  flex-1 ${
                  value === 3
                    ? "bg-[#194049] text-white"
                    : "bg-[#EEEEEE] text-[#194049]"
                }`}
                label="Level 3"
                disableRipple
                {...a11yProps(3)}
              /> */}
            </Tabs>
            <Box my={3}>
              <TabPanel value={value} index={0}>
                <ReusableTabPanel
                  value={value}
                  trainingData={slicedAllTrainings}
                  dashboardMatch={dashboardMatch}
                />
              </TabPanel>
              <TabPanel value={value} index={1}>
                <ReusableTabPanel
                  value={value}
                  trainingData={slicedAllTrainings}
                  dashboardMatch={dashboardMatch}
                />
              </TabPanel>
              <TabPanel value={value} index={2}>
                <ReusableTabPanel
                  value={value}
                  trainingData={slicedAllTrainings}
                  dashboardMatch={dashboardMatch}
                />
              </TabPanel>
              {/* <TabPanel value={value} index={3}>
                <ReusableTabPanel
                  value={value}
                  trainingData={slicedAllTrainings}
                  dashboardMatch={dashboardMatch}
                />
              </TabPanel> */}
            </Box>
          </Box>
        </Box>
      )}
    </React.Fragment>
  );
};
export default TrainingModule;
