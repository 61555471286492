import React from "react";
import { MHPagination } from "../../Common/UI/MHPagination";
import { Box, Typography } from "@mui/material";
import { testTraining } from "../../../utils/constants";
import { TrainProps } from "./AssignedTraining";

import { ReactComponent as CertificateOneIcon } from "../../../static/svg/cert-one.svg";
import { ReactComponent as CertificateTwoIcon } from "../../../static/svg/cert-two.svg";
import { ReactComponent as CertificateThreeIcon } from "../../../static/svg/cert-three.svg";
import moment from "moment";
import { EmployeeProps } from "./Profile";
import { TrainingData } from "../Training/TrainingCard";
import { HttpResponse } from "../../../models/api.interface";
import { getURLWithQueryParams } from "../../../utils/utils";
import useHttp from "../../../hooks/use-http";

const CertWidget = (props: TrainProps) => {
  // console.log(props);
  return (
    <Box className=" mt-4 ">
      <Box className=" flex items-center h-full  p-4 md:my-0 w-full relative lg:h-[101px] lg:w-[550px] border-2 border-[#D9D9D9] rounded-md ">
        {props.level === 0 ? (
          <CertificateThreeIcon />
        ) : props.level === 1 ? (
          <CertificateOneIcon />
        ) : props.level === 2 ? (
          <CertificateTwoIcon />
        ) : props.level === 3 ? (
          <CertificateTwoIcon />
        ) : null}
        <Box className=" grid ml-3">
          <Box className=" w-[43px] h-[16px] bg-[#EEEEEE] items-center flex justify-center ">
            <Typography
              variant="subtitle2"
              fontSize={{ xs: "9px", sm: "9px" }}
              lineHeight="150%"
              align="center"
              m={0}
              color="#A9A9A9"
              whiteSpace="nowrap" // Prevents text from wrapping
              overflow="hidden" // Hides any overflow
              textOverflow="ellipsis" // Shows an ellipsis (...) when text overflows
            >
              Level {props.level}
            </Typography>
          </Box>

          <Typography
            variant="subtitle1"
            fontSize={{ xs: "14px", sm: "14px" }}
            lineHeight="150%"
            align="left"
            m={0}
            color="primary"
            whiteSpace="nowrap" // Prevents text from wrapping
            overflow="hidden" // Hides any overflow
            textOverflow="ellipsis" // Shows an ellipsis (...) when text overflows
          >
            {props.title && props.title}
          </Typography>

          <Typography
            variant="subtitle2"
            fontSize={{ xs: "10px", sm: "10px" }}
            lineHeight="150%"
            align="left"
            m={0}
            color="primary"
            whiteSpace="nowrap" // Prevents text from wrapping
            overflow="hidden" // Hides any overflow
            textOverflow="ellipsis" // Shows an ellipsis (...) when text overflows
          >
            {props?.id && " REF:" + props?.id}
          </Typography>

          <Typography
            variant="subtitle2"
            fontSize={{ xs: "10px", sm: "10px" }}
            lineHeight="150%"
            align="left"
            m={0}
            color="primary"
            whiteSpace="nowrap" // Prevents text from wrapping
            overflow="hidden" // Hides any overflow
            textOverflow="ellipsis" // Shows an ellipsis (...) when text overflows
          >
            {moment(props.date && props.date).format("ddd MMMM D, YYYY")}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

const EmployeeCertificates = ({ employee }: EmployeeProps) => {
  const handlePageCertChange = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    setCurrentPager(value);
  };

  const [training, setTraining] = React.useState<TrainingData[]>([]);

  const { loading, error, sendHttpRequest: employeeTrainingReq } = useHttp();

  React.useEffect(() => {
    employeeTrainingReq(
      getURLWithQueryParams(
        process.env.REACT_APP_API_BASE_URL + "/training/employee/trainings",

        {
          customerId: String(employee?.customerId),
        }
      ),
      {
        method: "GET",
      },
      (response: HttpResponse<TrainingData[]>) => {
        setTraining(response.data);
      }
    );
  }, []);
  const attendedTraining =
    (training &&
      training.filter(
        (train) => train.attendanceStatus?.toLowerCase() === "attended"
      )) ||
    [];

  // Certificate Pagination
  const [currentPager, setCurrentPager] = React.useState(1);
  const [postsPerPager, setPostsPerPager] = React.useState(2);
  const lastPostIndexs = currentPager * postsPerPager;
  const firstPostIndexs = lastPostIndexs - postsPerPager;

  // Ensure attendedTraining is an array
  const currentPoster =
    attendedTraining.slice(firstPostIndexs, lastPostIndexs) || [];

  let itemsPerPage = 2;
  let noOfPage = Math.ceil((attendedTraining?.length || 0) / itemsPerPage);

  // console.log("training", training);
  // console.log("attendedTraining", attendedTraining);
  // console.log("employee", employee);

  return (
    <Box className=" h-full relative p-6 w-full lg:h-[380px] lg:w-[624px] overflow-hidden border-2 border-[#D9D9D9] rounded-md ">
      <Typography
        variant="subtitle1"
        fontSize={{ xs: "12px", sm: "14px" }}
        lineHeight="150%"
        align="left"
        color="primary"
      >
        Certificates
      </Typography>

      {/* {currentPoster.map((train) => (
        <CertWidget
          id={train.id}
          level={train.level}
          title={train.title}
          date={train.date}
          status={train.status}
        />
      ))} */}

      {currentPoster.map((train, index) => (
        <CertWidget
          key={index}
          id={train.Reference}
          level={train.Level}
          title={train.Title}
          date={train.Date}
          status={train.attendanceStatus}
        />
      ))}

      <Box className=" mt-6 lg:absolute bottom-2 left-0 right-0  lg:h-16">
        <Box display={"flex"} justifyContent={"center"} my={2}>
          <MHPagination
            count={noOfPage}
            variant="outlined"
            shape="rounded"
            page={currentPager}
            onChange={handlePageCertChange}
            color={"primary"}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default EmployeeCertificates;
