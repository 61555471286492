import { Avatar, Box, Button, Divider, Typography } from "@mui/material";
import React from "react";
import { ReactComponent as AvatarBadgeIcon } from "../../../static/svg/avatar-badge.svg";
import { ReactComponent as ClockIcon } from "../../../static/svg/clock.svg";
import { ReactComponent as ClocksIcon } from "../../../static/svg/clocks.svg";
import { ReactComponent as EditIcon } from "../../../static/svg/edit.svg";
import { EmployeeData } from "../../../models/employee.model";
import dayjs from "dayjs";
import MHButton from "../../Common/Button/MHButton";
import BucketAllocation from "./BucketAllocation";
import MHDialog from "../../Common/Dialog/MHDialog";
import useDialog from "../../../hooks/use-dialog";

type EmployeeProps = {
  employee: EmployeeData | null;
};
const ProfileSubHeader = ({ employee }: EmployeeProps) => {
  const { openDialog, handleOpenDialog, handleCloseDialog } = useDialog();

  let totalSalary =
    employee?.salary +
    employee?.Accomodation +
    employee?.airtimeOrData +
    employee?.meals +
    employee?.transportation;

  return (
    <React.Fragment>
      <Box className=" lg:h-[251px] h-full grid grid-cols-12 lg:flex gap-2 my-8 justCenter ml-o mr-0">
        <Box className=" relative  w-full md:w-[251px] col-span-12 md:col-span-4">
          <Avatar
            alt="Remy Sharp"
            variant="square"
            src="https://media.licdn.com/dms/image/D4E22AQHM9V2gh0gdQg/feedshare-shrink_800/0/1713882016047?e=1717632000&v=beta&t=qwUjlo4spoGPLcfGSMovJJVsuKqZ_SuwJFJxiev8w88"
            // sx={{ width: "251px", height: "251px" }}
            className=" rounded-md w-full md:w-[251px] h-[251px]"
          />
          <AvatarBadgeIcon className=" absolute bottom-2 right-2" />
        </Box>

        <Box className=" col-span-12 md:col-span-8 h-full p-4 md:my-0 w-full lg:w-[516px] lg:ml-3 border-2 border-[#D9D9D9] rounded-md ">
          <Box
            display={{ xs: "grid", sm: "flex" }}
            justifyContent={{ xs: "center", sm: "center" }}
            gap={2}
            my={{ xs: 0, sm: 4 }}
          >
            <Box display="" justifyContent="">
              <label htmlFor="">Role</label>
              <Box
                width={{ xs: "300px", sm: "163px", md: "163px" }}
                height="38px"
                bgcolor="#EEEEEE"
                display="flex"
                justifyContent="start"
                alignItems="left"
                overflow="hidden" // Prevents content overflow from floating out of the box
              >
                <Button disabled>
                  <Typography
                    variant="subtitle2"
                    fontSize={{ xs: "12px", sm: "12px" }}
                    className=" capitalize"
                    lineHeight="150%"
                    align="left"
                    color="#C8C8C8"
                    whiteSpace="nowrap" // Prevents text from wrapping
                    overflow="hidden" // Hides any overflow
                    // textOverflow="ellipsis" // Shows an ellipsis (...) when text overflows
                  >
                    {employee?.jobTitle}
                  </Typography>
                </Button>
              </Box>
            </Box>
            <Box display="" justifyContent="center">
              <label htmlFor="">Date Onboarded</label>
              <Box
                width={{ xs: "300px", sm: "163px", md: "163px" }}
                height="38px"
                bgcolor="#EEEEEE"
                display="flex"
                justifyContent="start"
                alignItems="left"
                overflow="hidden" // Prevents content overflow from floating out of the box
              >
                <Button disabled>
                  <Typography
                    variant="subtitle2"
                    className=" capitalize"
                    fontSize={{ xs: "12px", sm: "12px" }}
                    lineHeight="150%"
                    align="left"
                    color="#C8C8C8"
                    whiteSpace="nowrap" // Prevents text from wrapping
                    overflow="hidden" // Hides any overflow
                    textOverflow="ellipsis" // Shows an ellipsis (...) when text overflows
                  >
                    {dayjs(employee?.createdDate).format("MMMM D, YYYY")}
                  </Typography>
                </Button>
              </Box>
            </Box>
          </Box>
          <Box
            display={{ xs: "grid", sm: "flex" }}
            justifyContent={{ xs: "center", sm: "center" }}
            gap={2}
          >
            <Box display="" justifyContent="center">
              <label htmlFor="">Phone Number</label>
              <Box
                width={{ xs: "300px", sm: "163px", md: "163px" }}
                height="38px"
                bgcolor="#EEEEEE"
                display="flex"
                justifyContent="start"
                alignItems="left"
                overflow="hidden" // Prevents content overflow from floating out of the box
              >
                <Button disabled>
                  <Typography
                    variant="subtitle2"
                    className=" capitalize"
                    fontSize={{ xs: "12px", sm: "12px" }}
                    lineHeight="150%"
                    align="left"
                    color="#C8C8C8"
                    whiteSpace="nowrap" // Prevents text from wrapping
                    overflow="hidden" // Hides any overflow
                    textOverflow="ellipsis" // Shows an ellipsis (...) when text overflows
                  >
                    {employee?.telephone}
                  </Typography>
                </Button>
              </Box>
            </Box>
            <Box display="" justifyContent="center">
              <label htmlFor="">Location</label>
              <Box
                width={{ xs: "300px", sm: "163px", md: "163px" }}
                height="38px"
                bgcolor="#EEEEEE"
                display="flex"
                justifyContent="start"
                alignItems="left"
                overflow="hidden" // Prevents content overflow from floating out of the box
              >
                <Button disabled>
                  <Typography
                    variant="subtitle2"
                    className=" capitalize"
                    fontSize={{ xs: "12px", sm: "12px" }}
                    lineHeight="150%"
                    align="left"
                    color="#C8C8C8"
                    whiteSpace="nowrap" // Prevents text from wrapping
                    overflow="hidden" // Hides any overflow
                    textOverflow="ellipsis" // Shows an ellipsis (...) when text overflows
                  >
                    {employee?.stateOfOrigin}
                  </Typography>
                </Button>
              </Box>
            </Box>
          </Box>
        </Box>

        <Box className=" col-span-12 md:col-span-12 grid h-full w-full lg:w-[516px] lg:ml-3 border-2 border-[#D9D9D9] rounded-md overflow-hidden">
          <Box className=" h-[171px] w-full  p-4 rounded-t-md bg-[#F9F9F7] border-b-[2px] border-[#D9D9D9]">
            <Box display="" alignItems="center" justifyContent="center" gap={2}>
              <Box className="  mx-auto my-2 justify-between">
                <Typography
                  variant="subtitle1"
                  fontSize={{ xs: "12px", sm: "12px" }}
                  lineHeight="150%"
                  align="left"
                  color="primary"
                  whiteSpace="nowrap" // Prevents text from wrapping
                  overflow="hidden" // Hides any overflow
                  textOverflow="ellipsis" // Shows an ellipsis (...) when text overflows
                >
                  Salary
                </Typography>

                <Typography
                  variant="subtitle2"
                  fontSize={{ xs: "12px", sm: "12px" }}
                  lineHeight="150%"
                  align="left"
                  color="primary"
                  whiteSpace="nowrap" // Prevents text from wrapping
                  overflow="hidden" // Hides any overflow
                  textOverflow="ellipsis" // Shows an ellipsis (...) when text overflows
                >
                  Choose how you prefer to pay household staff
                </Typography>
              </Box>

              <Box className=" flex mx-auto mt-2 justify-between ">
                <Box
                  className={` rounded-md w-fit lg:w-[189px] h-[63px] ${
                    employee?.payFrequency === "Bi-Weekly"
                      ? "bg-[#F5FBFF] border-[1px] border-[#194049]"
                      : "bg-[#EEEEEE] border-[1px] border-[#EEEEEE]"
                  }`}
                >
                  <Typography
                    variant="subtitle2"
                    fontSize={{ xs: "12px", sm: "12px" }}
                    lineHeight="150%"
                    display="flex"
                    align="center"
                    gap={1}
                    padding={3.5}
                    color={`${
                      employee?.payFrequency === "Bi-Weekly"
                        ? "primary"
                        : "#A9A9A9"
                    }`}
                    whiteSpace="nowrap" // Prevents text from wrapping
                    overflow="hidden" // Hides any overflow
                    textOverflow="ellipsis" // Shows an ellipsis (...) when text overflows
                  >
                    {employee?.payFrequency === "Bi-Weekly" ? (
                      <ClockIcon />
                    ) : (
                      <ClocksIcon />
                    )}
                    Bi-weekly
                  </Typography>
                </Box>
                <Box
                  className={` rounded-md w-fit lg:w-[189px] h-[63px] ${
                    employee?.payFrequency === "Monthly"
                      ? "bg-[#F5FBFF] border-[1px] border-[#194049]"
                      : "bg-[#EEEEEE] border-[1px] border-[#EEEEEE]"
                  }`}
                >
                  <Typography
                    variant="subtitle2"
                    fontSize={{ xs: "12px", sm: "12px" }}
                    lineHeight="150%"
                    display="flex"
                    align="center"
                    gap={1}
                    padding={3.5}
                    color={`${
                      employee?.payFrequency === "Monthly"
                        ? "primary"
                        : "#A9A9A9"
                    }`}
                    whiteSpace="nowrap" // Prevents text from wrapping
                    overflow="hidden" // Hides any overflow
                    textOverflow="ellipsis" // Shows an ellipsis (...) when text overflows
                  >
                    {employee?.payFrequency === "Monthly" ? (
                      <ClockIcon />
                    ) : (
                      <ClocksIcon />
                    )}
                    Monthly
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>
          <Box
            className=" h-fit py-2 lg:h-[81px] w-full rounded-b-md bg-[#fff]"
            my={2}
          >
            <Box
              display={"flex"}
              justifyContent={"center"}
              width={"full"}
              sx={{ gap: 2 }}
            >
              <Box
                width="163px"
                height="32px"
                bgcolor="#EEEEEE"
                display="flex"
                justifyContent="start"
                alignItems="left"
                className=" rounded-md"
                overflow="hidden" // Prevents content overflow from floating out of the box
              >
                <Box
                  className=" w-[30px] bg-[#D9D9D9] text-[#A9A9A9]"
                  py={0.5}
                  px={1}
                >
                  ₦
                </Box>
                <Button disabled>
                  <Typography
                    variant="subtitle2"
                    fontSize={{ xs: "12px", sm: "12px" }}
                    lineHeight="150%"
                    align="left"
                    color="#C8C8C8"
                    whiteSpace="nowrap" // Prevents text from wrapping
                    overflow="hidden" // Hides any overflow
                    // textOverflow="ellipsis" // Shows an ellipsis (...) when text overflows
                  >
                    {totalSalary}
                  </Typography>
                </Button>
              </Box>

              <Box className=" " width={{ xs: "100px", sm: "189px" }}>
                <MHButton
                  sx={{}}
                  onClick={handleOpenDialog}
                  fullWidth
                  startIcon={<EditIcon />}
                  className=" w-[100px]  md:w-[189px] h-[32px]  rounded-md"
                >
                  Edit
                </MHButton>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>

      <MHDialog
        open={openDialog}
        title={"Update Payment "}
        sx={{ textAlign: "center" }}
        handleClose={handleCloseDialog}
        scroll="paper"
        maxWidth={"xs"}
        fullWidth
      >
        {openDialog ? (
          <Box p={2}>
            <BucketAllocation employee={employee} onClose={handleCloseDialog} />
          </Box>
        ) : null}
      </MHDialog>
    </React.Fragment>
  );
};

export default ProfileSubHeader;
