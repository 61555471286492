import React, { useEffect, useState } from "react";
import ProfileSubHeader from "./ProfileSubHeader";
import { Box, Button, Divider, Grid, Tooltip, Typography } from "@mui/material";
import { EmployeeData } from "../../../models/employee.model";
import MHButton from "../../Common/Button/MHButton";

import { ReactComponent as VerifiedIcon } from "../../../static/svg/status/check-verified.svg";
import { ReactComponent as DisabledIcon } from "../../../static/svg/status/check-canceled.svg";
import { ReactComponent as WarningIcon } from "../../../static/svg/status/check-pending.svg";
import { ReactComponent as EmptyStateIcon } from "../../../static/svg/status/check-empty.svg";
import { ReactComponent as InfoIcon } from "../../../static/svg/status/check-info.svg";

import useDialog from "../../../hooks/use-dialog";
import MHCheckboxContent from "../../Common/Form/MHCheckboxContent";
import AssignedTraining from "./AssignedTraining";
import EmployeeCertificates from "./EmployeeCertificates";
import useInput from "../../../hooks/use-input";
import * as validators from "../../../utils/validators";
import RunCheckDialog from "../../Common/Dialog/RunCheckDialog";
import SnackbarContext from "../../../store/context/snackbar.context";
import { ASSESSDATA } from "../../../utils/assessment";
import { formatAmount, getURLWithQueryParams } from "../../../utils/utils";
import useHttp from "../../../hooks/use-http";
import { BackgroundData, HealthData } from "../../../models/assessment.model";
import { HttpResponse } from "../../../models/api.interface";

type EmployeeProps = {
  employee: EmployeeData | null;
};

interface CheckState {
  [key: string]: boolean;
}

interface CheckWidgetProps {
  tipText: string;
  status: string;
  label: string;
  verified: boolean | undefined;
}

type BgAmount = {
  [key: string]: number; // Keys are strings, values are numbers
};

const CheckWidget: React.FC<CheckWidgetProps> = ({
  status,
  verified,
  label,
  tipText,
}) => (
  <Box
    className="grid"
    sx={{
      width: "100%",
    }}
    justifyContent="center"
    justifyItems="center"
    alignContent="center"
    gap={1.5}
  >
    <Box justifyContent="center" justifyItems="center" alignContent="center">
      {status.toLocaleLowerCase() === "done" && verified === true ? (
        <VerifiedIcon />
      ) : status.toLocaleLowerCase() === "done" && verified === false ? (
        <DisabledIcon />
      ) : status.toLocaleLowerCase() === "inprogress" ? (
        <WarningIcon />
      ) : (
        <EmptyStateIcon />
      )}
    </Box>

    <Typography
      variant="subtitle2"
      fontSize={{ xs: "10px", sm: "12px" }}
      lineHeight="120%"
      align="center"
      // color={status ? "primary" : "#A9A9A9"}
      color={
        status.toLocaleLowerCase().includes("done") ? "primary" : "#A9A9A9"
      }
      // whiteSpace="nowrap"
      // overflow="hidden"
      // textOverflow="ellipsis"
      className=" text-center w-[80%]"
    >
      {label}
    </Typography>
    <Tooltip
      title={tipText}
      enterTouchDelay={0} // Show tooltip immediately on touch
      leaveTouchDelay={5000} // Hide tooltip after 3 seconds
    >
      <Button>
        <InfoIcon />
      </Button>
    </Tooltip>
  </Box>
);

export const bgArray = [
  {
    label: "ID Verification",
    amount: 2000,
    location: "bgCheck.id",
    place: "id",
  },
  {
    label: "Address Verification",
    amount: 5000,
    location: "bgCheck.address",
    place: "address",
  },
  {
    label: "Guarantor Verification",
    amount: 4000,
    location: "bgCheck.guarantor",
    place: "guarantor",
  },
  {
    label: "Employment Verification",
    amount: 4000,
    location: "bgCheck.employment",
    place: "employment",
  },
  {
    label: "Education Verification",
    amount: 4000,
    location: "bgCheck.education",
    place: "education",
  },
];

const SummaryView = ({ employee }: EmployeeProps) => {
  const snackbarCtx = React.useContext(SnackbarContext);
  const { toast } = snackbarCtx;

  const { openDialog, handleOpenDialog, handleCloseDialog } = useDialog();
  const {
    openDialog: openDialog2,
    handleOpenDialog: handleOpenDialog2,
    handleCloseDialog: handleCloseDialog2,
  } = useDialog();

  // const [clickState, setClickState] = useState(false);

  const [healthCheck, setHealthCheck] = useState<CheckState>({
    medic: false,
    vaccine: false,
    disease: false,
    vision: false,
    drug: false,
  });

  const [bgCheck, setBgCheck] = useState<CheckState>({
    id: false,
    address: false,
    guarantor: false,
    employment: false,
    education: false,
  });

  React.useEffect(() => {}, [healthCheck, bgCheck]);

  const checker = "pending";

  const {
    value: enteredHealth,
    valid: enteredHealthIsValid,
    error: healthInputHasError,
    onChange: healthInputChangeHandler,
    onBlur: healthInputBlurHandler,
  } = useInput([
    {
      validator: (value: string) => validators.required(value),
    },
  ]);

  // console.log("bgCheck", bgCheck);
  // console.log("healthCheck", healthCheck);

  const [healthAmount, setHealthAmount] = useState(0);
  const [healthText, setHealthText] = useState("");
  const initialBgAmount: BgAmount = bgArray.reduce((acc, item) => {
    acc[item.place] = 0; // Initialize all with 0
    return acc;
  }, {} as BgAmount); // Explicitly cast to BgAmount

  const [bgAmount, setBgAmount] = useState<BgAmount>(initialBgAmount);

  // console.log("bgAmount", bgAmount);

  const resetBgState = () => {
    // Reset other state
    setBgCheck({
      id: false,
      address: false,
      guarantor: false,
      employment: false,
      education: false,
    });

    // Reset bgAmount values to 0
    setBgAmount((prevBgAmount) => {
      const resetBg = Object.keys(prevBgAmount).reduce((acc, key) => {
        acc[key] = 0;
        return acc;
      }, {} as BgAmount);

      return resetBg;
    });
  };

  const selectedTest = ASSESSDATA.filter((item) => {
    if (item.value === employee?.jobTitle) {
      return true; // Include the item if it matches the job title
    }
    return item.value === "other"; // Fallback to "other" if no match
  });

  let employeeTest = selectedTest[0];
  let testArr = employeeTest.text.split("##");

  const reqHealth = {
    customerId: employee?.customerId,
    employerRefId: employee?.employerRefId,
    firsName: employee?.firstName,
    lastName: employee?.lastName,
    vaccination: false,
    vaccinationCost: 0,
    communicableDiseaseTest: false,
    commDiseaseTestCost: 0,
    vasionScreenTest: false,
    vasionScreenTestCost: 0,
    drugTest: false,
    drugTestCost: 0,
    medHistoryCheck: employeeTest.amount > 0 ? true : false,
    medHistoryCheckCost: employeeTest.amount,
    medicalStatus: "INPROGRESS",
    description: employeeTest.text,
  };

  const requestBackg = {
    customerId: employee?.customerId,
    employerRefId: employee?.employerRefId,
    firsName: employee?.firstName,
    lastName: employee?.lastName,
    newRequest: true,

    idCheck: bgAmount.id > 0 ? true : false,
    idCost: bgAmount.id,
    idType: "",

    addressCheck: bgAmount.address > 0 ? true : false,
    addressCost: bgAmount.address,

    educationCheck: bgAmount.education > 0 ? true : false,
    educationCost: bgAmount.education,

    employmentCheck: bgAmount.employment > 0 ? true : false,
    employmentCost: bgAmount.employment,

    guarantorCheck: bgAmount.guarantor > 0 ? true : false,
    guarantorCost: bgAmount.guarantor,
  };

  const handleBgVerification = async () => {
    const url = process.env.REACT_APP_API_BASE_URL + `/background-check/store`;

    // console.log("reqBackg", requestBackg);
    try {
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(requestBackg),
      });

      if (response.ok) {
        // Handle success
        const successData = await response.json();
        resetBgState();

        handleCloseDialog2();
        toast({
          variant: "success",
          message: "Background check request successful!",
        });
      } else {
        resetBgState();

        handleCloseDialog2();
        const errorData = await response.json();
        console.error("Request submission failed:", errorData);
        toast({
          variant: "error",
          message: `${errorData.error}`,
        });
      }
    } catch (error) {
      handleCloseDialog2();
      console.error("An error occurred:", error);
      toast({
        variant: "error",
        message: " An error occurred",
      });
    }
  };
  const handleHealthVerification = async () => {
    const url =
      process.env.REACT_APP_API_BASE_URL + `/background-check/health/store`;

    console.log("reqHealth", reqHealth);
    try {
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(reqHealth),
      });

      if (response.ok) {
        // Handle success
        const successData = await response.json();
        handleCloseDialog();
        toast({
          variant: "success",
          message: "Health verification request successful!",
        });
      } else {
        handleCloseDialog();
        const errorData = await response.json();
        console.error("Request submission failed:", errorData);
        toast({
          variant: "error",
          message: `${errorData.error}`,
        });
      }
    } catch (error) {
      handleCloseDialog();
      console.error("An error occurred:", error);
      toast({
        variant: "error",
        message: " An error occurred",
      });
    }
  };

  const bgChecker = () => {
    // console.log("reqBackg", reqBackg);
    handleBgVerification();
  };
  const healthChecker = () => {
    // console.log("reqHealth", reqHealth);
    handleHealthVerification();
    // console.log("Health Assessment For", enteredHealth);
    // console.log("Health Assessment Amount", healthAmount);
  };

  // console.log("employee", employee);
  // console.log("employeeTest", employeeTest);
  // console.log("testArr", testArr);

  // GET MEDICAL HEALTH STATUS

  const { sendHttpRequest } = useHttp();

  const [healthData, setHealthData] = React.useState<HealthData[]>([]);

  React.useEffect(() => {
    sendHttpRequest(
      getURLWithQueryParams(
        process.env.REACT_APP_API_BASE_URL + "/background-check/health/getby",
        {
          customerId: String(employee?.customerId),
        }
      ),
      {
        method: "GET",
      },
      (response: HttpResponse<HealthData[]>) => {
        setHealthData(response.data);
      }
    );
  }, []);

  const healthCheckResult =
    healthData && healthData[0]
      ? healthData[0].medicalStatus === ""
        ? "empty"
        : healthData[0].medicalStatus || "undefined" // Return medicalStatus if defined, otherwise "undefined"
      : "undefined"; // If healthData or healthData[0] is undefined

  // GET BACKGROUND CHECK STATUS
  const { sendHttpRequest: sendBgHttpReq } = useHttp();

  const [backGroundData, setBackGroundData] = React.useState<BackgroundData[]>(
    []
  );

  React.useEffect(() => {
    sendBgHttpReq(
      getURLWithQueryParams(
        process.env.REACT_APP_API_BASE_URL + "/background-check/getby",
        {
          customerId: String(employee?.customerId),
        }
      ),
      {
        method: "GET",
      },
      (response: HttpResponse<BackgroundData[]>) => {
        setBackGroundData(response.data);
      }
    );
  }, []);

  const bgFinalResultRecord =
    backGroundData &&
    backGroundData.filter((bgFinalData) => bgFinalData.finalResult === true);

  const [bgCheckResults, setBgCheckResults] = useState({
    idStatus: "undefined",
    idComment: "no comments",
    addressVerificationStatus: "undefined",
    addressVerificationComment: "no comments",
    guarantorVerificationStatus: "undefined",
    guarantorVerificationComment: "no comments",
    employmentVerificationStatus: "undefined",
    employmentVerificationComment: "no comments",
    educationVerificationStatus: "undefined",
    educationVerificationComment: "no comments",
  });

  useEffect(() => {
    if (
      !Array.isArray(bgFinalResultRecord) ||
      bgFinalResultRecord.length === 0
    ) {
      setBgCheckResults({
        idStatus: "undefined",
        idComment: "no comments",
        addressVerificationStatus: "undefined",
        addressVerificationComment: "no comments",
        guarantorVerificationStatus: "undefined",
        guarantorVerificationComment: "no comments",
        employmentVerificationStatus: "undefined",
        employmentVerificationComment: "no comments",
        educationVerificationStatus: "undefined",
        educationVerificationComment: "no comments",
      });
      return;
    }

    const result = {
      idStatus:
        bgFinalResultRecord[0].idStatus === ""
          ? "disabled"
          : bgFinalResultRecord[0].idStatus,
      idComment:
        bgFinalResultRecord[0].idComment === ""
          ? "no comment"
          : bgFinalResultRecord[0].idComment,

      addressVerificationStatus:
        bgFinalResultRecord[0].addressVerificationStatus === ""
          ? "disabled"
          : bgFinalResultRecord[0].addressVerificationStatus,
      addressVerificationComment:
        bgFinalResultRecord[0].addressVerificationComment === ""
          ? "no comment"
          : bgFinalResultRecord[0].addressVerificationComment,

      guarantorVerificationStatus:
        bgFinalResultRecord[0].guarantorVerificationStatus === ""
          ? "disabled"
          : bgFinalResultRecord[0].guarantorVerificationStatus,
      guarantorVerificationComment:
        bgFinalResultRecord[0].guarantorVerificationComment === ""
          ? "no comment"
          : bgFinalResultRecord[0].guarantorVerificationComment,

      employmentVerificationStatus:
        bgFinalResultRecord[0].employmentVerficationStatus === ""
          ? "disabled"
          : bgFinalResultRecord[0].employmentVerficationStatus,
      employmentVerificationComment:
        bgFinalResultRecord[0].employmentVerficationComment === ""
          ? "no comment"
          : bgFinalResultRecord[0].employmentVerficationComment,

      educationVerificationStatus:
        bgFinalResultRecord[0].educationVericicationStatus === ""
          ? "disabled"
          : bgFinalResultRecord[0].educationVericicationStatus,
      educationVerificationComment:
        bgFinalResultRecord[0].educationVerificationComment === ""
          ? "no comment"
          : bgFinalResultRecord[0].educationVerificationComment,
    };

    setBgCheckResults(result);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [backGroundData]);

  // console.log("bgFinalResultRecord", bgFinalResultRecord);
  // console.log("backGroundData", backGroundData);

  // console.log("healthData", healthData);
  // console.log("healthCheckResult", healthCheckResult);

  return (
    <React.Fragment>
      <Divider />

      <ProfileSubHeader employee={employee} />

      <Grid container spacing={2}>
        <Grid item xs={12} sm={3}>
          <Box className=" border-2 mt-4 lg:h-[204px] border-[#D9D9D9] rounded-md overflow-hidden">
            <Box className=" justify-between md:justify-between flex items-center px-4 md:px-2 w-full h-[64px] rounded-t-md bg-[#F9F9F7] border-b-[2px] border-[#D9D9D9]">
              <Typography
                variant="subtitle1"
                fontSize={{ xs: "14px", sm: "14px" }}
                lineHeight="150%"
                align="left"
                color="primary"
                whiteSpace="nowrap" // Prevents text from wrapping
                overflow="hidden" // Hides any overflow
                textOverflow="ellipsis" // Shows an ellipsis (...) when text overflows
                // className="  justify-start"
              >
                Health Check
              </Typography>
              <Box className=" " width={{ xs: "full", sm: "189px" }}>
                <MHButton
                  sx={{}}
                  onClick={handleOpenDialog}
                  fullWidth
                  //   startIcon={<EditIcon />}
                  className=" w-[120px] md:w-fit  lg:w-[150px] h-[32px] md:p-2  rounded-md"
                >
                  Run Check
                </MHButton>
              </Box>
            </Box>
            <Box
              bgcolor={"#fff"}
              className=" hidden md:flex md:justify-evenly items-center p-4 md:p-0 h-full md:h-[140px]"
            >
              <CheckWidget
                tipText=""
                status={healthCheckResult}
                verified={
                  healthData && healthData[0]
                    ? healthData[0].medHistoryCheck
                    : false
                } // Add a fallback to prevent errors
                label="Medical History & Physical Examination"
              />
            </Box>

            <Box bgcolor={"#fff"} className=" flex md:hidden lg:hidden py-2">
              <CheckWidget
                tipText=""
                status={healthCheckResult}
                verified={
                  healthData && healthData[0]
                    ? healthData[0].medHistoryCheck
                    : false
                } // Add a fallback to prevent errors
                label="Medical History & Physical Examination"
              />
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} sm={9}>
          <Box className=" border-2 mt-4 lg:h-[204px] border-[#D9D9D9] rounded-md overflow-hidden">
            <Box className=" justify-around md:justify-between flex items-center md:px-4 w-full h-[64px] rounded-t-md bg-[#F9F9F7] border-b-[2px] border-[#D9D9D9]">
              <Typography
                variant="subtitle1"
                fontSize={{ xs: "14px", sm: "14px" }}
                lineHeight="150%"
                align="left"
                color="primary"
                whiteSpace="nowrap" // Prevents text from wrapping
                overflow="hidden" // Hides any overflow
                textOverflow="ellipsis" // Shows an ellipsis (...) when text overflows
                // className="  justify-start"
              >
                Background Check
              </Typography>
              <Box className=" " width={{ xs: "full", sm: "189px" }}>
                <MHButton
                  sx={{}}
                  onClick={handleOpenDialog2}
                  fullWidth
                  //   startIcon={<EditIcon />}
                  className=" w-[120px]  md:w-[189px] h-[32px]  rounded-md"
                >
                  Run Check
                </MHButton>
              </Box>
            </Box>

            <Box
              bgcolor={"#fff"}
              className=" hidden md:flex md:justify-evenly items-center p-4 md:p-0 h-full md:h-[140px]"
            >
              <CheckWidget
                verified={
                  bgFinalResultRecord && bgFinalResultRecord[0]
                    ? bgFinalResultRecord[0].idVerified
                    : false
                }
                tipText={bgCheckResults.idComment}
                status={bgCheckResults.idStatus}
                label="ID Verification"
              />
              <CheckWidget
                verified={
                  bgFinalResultRecord && bgFinalResultRecord[0]
                    ? bgFinalResultRecord[0].addressVerified
                    : false
                }
                tipText={bgCheckResults.addressVerificationComment}
                status={bgCheckResults.addressVerificationStatus}
                label="Address Verification"
              />
              <CheckWidget
                verified={
                  bgFinalResultRecord && bgFinalResultRecord[0]
                    ? bgFinalResultRecord[0].guarantorVerified
                    : false
                }
                tipText={bgCheckResults.guarantorVerificationComment}
                status={bgCheckResults.guarantorVerificationStatus}
                label="Guarantor Verification"
              />
              <CheckWidget
                verified={
                  bgFinalResultRecord && bgFinalResultRecord[0]
                    ? bgFinalResultRecord[0].employmentVerified
                    : false
                }
                tipText={bgCheckResults.employmentVerificationComment}
                status={bgCheckResults.employmentVerificationStatus}
                label="Employment Verification"
              />
              <CheckWidget
                verified={
                  bgFinalResultRecord && bgFinalResultRecord[0]
                    ? bgFinalResultRecord[0].educationVerfied
                    : false
                }
                tipText={bgCheckResults.educationVerificationComment}
                status={bgCheckResults.educationVerificationStatus}
                label="Education Verification"
              />
            </Box>
            <Box bgcolor={"#fff"} className=" md:hidden lg:hidden flex py-2">
              <CheckWidget
                verified={
                  bgFinalResultRecord && bgFinalResultRecord[0]
                    ? bgFinalResultRecord[0].idVerified
                    : false
                }
                tipText={bgCheckResults.idComment}
                status={bgCheckResults.idStatus}
                label="ID Verification"
              />
              <CheckWidget
                verified={
                  bgFinalResultRecord && bgFinalResultRecord[0]
                    ? bgFinalResultRecord[0].addressVerified
                    : false
                }
                tipText={bgCheckResults.addressVerificationComment}
                status={bgCheckResults.addressVerificationStatus}
                label="Address Verification"
              />
            </Box>
            <Box bgcolor={"#fff"} className=" md:hidden lg:hidden flex py-2">
              <CheckWidget
                verified={
                  bgFinalResultRecord && bgFinalResultRecord[0]
                    ? bgFinalResultRecord[0].guarantorVerified
                    : false
                }
                tipText={bgCheckResults.guarantorVerificationComment}
                status={bgCheckResults.guarantorVerificationStatus}
                label="Guarantor Verification"
              />
              <CheckWidget
                verified={
                  bgFinalResultRecord && bgFinalResultRecord[0]
                    ? bgFinalResultRecord[0].employmentVerified
                    : false
                }
                tipText={bgCheckResults.employmentVerificationComment}
                status={bgCheckResults.employmentVerificationStatus}
                label="Employment Verification"
              />
            </Box>
            <Box bgcolor={"#fff"} className=" md:hidden lg:hidden flex py-2">
              <CheckWidget
                verified={
                  bgFinalResultRecord && bgFinalResultRecord[0]
                    ? bgFinalResultRecord[0].educationVerfied
                    : false
                }
                tipText={bgCheckResults.educationVerificationComment}
                status={bgCheckResults.educationVerificationStatus}
                label="Education Verification"
              />
            </Box>
          </Box>
        </Grid>
      </Grid>

      {/* Training & Certificates */}
      <Box className="  lg:flex lg:justify-between my-4 ">
        <AssignedTraining employee={employee} />
        <EmployeeCertificates employee={employee} />
      </Box>
      {/*  */}

      <RunCheckDialog
        open={openDialog}
        title={`Health Check`}
        handleClose={handleCloseDialog}
      >
        <Box p={2}>
          <Box
            className={`h-auto ring-1 ring-[#009688] rounded-lg py-6 px-2 mb-3 md:mb-0`}
            // ${
            //   clickState === false
            //     ? "grayscale-0 opacity-100"
            //     : "grayscale opacity-50"
            // }
          >
            <Typography
              variant="h1"
              fontSize={{ xs: "18px", sm: "20px", md: "20px" }}
              component="div"
              gutterBottom
              mb={3}
              className=" capitalize"
            >
              {/* Health Assessment */}
              {employee?.jobTitle}
            </Typography>
            <Typography
              variant="body1"
              fontSize={{ xs: "13px", sm: "13px", md: "13px" }}
              lineHeight={{ xs: "22px", sm: "22px" }}
              component="div"
              gutterBottom
              mb={5}
            >
              Health Assessments are important to safeguard your loved ones, and
              verify your household employee's fitness to work.
            </Typography>

            <Typography
              variant="caption"
              fontSize={{ xs: "14px", sm: "16px", md: "16px" }}
              component="div"
              gutterBottom
              mb={3}
              className=" capitalize text-center"
            >
              Overview
            </Typography>

            <Grid
              container
              spacing={{ xs: 0, md: 2, mb: 5 }}
              sx={{ px: { xs: 0, sm: 2 } }}
            >
              <Grid item xs={12} md={6}>
                {testArr
                  .filter((item: any, index) => index % 2 === 0)
                  .map((item: any) => (
                    <Typography
                      variant="body1"
                      fontSize={{ xs: "14px", sm: "14px", md: "14px" }}
                      lineHeight={{ xs: "22px", sm: "22px" }}
                      component="div"
                      gutterBottom
                      mb={1}
                    >
                      ~ {item}
                    </Typography>
                  ))}
              </Grid>

              <Grid item xs={12} md={6}>
                {testArr
                  .filter((item: any, index) => index % 2 !== 0)
                  .map((item: any) => (
                    <Typography
                      variant="body1"
                      fontSize={{ xs: "14px", sm: "14px", md: "14px" }}
                      lineHeight={{ xs: "22px", sm: "22px" }}
                      component="div"
                      gutterBottom
                      mb={1}
                    >
                      ~ {item}
                    </Typography>
                  ))}
              </Grid>
            </Grid>
            {/* <Typography
              variant="caption"
              fontSize={{ xs: "15px", sm: "15px", md: "15px" }}
              lineHeight={{ xs: "22px", sm: "22px" }}
              component="div"
              gutterBottom
              mb={1}
              className=" absolute bottom-1 right-1"
            >
              Cost: {employeeTest.amount}
            </Typography> */}

            <Box
              mt={4}
              mx={{ xs: 2, md: 8 }}
              // width="193px"
              p={2}
              borderRadius={2}
              bgcolor="#D2E0CB"
              display="flex"
              justifyContent="center"
              alignItems="center"
              overflow="hidden" // Prevents content overflow from floating out of the box
            >
              <Button disabled>
                <Typography
                  variant="h1"
                  fontSize={{ xs: "20px", sm: "24px" }}
                  // lineHeight="100%"
                  gap={2}
                  align="center"
                  alignItems="center"
                  display="flex"
                  color="primary"
                  whiteSpace="nowrap" // Prevents text from wrapping
                  overflow="hidden" // Hides any overflow
                  textOverflow="ellipsis" // Shows an ellipsis (...) when text overflows
                >
                  Total:
                  <Box display="flex" alignItems="center">
                    {/* <NairaIcon className=" ml-2 w-6 h-6" /> */}
                    <Typography
                      variant="h1"
                      fontSize={{ xs: "24px", sm: "28px" }}
                      // lineHeight="100%"
                      gap={2}
                      align="center"
                      alignItems="center"
                      display="flex"
                      color="primary"
                      whiteSpace="nowrap" // Prevents text from wrapping
                      overflow="hidden" // Hides any overflow
                      textOverflow="ellipsis" // Shows an ellipsis (...) when text overflows
                    >
                      {formatAmount(employeeTest.amount)}
                    </Typography>
                  </Box>
                </Typography>
              </Button>
            </Box>

            {/* <Box className=" space-y-6">
                {healthArray.map((item, index) => (
                  <MHCheckboxContent
                    key={index}
                    label={item.label}
                    amount={item.amount}
                    value={healthCheck[item.place]}
                    // value={item.location}
                    handleCheck={(e) => {
                      setHealthCheck((prevHealthCheck) => ({
                        ...prevHealthCheck,
                        [item.place]: e.target.checked,
                      }));
                    }}
                  />
                ))}
              </Box> */}
            {/* <MHRadioContent
              row
              name="allocation-period"
              id="allocation-period"
              options={[
                {
                  value: "Nanny / Housekeeper",
                  amount: 35000,
                  label: "Nanny / Housekeeper",
                  text: "Tuberculosis (TB) Test, HIV I & II Test, Hepatitis A, B, and C Tests, Pregnancy, Genotype,, and BP | N35,000",
                },
                {
                  value: "Drivers & Security",
                  amount: 50000,
                  label: "Drivers & Security",
                  text: "Tuberculosis (TB) Test, HIV I & II Test, Hepatitis A, B, and C Tests, Pregnancy, Genotype, and BP,. Drug Screening, Vision Test, Hearing Test, and Diabetes Screening",
                },
                {
                  value: "Cook / Chef",
                  amount: 45000,
                  label: "Cook / Chef",
                  text: "Tuberculosis (TB) Test, HIV I & II Test, Hepatitis A, B, and C Tests, Pregnancy Test, Genotype Test, and BP, Stool Test, Drug Screening",
                },
                {
                  value: "Home Tutors / Others",
                  amount: 40000,
                  label: "Home Tutors / Others",
                  text: "Tuberculosis (TB) Test, HIV I & II Test, Hepatitis A, B, and C Tests, Drug Screening",
                },
              ]}
              value={enteredHealth}
              onChange={handleHealthChange}
              // color="default"
              controlSx={{
                mb: 0,
              }}
            /> */}
          </Box>

          <Box display="flex" justifyContent="center" my={4}>
            <MHButton
              color="primary"
              sx={{}}
              type="submit"
              className=" w-[215px]"
              // startIcon={<PlusIcon />}
              onClick={healthChecker}
              // onClick={() =>
              //   history.push("/organization/employee-onboarding")
              // }
            >
              Run Check
            </MHButton>
          </Box>
        </Box>
      </RunCheckDialog>
      <RunCheckDialog
        open={openDialog2}
        title={"Background Check "}
        handleClose={handleCloseDialog2}
      >
        <Box p={2}>
          <Box
            className={`h-[480px] md:h-[450px] ring-1 ring-[#009688] rounded-lg py-6 px-2 mb-3 md:mb-0 
              
            `}
            // ${
            //   clickState === true
            //     ? "grayscale-0 opacity-100"
            //     : "grayscale opacity-50"
            // }
          >
            <Typography
              variant="h1"
              fontSize={{ xs: "18px", sm: "20px", md: "20px" }}
              component="div"
              gutterBottom
              mb={3}
              className=" capitalize"
            >
              {/* Background Check */}
              {employee?.jobTitle}
            </Typography>

            <Typography
              variant="body1"
              fontSize={{ xs: "13px", sm: "13px", md: "13px" }}
              lineHeight={{ xs: "22px", sm: "22px" }}
              component="div"
              gutterBottom
              mb={5}
            >
              Conducting thorough background checks to verify the staff's
              identity, criminal history, and past employment records.
            </Typography>

            <Box className=" space-y-6" px={1.5}>
              {bgArray.map((item, index) => (
                <MHCheckboxContent
                  key={index}
                  label={item.label}
                  amount={item.amount}
                  value={bgCheck[item.place]}
                  // value={item.location}
                  handleCheck={(e) => {
                    setBgCheck((prevBgCheck) => ({
                      ...prevBgCheck,
                      [item.place]: e.target.checked,
                    }));
                    setBgAmount((prev) => ({
                      ...prev,
                      [item.place]: e.target.checked ? item.amount : 0, // Update amount based on check status
                    }));
                  }}
                />
              ))}
              {/* 
                <MHCheckboxContent
                  label="Reference Checks"
                  amount={2500}
                  value={bgCheck.reference}
                  handleCheck={(e) =>
                    setBgCheck({ ...bgCheck, reference: e.target.checked })
                  }
                />
              */}
            </Box>
          </Box>
        </Box>
        <Box display="flex" justifyContent="center" my={4}>
          <MHButton
            color="primary"
            sx={{}}
            type="submit"
            className=" w-[215px]"
            // startIcon={<PlusIcon />}
            onClick={bgChecker}
            // onClick={() =>
            //   history.push("/organization/employee-onboarding")
            // }
          >
            Run Check
          </MHButton>
        </Box>
      </RunCheckDialog>
    </React.Fragment>
  );
};

export default SummaryView;
