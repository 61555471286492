import React from "react";
import StepperBar from "../../components/Layout/StepperBar";
import EmployeeInfo from "../../components/Features/Employees/EmployeeInfo";
import EmployeeTrustSafety from "../../components/Features/Employees/EmployeeTrustSafety";
import { useOnboardForm } from "../../store/context/onboard-context";
import EmployeeSalaryBenefit from "../../components/Features/Employees/EmployeeSalaryBenefit";
import { useMediaQuery, useTheme } from "@mui/material";

export const EmployeeOnboarding = () => {
  const { activeStep } = useOnboardForm();

  const steps = ["Info", "Salary & Benefits"];
  // const steps = ["Info", "Trust & Safety", "Salary & Benefits", "Final"];

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  // console.log("fullScreen", fullScreen);

  const handleOnboarding = () => {
    if (activeStep === 0) {
      return <EmployeeInfo />;
    } else if (activeStep === 1) {
      // return <EmployeeTrustSafety />;
      return <EmployeeSalaryBenefit />;
    } else {
      return null;
      // return <EmployeeContract />;
    }
  };
  return (
    <React.Fragment>
      {/* {!fullScreen && <StepperBar data={steps} />} */}
      {/* <EmployeeTrustSafety /> */}

      {handleOnboarding()}
    </React.Fragment>
  );
};
