import { Box, Tab, Tabs } from "@mui/material";
import React from "react";
import ProfileSettings from "./ProfileSettings";
import ProfileMessaging from "./ProfileMessaging";
import ProfileTransactions from "./ProfileTransactions";
import BackgroundCheck from "./BackgroundCheck";

export interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

export function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

export function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

type Props = {
  // tabValue: number;
  // handleTabChange: (event: React.SyntheticEvent, newValue: number) => void;
};

const ProfileOverview = (props: Props) => {
  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const [indicatorWidth, setIndicatorWidth] = React.useState(0);
  const tabIndicatorRef = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    if (tabIndicatorRef.current) {
      const selectedTab = document.querySelector(
        `.MuiTab-root:nth-child(${value + 1})`
      ) as HTMLElement;
      setIndicatorWidth(selectedTab.offsetWidth);
    }
  }, [value]);

  return (
    <Box>
      <Tabs
        value={value}
        className="font-areaNorm"
        // onChange={props.handleTabChange}
        onChange={handleChange}
        aria-label="basic tabs example-1-1"
      >
        <Tab
          className=" md:mr-0 -ml-4 text-xs capitalize leading-[102%] tracking-[0.04em]"
          label="Account Settings"
          disableRipple
          {...a11yProps(0)}
        />
        <Tab
          className=" md:mr-0 text-xs capitalize leading-[102%] tracking-[0.04em]"
          label="Verify"
          disableRipple
          {...a11yProps(1)}
        />
        <Tab
          className=" md:mr-0 text-xs capitalize leading-[102%] tracking-[0.04em]"
          label="Transactions"
          disableRipple
          {...a11yProps(2)}
        />
        <Tab
          className=" md:mr-0 text-xs capitalize leading-[102%] tracking-[0.04em]"
          label="Messages"
          disableRipple
          {...a11yProps(3)}
        />
      </Tabs>

      <Box my={3}>
        <TabPanel value={value} index={0}>
          <ProfileSettings />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <BackgroundCheck />
        </TabPanel>
        <TabPanel value={value} index={2}>
          <ProfileTransactions />
        </TabPanel>
        <TabPanel value={value} index={3}>
          <ProfileMessaging />
        </TabPanel>
      </Box>
    </Box>
  );
};

export default ProfileOverview;
