import React from "react";
import { Link } from "react-router-dom";

import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import MuiLink from "@mui/material/Link";
import Alert from "@mui/material/Alert";
import ListItemText from "@mui/material/ListItemText";
import Avatar from "@mui/material/Avatar";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItem from "@mui/material/ListItem";

import MHButton from "../../components/Common/Button/MHButton";
import MHFormControl from "../../components/Common/Form/MHFormControl";
import InputAdornment from "../../components/Common/Form/InputAdornment";
import useInput from "../../hooks/use-input";
import useHttp from "../../hooks/use-http";
import useTitle from "../../hooks/use-title";

import { ReactComponent as MailIcon } from "../../static/svg/mail.svg";
import { ReactComponent as InfoRoundedIcon } from "../../static/svg/info-rounded.svg";
import { FnComponent } from "../../models/component.model";
// import { BGImage } from "../../models/background-image.model";
import * as validators from "../../utils/validators";
import { HttpResponse } from "../../models/api.interface";
import { EMAIL_FROM } from "../../utils/constants";
import MHRadioGroup from "../../components/Common/Form/MHRadioGroup";
import { useOnboardForm } from "../../store/context/onboard-context";
import { Divider, TextField } from "@mui/material";
import SnackbarContext from "../../store/context/snackbar.context";
import { ReactComponent as CheckMarkIcon } from "../../static/svg/check-mark-md.svg";
import MHPasswordControl from "../../components/Common/Form/MHPasswordControl";
import Label from "../../components/Common/Form/Label";
import MHTextInput from "../../components/Common/Form/MHTextInput";

const ForgotPassword: FnComponent<{
  title: string;
}> = (props) => {
  useTitle(props.title);

  const { activeStep, setActiveStep } = useOnboardForm();

  const {
    value: enteredEmail,
    valid: enteredEmailIsValid,
    error: emailInputHasError,
    onChange: emailInputChangeHandler,
    onBlur: emailInputBlurHandler,
  } = useInput([{ validator: (value: string) => validators.email(value) }]);

  const [enteredRole, setEnteredRole] = React.useState("Employer");

  const RoleSelection = () => {
    const emailErrorTip = emailInputHasError
      ? "Please enter a valid email"
      : "";

    const handleRoleChange = (event: React.ChangeEvent<HTMLInputElement>) =>
      setEnteredRole(event.target.value);

    const { loading, error, sendHttpRequest: sendResetLink } = useHttp();

    const sendResetLinkHandler = (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      if (emailInputHasError || !enteredEmailIsValid) return;

      sendResetLink(
        process.env.REACT_APP_API_BASE_URL + "/employer/password/authcode",
        {
          method: "POST",
          body: JSON.stringify({ email: enteredEmail, type: enteredRole }),
        },
        (response: HttpResponse<unknown>) => {
          console.log(response);
          setActiveStep(1);
        }
      );
    };

    localStorage.setItem("enteredEmail", enteredEmail);
    localStorage.setItem("enteredRole", enteredRole);

    return (
      <Paper
        sx={{
          px: 3,
          py: 3,
          width: "100%",
        }}
        className={enteredEmail ? "h-full" : "h-[400px]"}
      >
        <Box>
          <Box className="place-content-center text-center space-y-6 mb-10">
            <Typography
              variant="h1"
              component="h1"
              fontSize={{ xs: "20px", sm: "28px", md: "28px" }}
              gutterBottom
              mb={1}
            >
              Can't remember your password?
            </Typography>

            <Typography variant="body2" component={"div"} gutterBottom mb={3}>
              Not a problem! Just enter your email address and we'll send you a
              link to reset it.
            </Typography>
          </Box>

          {error && (
            <Alert
              severity="error"
              sx={{
                mb: 3,
              }}
            >
              {error.message}
            </Alert>
          )}

          <Box component={"form"} onSubmit={sendResetLinkHandler}>
            <Box className="max-w-[200px] mx-auto">
              <MHRadioGroup
                defaultValue="Employer"
                row
                name="allocation-period"
                id="allocation-period"
                options={[
                  {
                    value: "Employer",
                    label: "Employer",
                  },
                  {
                    value: "Employee",
                    label: "Employee",
                  },
                ]}
                value={enteredRole}
                onChange={handleRoleChange}
                color="default"
                controlSx={{
                  mb: 0,
                }}
              />
            </Box>

            {/* <MHFormControl
              id="email-forgot"
              type="email"
              label="Email"
              placeholder="Enter your email address"
              value={enteredEmail}
              onChange={emailInputChangeHandler}
              onBlur={emailInputBlurHandler}
              error={emailErrorTip}
              startAdornment={
                <InputAdornment>
                  <MailIcon width="1rem" />
                </InputAdornment>
              }
              required
            /> */}

            <MHTextInput
              id="email"
              type="email"
              placeholder="Enter your email address"
              value={enteredEmail}
              onChange={emailInputChangeHandler}
              onBlur={emailInputBlurHandler}
              className=" mb-3"
              startAdornment={
                <InputAdornment>
                  <MailIcon width="1rem" />
                </InputAdornment>
              }
              required
              autoFocus
            />

            {enteredEmail && (
              <ListItem
                sx={{
                  background: "#ECF7FF",
                  border: 0.2,
                  borderColor: "#194049",
                  color: "#6B6B6B",
                  my: 3,
                  py: 3,
                }}
              >
                <ListItemAvatar>
                  <Avatar
                    alt="!"
                    sx={{
                      bgcolor: "transparent",
                    }}
                  >
                    <InfoRoundedIcon width="100%" />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText>
                  If your email address exists in our database, you'll receive a
                  Whatsapp authentication code to reset your password.
                </ListItemText>
              </ListItem>
            )}

            <MHButton sx={{ mb: 2 }} type="submit" loading={loading} fullWidth>
              Send Authentication Code
            </MHButton>

            <MuiLink
              component={Link}
              underline="always"
              to="/auth/sign-in"
              display="block"
              align="left"
              className="text-blue-100 removeUnderline"
            >
              Go back to the login page
            </MuiLink>
          </Box>
        </Box>
      </Paper>
    );
  };

  const Verification = () => {
    const [otp, setOtp] = React.useState(["", "", "", "", "", ""]);
    const [errorMsg, setErrorMsg] = React.useState(false);

    const enteredEmailStored = localStorage.getItem("enteredEmail");
    const enteredRoleStored = localStorage.getItem("enteredRole");

    const [password, setPassword] = React.useState("");

    const handlePasswordChange = (value: string) => {
      setPassword(value);
      console.log("password", password);
    };

    const isValidPassword = validators.validatePassword(
      password,
      8, // minLength
      true, // requiredUppercase
      true, // requiredLowercase
      true, // requiredNumber
      true // requiredSpecialChar
    );

    const handleVerifyChange = (index: number, value: string) => {
      if (/^\d*$/.test(value)) {
        const newOtp = [...otp];
        newOtp[index] = value;
        setOtp(newOtp);
        if (index < otp.length - 1 && value.length === 1)
          document.getElementById(`otp-input-${index + 1}`)?.focus();
      }
    };

    const handleVerifyPaste = (e: React.ClipboardEvent<HTMLInputElement>) => {
      e.preventDefault();
      const pastedData = e.clipboardData.getData("Text");
      if (pastedData.match(/^\d{4}$/)) {
        const newOtp = pastedData.split("");
        setOtp(newOtp);
      }
    };

    const verifiedOtp = otp.join("");

    const {
      loading: passLoading,
      error: passError,
      sendHttpRequest: sendPassResetLink,
    } = useHttp();

    const handlePasswordReset = (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();

      if (emailInputHasError && !enteredEmailIsValid) {
        return;
      }

      sendPassResetLink(
        process.env.REACT_APP_API_BASE_URL + "/employer/password/reset",
        {
          method: "POST",
          body: JSON.stringify({
            email: enteredEmailStored,
            type: enteredRoleStored,
            newPassword: password,
            authCode: Number(verifiedOtp),
          }),
        },
        (response: HttpResponse<unknown>) => {
          console.log(response);
          setActiveStep(2);
        }
      );
    };

    console.log("enteredEmailStored", enteredEmailStored);
    console.log("enteredRoleStored", enteredRoleStored);
    console.log("password", password);
    console.log("verifiedOtp", verifiedOtp);

    return (
      <Paper
        sx={{
          px: 3,
          py: 3,
          width: "100%",
        }}
        className="h-full"
      >
        <Box className=" ml-0 mr-0 place-content-center  mx-auto ">
          <Box
            justifyContent={"center"}
            alignContent={"center"}
            alignItems={"center"}
            mb={5}
          >
            <Box className="centerItems">
              <Typography
                variant="h1"
                fontSize={{ xs: "24px", sm: "24px" }}
                align="center"
                mt={3}
                gutterBottom
                display={"flex"}
                alignSelf={"center"}
              >
                Reset Password
              </Typography>
            </Box>
            <>
              <Box className="centerItems">
                {passError && (
                  <Alert
                    severity="error"
                    sx={{
                      mb: 3,
                    }}
                  >
                    {passError.message}
                  </Alert>
                )}
              </Box>
              <Box className="centerItems">
                <Typography
                  variant="body1"
                  fontSize={{ xs: "12px", sm: "12px", md: "12px" }}
                  width={{ xs: "330px", sm: "330px" }}
                  lineHeight={"19px"}
                  align="center"
                  component="div"
                  gutterBottom
                  mb={1}
                  className="centerItems "
                >
                  {errorMsg
                    ? "The PIN you entered is invalid, Please enter the right PIN to initiate wallet-to-bank transfer."
                    : "Enter the 6-digit Code sent to your Whatsapp number"}
                </Typography>
              </Box>

              <Box component={"form"} onSubmit={handlePasswordReset}>
                <Box className="centerItems" mt={2}>
                  <Box className="  mx-auto place-content-center text-center rounded-xl">
                    {otp.map((value, index) => (
                      <TextField
                        key={index}
                        id={`otp-input-${index}`}
                        variant="outlined"
                        value={value}
                        onChange={(e) =>
                          handleVerifyChange(index, e.target.value)
                        }
                        onPaste={handleVerifyPaste}
                        inputProps={{ maxLength: 1, minWidth: "100%" }}
                        sx={{
                          marginRight: "10px",
                          width: { xs: "38px", sm: "50px" }, // Responsive width using the sx prop
                        }}
                      />
                    ))}
                  </Box>
                </Box>

                <Box className="py-4 space-y-2 w-[90%] md:w-[70%] mx-auto">
                  {/* <Label>Enter Password</Label> */}
                  <MHPasswordControl
                    label="Enter Password"
                    value={password}
                    onChange={handlePasswordChange}
                    minLength={8}
                    requiredUppercase={true}
                    requiredLowercase={true}
                    requiredNumber={true}
                    requiredSpecialChar={true}
                  />
                </Box>

                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  mt={10}
                >
                  <Box
                    className=""
                    width={{ xs: "fit-content", sm: "100%" }}
                    gap={2}
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <MHButton
                      sx={{}}
                      onClick={() =>
                        setActiveStep((prevActiveStep) => prevActiveStep - 1)
                      }
                      color="secondary"
                      className="  "
                      fullWidth
                    >
                      Back
                    </MHButton>
                    <MHButton
                      sx={{}}
                      type="submit"
                      loading={passLoading}
                      disabled={!isValidPassword}
                      fullWidth
                    >
                      Reset
                    </MHButton>
                  </Box>
                </Box>
              </Box>
            </>
          </Box>
        </Box>
      </Paper>
    );
  };

  const Information = () => (
    <Paper
      sx={{
        px: 3,
        py: 3,
        width: "100%",
      }}
      className="h-full"
    >
      <Box
        sx={{
          "& > svg": {
            textAlign: "center",
            mx: "auto",
            my: 2,
          },
        }}
      >
        <CheckMarkIcon />
        <Typography variant="h2" align="center" color="#194049" paragraph>
          Password Reset Successful!
        </Typography>

        <Divider
          light
          variant="middle"
          sx={{
            borderColor: "#F3F4F6",
            my: 2,
          }}
        />

        <Typography
          variant="body1"
          color="#194049"
          align="center"
          fontSize="16px"
          lineHeight="150%"
          sx={{
            opacity: 0.8,
          }}
          gutterBottom
        >
          Your password has been successfully updated and is now ready for use.
          You can securely log in with your new credentials to access your
          account. If you encounter any issues or need further assistance, feel
          free to reach out to support.
        </Typography>
      </Box>

      <MuiLink
        component={Link}
        underline="always"
        to="/auth/sign-in"
        display="block"
        align="center"
        className="text-blue-100 mt-4 removeUnderline"
      >
        Go back to the login page
      </MuiLink>
    </Paper>
  );

  const handlePasswordFunction = () => {
    if (activeStep === 0) {
      return <RoleSelection />;
    } else if (activeStep === 1) {
      return <Verification />;
    } else {
      return <Information />;
    }
  };

  return <React.Fragment>{handlePasswordFunction()}</React.Fragment>;
};

export default ForgotPassword;
