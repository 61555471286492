import React from "react";
import { useHistory } from "react-router-dom";

import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Alert from "@mui/material/Alert";

import MHFormControl from "../../../components/Common/Form/MHFormControl";
import MHButton from "../../../components/Common/Button/MHButton";
import InputAdornment from "../../../components/Common/Form/InputAdornment";
import IconButton from "../../../components/Common/Button/IconButtonStyled";
import useHttp from "../../../hooks/use-http";

import { ReactComponent as LockIcon } from "../../../static/svg/lock.svg";
import { ReactComponent as VisibilityIcon } from "../../../static/svg/visibility.svg";
import { ReactComponent as VisibilityOffIcon } from "../../../static/svg/visibility-off.svg";
import { ReactComponent as PhoneIcon } from "../../../static/svg/phone.svg";

import * as validators from "../../../utils/validators";

import useInput from "../../../hooks/use-input";
import { MHSelect } from "../Form/MHSelect";
import { decrypt, resolveErrorMessage } from "../../../utils/utils";
import { useOnboardForm } from "../../../store/context/onboard-context";
import useDialog from "../../../hooks/use-dialog";
import useForm from "../../../hooks/use-form";
import RequestConfirmationPrompt from "../Dialog/RequestConfirmation";
import { NIGERIA_STATES_LCDAS } from "../../../utils/constants";
import { GENDER } from "../../Features/Employees/EmployeeInfo";
import SnackbarContext from "../../../store/context/snackbar.context";
import MHAutocomplete, { Option } from "../Form/MHAutocomplete";
import MHPhoneInput from "../Form/MHPhoneInput";
import usePhoneInput from "../../../hooks/use-phone";
// import BackdropLoader from "../Loading/BackdropLoader";

export interface SelectOption<T> {
  label: string;
  value: T;
}

const Information = () => {
  const {
    value: enteredEmail,
    onChange: emailInputChangeHandler,
    onBlur: emailInputBlurHandler,
  } = useInput([
    {
      validator: (value: string) => validators.required(value),
    },
  ]);
  const {
    value: enteredFirstName,
    valid: enteredFirstNameIsValid,
    onChange: firstNameInputChangeHandler,
    onBlur: firstNameInputBlurHandler,
  } = useInput([
    {
      validator: (value: string) => validators.required(value),
    },
  ]);

  const {
    value: enteredLastName,
    valid: enteredLastNameIsValid,
    onChange: lastNameInputChangeHandler,
    onBlur: lastNameInputBlurHandler,
  } = useInput([
    {
      validator: (value: string) => validators.required(value),
    },
  ]);
  const {
    value: enteredMiddleName,
    valid: enteredMiddleNameIsValid,
    onChange: middleNameInputChangeHandler,
    onBlur: middleNameInputBlurHandler,
  } = useInput([
    {
      validator: (value: string) => validators.required(value),
    },
  ]);
  const {
    value: enteredGender,
    valid: enteredGenderIsValid,
    error: genderInputHasError,
    onChange: genderInputChangeHandler,
    onBlur: genderInputBlurHandler,
  } = useInput([
    {
      validator: (value: string) => validators.required(value),
    },
  ]);

  const {
    value: enteredHomeAddress,
    valid: enteredHomeAddressIsValid,
    onChange: homeAddressInputChangeHandler,
    onBlur: homeAddressInputBlurHandler,
  } = useInput([
    {
      validator: (value: string) => validators.required(value),
    },
  ]);

  const {
    value: enteredLcda,
    valid: enteredLcdaIsValid,
    error: enteredLcdaHasError,
    onChange: lcdaInputChangeHandler,
    onBlur: lcdaInputBlurHandler,
  } = useInput([
    {
      validator: (value: string) => validators.required(value),
    },
  ]);

  const {
    value: enteredNin,
    valid: enteredNinIsValid,
    error: enteredNinHasError,
    onChange: ninInputChangeHandler,
    onBlur: ninInputBlurHandler,
  } = useInput([
    {
      validator: (value: string) => validators.required(value),
    },
  ]);

  const {
    phoneNumber,
    phoneInfo,
    phoneNumberInputChangeHandler,
    isValid: phoneNumberIsValid,
  } = usePhoneInput([]);

  const { value: enteredWhatsapp } = useInput([
    {
      validator: (value: string) => validators.required(value),
    },
  ]);

  const {
    value: enteredPassword,
    valid: enteredPasswordIsValid,
    error: passwordInputHasError,
    onChange: passwordInputChangeHandler,
    onBlur: passwordInputBlurHandler,
  } = useInput([
    {
      validator: (value: string) => validators.password(value),
    },
  ]);

  const genderRolesOptions: Option[] = GENDER.map((role) => ({
    label: role.label as string,
    value: role.value,
  }));

  // const stateLcdaOptions: Option[] = filteredLCDAs.map((role) => ({
  //   label: role.label as string,
  //   value: role.value,
  // }));

  const [selectedState, setSelectedState] = React.useState<string>("");
  const [filteredLCDAs, setFilteredLCDAs] = React.useState<
    SelectOption<string>[]
  >([]);

  const [showPassword, setShowPassword] = React.useState<boolean>(false);

  const { loading, error, sendHttpRequest: onboardEmployer } = useHttp();

  const { openDialog, handleOpenDialog } = useDialog();
  const { validateForm } = useForm();

  const closure = () => {
    history.push("/auth/sign-in");
  };

  const handleStateChange = (state: string | null) => {
    if (state) {
      setSelectedState(state);
      const selected = NIGERIA_STATES_LCDAS.find((s) => s.state === state);
      setFilteredLCDAs(
        selected
          ? selected.lcda.map((lcda) => ({ label: lcda, value: lcda }))
          : []
      );
    }
  };

  let passwordErrorTip = passwordInputHasError
    ? "Minimum of 8 characters, consisting of at least an uppercase letter, a lowercase letter, a number and a special character"
    : undefined;

  const enteredUsePasswordIsValid = enteredPassword.trim().length >= 8; // Assuming a minimum of 8 characters

  const phoneUseNumberIsValid = phoneNumber.trim() !== ""; // Or use a more complex regex check

  const formIsValid =
    enteredFirstNameIsValid &&
    enteredLastNameIsValid &&
    enteredMiddleNameIsValid &&
    enteredUsePasswordIsValid &&
    enteredHomeAddressIsValid &&
    enteredLcdaIsValid &&
    enteredGenderIsValid &&
    enteredNinIsValid &&
    phoneUseNumberIsValid;

  let history = useHistory();

  const snackbarCtx = React.useContext(SnackbarContext);
  const { toast } = snackbarCtx;

  const preventDefault = (event: React.SyntheticEvent) =>
    event.preventDefault();

  const handleClickShowPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    preventDefault(event);
    setShowPassword((prevState) => !prevState);
  };

  const { form, clearForm } = useOnboardForm();

  // GET SUBSCRIPTION PLAN
  const subs = localStorage.getItem("subscriptionType");
  let finalSubType = "";

  if (!subs) {
    finalSubType = "Free Trial";
  } else {
    const subsType = JSON.parse(decrypt(subs));
    finalSubType = subsType.subscriptionType;
  }

  // console.log("subsType", subsType);
  // console.log("finalSubType", finalSubType);

  const onboardEmployerHandler = async (
    event: React.ChangeEvent<HTMLFormElement>
  ) => {
    // emailInputRef.current!.focus();
    event.preventDefault();
    if (!formIsValid) {
      // console.log("Complete Form");
      toast({
        message: "Please input all required field",
        variant: "warning",
      });
    }

    // let newPhoneNo = enteredPhone;

    // if (newPhoneNo?.startsWith("0")) {
    //   newPhoneNo = "+234" + newPhoneNo.slice(1);
    // } else if (newPhoneNo?.startsWith("234")) {
    //   newPhoneNo = "+234" + newPhoneNo.slice(3);
    // } else if (newPhoneNo?.startsWith("+234")) {
    //   return newPhoneNo;
    // } else {
    //   // Handle other cases if necessary
    //   return;
    // }

    const employerInfo = {
      email: enteredEmail,
      whatsAppNumber: form.whatsapp
        ? form.whatsapp.replace(/\s+/g, "")
        : phoneNumber.replace(/\s+/g, ""),
      firstName: enteredFirstName,
      lastName: enteredLastName,
      middleName: enteredMiddleName,
      sex: enteredGender,
      password: enteredPassword,
      telephone: phoneNumber.replace(/\s+/g, ""),
      subscriptionType: finalSubType,
      state: selectedState,
      lcda: enteredLcda,
      address: enteredHomeAddress,
      nin: enteredNin,
      role: "Employer",
      NumberOfEmployees: 0,
      status: "ACTIVE",
      countryCode: "NG",
      bvn: "",
    };

    // console.log("employerInfo", employerInfo);
    // console.log("formIsValid", formIsValid);

    onboardEmployer(
      process.env.REACT_APP_API_BASE_URL + "/employer/create",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(employerInfo),
      },
      () => {
        // onClose();
        // eslint-disable-next-line @typescript-eslint/no-unused-expressions

        toast({
          message: "Successfully onboarded employer.",
          variant: "success",
        });

        localStorage.removeItem("subscriptionType");
        console.log("Onboarded Successfully");
        // handleOpenDialog();
        closure();
        clearForm();
      }
      // (response: HttpResponse<any>) => {
      //   updateNoOfEmployee();
      // clearForm()

      // }
    );
  };

  // const submitHandler = async (event: React.ChangeEvent<HTMLFormElement>) => {
  //   // emailInputRef.current!.focus();
  //   preventDefault(event);
  //   // validateForm();

  //   // if (!formIsValid) {
  //   //   return;
  //   // }

  //   let newPhoneNo = enteredPhone;

  //   if (newPhoneNo.startsWith("0")) {
  //     newPhoneNo = "+234" + newPhoneNo.slice(1);
  //   } else if (newPhoneNo.startsWith("234")) {
  //     newPhoneNo = "+234" + newPhoneNo.slice(3);
  //   } else if (newPhoneNo.startsWith("+234")) {
  //     return newPhoneNo;
  //   } else {
  //     // Handle other cases if necessary
  //     return;
  //   }

  //   addToOnboard({
  //     firstname: enteredFirstName,
  //     middlename: enteredMiddleName,
  //     lastname: enteredLastName,
  //     email: enteredEmail,
  //     password: enteredPassword,
  //     state: selectedState,
  //     address: enteredHomeAddress,
  //     lcda: enteredLcda,
  //     phone: newPhoneNo,
  //     sex: enteredGender,
  //     subscriptionType: finalSubType,
  //     whatsapp: enteredWhatsapp,
  //   });

  //   const employerInfo = {
  //     email: enteredEmail,
  //     whatsAppNumber: form.whatsapp ? form.whatsapp : newPhoneNo,
  //     firstName: enteredFirstName,
  //     lastName: enteredLastName,
  //     middleName: enteredMiddleName,
  //     sex: enteredGender,
  //     password: enteredPassword,
  //     telephone: newPhoneNo,
  //     subscriptionType: finalSubType,
  //     state: selectedState,
  //     lcda: enteredLcda,
  //     address: enteredHomeAddress,
  //     role: "Employer",
  //     NumberOfEmployees: 0,
  //     status: "ACTIVE",
  //     countryCode: "NG",
  //     nin: "",
  //     bvn: "",
  //   };

  //   console.log("employerInfo", employerInfo);

  //   // dispatch({ type: "REQUEST" });

  //   try {
  //     const response = await fetch(
  //       form.type === "employer" ? employerUrl : employeeUrl,
  //       {
  //         method: "POST",
  //         headers: { "Content-Type": "application/json" },
  //         body: JSON.stringify(employerInfo),
  //       }
  //     );
  //     if (!response.ok) {
  //       console.error("Failed to onboard. Status Code:", response.status);
  //       return;
  //     }

  //     // dispatch({ type: "RESPONSE", error: null, data: "success" });
  //     console.log(response);

  //     toast({
  //       message: "Onboarded Successfully",
  //       variant: "success",
  //     });
  //     localStorage.removeItem("subscriptionType");
  //     console.log("Onboarded Successfully");
  //     handleOpenDialog();

  //     history.push("/auth/sign-in");
  //     clearForm();
  //   } catch (error) {
  //     console.error("An error occurred:", error);
  //   }
  // };
  return (
    <React.Fragment>
      <Box className="text-center">
        <Typography
          variant="h1"
          fontSize={{ xs: "24px", sm: "28px", md: "28px" }}
          component="div"
          gutterBottom
          mb={5}
        >
          Enter your information
        </Typography>
      </Box>
      <Paper
        sx={{
          px: 3,
          py: 3,
          boxShadow: "0px 10px 16px rgba(154, 154, 154, 0.13)",
        }}
        className="h-full max-w-md mx-auto"
      >
        {error && (
          <Alert
            severity="error"
            sx={{
              mb: 3,
            }}
          >
            {error.message}
          </Alert>
        )}

        {/* autoComplete="off"
            noValidate */}
        <Box component={"form"} onSubmit={onboardEmployerHandler}>
          <MHFormControl
            id="email"
            type="email"
            label=""
            placeholder="Email"
            value={enteredEmail}
            onChange={emailInputChangeHandler}
            onBlur={emailInputBlurHandler}
            required
            // autoFocus
          />

          <MHFormControl
            id="fname"
            type="text"
            label=""
            placeholder="First Name"
            value={enteredFirstName}
            onChange={firstNameInputChangeHandler}
            onBlur={firstNameInputBlurHandler}
            required
            // autoFocus
          />
          <MHFormControl
            id="midname"
            type="text"
            label=""
            placeholder="Middle name"
            value={enteredMiddleName}
            onChange={middleNameInputChangeHandler}
            onBlur={middleNameInputBlurHandler}
            required
            // autoFocus
          />
          <MHFormControl
            id="lname"
            type="text"
            label=""
            placeholder="Last name"
            value={enteredLastName}
            onChange={lastNameInputChangeHandler}
            onBlur={lastNameInputBlurHandler}
            required
            // autoFocus
          />

          {/* <MHSelect
            label=""
            placeholder="Select Gender"
            options={GENDER}
            value={enteredGender}
            onChange={(val) => genderInputChangeHandler(val as string)}
            onBlur={genderInputBlurHandler}
            error={resolveErrorMessage(genderInputHasError)(
              "Please select your gender"
            )}
          /> */}

          <Box my={2}>
            <MHAutocomplete
              label=""
              placeholder="Select Gender"
              options={genderRolesOptions}
              onInputChange={(val) => genderInputChangeHandler(val as string)}
            />
          </Box>

          <MHFormControl
            id="address"
            type="text"
            label=""
            placeholder="Home Address"
            value={enteredHomeAddress}
            onChange={homeAddressInputChangeHandler}
            onBlur={homeAddressInputBlurHandler}
            required
            // autoFocus
          />

          <MHFormControl
            id="nin"
            type="text"
            label=""
            placeholder="National Identification Number (NIN)"
            value={enteredNin}
            onChange={ninInputChangeHandler}
            onBlur={ninInputBlurHandler}
            required
            // autoFocus
          />

          {/* <MHSelect
            label=""
            placeholder="Select State"
            options={NIGERIA_STATES_LCDAS.map((state) => ({
              label: state.state,
              value: state.state,
            }))}
            value={selectedState}
            onChange={handleStateChange}
            onBlur={() => {}}
            error={resolveErrorMessage(enteredFirstNameIsValid)(
              "Please select your state"
            )}
          /> */}

          <Box my={2}>
            <MHAutocomplete
              label=""
              placeholder="Select State"
              options={NIGERIA_STATES_LCDAS.map((state) => ({
                label: state.state,
                value: state.state,
              }))}
              onInputChange={handleStateChange}
            />
          </Box>

          <Box my={2}>
            <MHAutocomplete
              label=""
              placeholder="Select LCDA"
              options={filteredLCDAs}
              onInputChange={(val) => lcdaInputChangeHandler(val as string)}
            />
          </Box>

          {/* <MHSelect
            label=""
            placeholder="Select LCDA"
            options={filteredLCDAs}
            value={enteredLcda}
            onChange={(val) => lcdaInputChangeHandler(val as string)}
            onBlur={lcdaInputBlurHandler}
            error={resolveErrorMessage(enteredLcdaHasError)(
              "Please select your LCDA"
            )}
          /> */}

          {/* <MHFormControl
            type="text"
            id="phone"
            label=""
            placeholder="Phone Number"
            value={enteredPhone}
            onChange={phoneInputChangeHandler}
            onBlur={phoneInputBlurHandler}
            startAdornment={
              <InputAdornment className="bg-[#EEEEEE] md:h-[43px]">
                <PhoneIcon width={10} />
              </InputAdornment>
            }
            required
            // autoFocus
          /> */}
          <MHPhoneInput
            value={phoneNumber}
            onChange={phoneNumberInputChangeHandler}
            // label="Phone Number"
            errorMessage={resolveErrorMessage(phoneNumberIsValid)(
              "Please enter a valid phone number"
            )}
          />

          <MHFormControl
            id="password"
            type={showPassword ? "text" : "password"}
            label=""
            placeholder="Password"
            value={enteredPassword}
            onChange={passwordInputChangeHandler}
            onBlur={passwordInputBlurHandler}
            error={passwordErrorTip}
            startAdornment={
              <InputAdornment>
                <LockIcon width="1.2rem" />
              </InputAdornment>
            }
            endAdornment={
              <InputAdornment>
                <IconButton onClick={handleClickShowPassword}>
                  {showPassword ? (
                    <VisibilityOffIcon width="1rem" height="1rem" />
                  ) : (
                    <VisibilityIcon width="1rem" height="1rem" />
                  )}
                </IconButton>
              </InputAdornment>
            }
            required
          />

          <MHButton sx={{}} type="submit" loading={loading} fullWidth>
            Continue
          </MHButton>
        </Box>
      </Paper>

      {openDialog && (
        <RequestConfirmationPrompt
          open={openDialog}
          onClose={closure}
          header="We'll be in touch soon!"
          message="Thanks for onboarding! Anticipate upcoming communications as we reach out to share more details about our platform. We're excited to connect with you soon."
        />
      )}
    </React.Fragment>
  );
};

export default Information;
