import React, { useEffect } from "react";
import {
  Switch,
  Route,
  useRouteMatch,
  Redirect,
  useLocation,
} from "react-router-dom";
import { CSSTransition, TransitionGroup } from "react-transition-group";

// import { styled } from "@mui/material/styles";

import Layout from "../components/Layout/Layout";
import Startup from "../components/Features/Dashboard/Startup";
import Dashboard from "../pages/Dashboard/Dashboard";

import AuthContext from "../store/context/auth-context";
import DashboardContext from "../store/context/dashboard.context";
// import Subscription from "../pages/Dashboard/Subscription";
import FAQ from "../pages/CBFaq";
import ViewEmployee from "../pages/Dashboard/ViewEmployee";
import { EmployeeOnboarding } from "../pages/Dashboard/EmployeeOnboarding";
import Documents from "../pages/Dashboard/Documents";
import Transaction from "../pages/Dashboard/Transaction";
import Contract from "../pages/Contract/Contract";
import ContractPage from "../pages/Contract/ContractPage";
import ViewContractPage from "../pages/Contract/ViewContractPage";
import Training from "../pages/Dashboard/Training";
import AllTrainingPage from "../pages/Dashboard/AllTrainingPage";
import ContractAgreement from "../pages/Household/ContractAgreement";
import ContractSigned from "../pages/Household/ContractSigned";
import EmployeeDashboard from "../pages/Household/EmployeeDashboard";
import EmployeeProfile from "../pages/Household/EmployeeProfile";
import EmployerProfile from "../pages/Dashboard/EmployerProfile";
import EmployeeTraining from "../pages/Household/EmployeeTraining";
import EmployerNotifications from "../pages/Dashboard/EmployerNotifications";
import EmployeeNotifications from "../pages/Household/EmployeeNotifications";
import {
  EmployeeProtectedRoute,
  EmployerProtectedRoute,
} from "./ProtectedNavigator";
import ContractPreview from "../pages/Dashboard/ContractPreview";
import EmployeeContractPreview from "../pages/Household/EmployeeContractPreview";
import AdminEditor from "../components/Common/Editor/AdminEditor";
import ArticlesPage from "../components/Resources/Articles/ArticlesPage";
import ViewArticle from "../components/Resources/Articles/ViewArticle";
import EmployerSubscribe from "../components/Features/Dashboard/EmployerSubscribe";
import { SubscribersData } from "../models/subscription.model";
import { getURLWithQueryParams } from "../utils/utils";
import { HttpResponse } from "../models/api.interface";
import useHttp from "../hooks/use-http";
import useDialog from "../hooks/use-dialog";
import SubscribeDialog from "../components/Features/Dashboard/SubscribeDialog";
import CBSingleTraining from "../pages/CBSingleTraining";
import SingleTraining from "../pages/SingleTraining";

// const Wrapper = styled("div")(
//   ({ theme }) => `
//     padding: ${theme.spacing(3)};
//   `
// );

const CaringBlocksNavigator = () => {
  const authCtx = React.useContext(AuthContext);
  const dashboardCtx = React.useContext(DashboardContext);

  const { organization } = dashboardCtx;

  const { path } = useRouteMatch();
  const location = useLocation();

  const { sendHttpRequest: sendSubscribersHttpRequest } = useHttp();

  const [subscriber, setSubscriber] = React.useState<SubscribersData>({});

  const { openDialog, handleOpenDialog, handleCloseDialog } = useDialog();

  React.useEffect(() => {
    sendSubscribersHttpRequest(
      getURLWithQueryParams(
        process.env.REACT_APP_API_BASE_URL + "/subscription/getby",
        {
          customerId: String(organization?.customerId),
        }
      ),
      {
        method: "GET",
      },
      (response: HttpResponse<SubscribersData>) => {
        setSubscriber(response.data);
      }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organization?.customerId]); // Add organization?.customerId to dependency array

  // console.log("authCtx", authCtx.role);

  useEffect(() => {
    const interval = setInterval(() => {
      if (
        authCtx.role === "Employer" &&
        (subscriber === null || Object.keys(subscriber).length === 0)
      ) {
        handleOpenDialog();
        // console.log("subscriber", subscriber);
      } else {
        handleCloseDialog();
        // console.log("subscriber", subscriber);
      }
    }, 100); // Run every second

    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subscriber, authCtx.role]);

  useEffect(() => {
    console.log(openDialog); // Add this to check when it's being triggered
  }, [openDialog]);

  if (!authCtx.user || !organization) {
    return <Startup />;
  }

  // console.log("authCtx", authCtx);
  // console.log("organization", organization);
  // if (!authCtx.isOnboarded(authCtx.user as User)) {
  // return (
  //   <Redirect
  //     to={{
  //       pathname: '/onboarding/employer',
  //       state: { from: { pathname: '/dashboard' } }
  //     }}
  //   />
  // );
  // }

  return (
    <>
      <Layout>
        <title>Caring Blocks Employee Dashboard</title>

        <TransitionGroup>
          <CSSTransition
            unmountOnExit
            key={location.pathname}
            classNames="fade"
            timeout={400}
          >
            <Switch location={location}>
              {/* Employer Route Starts */}
              <EmployerProtectedRoute
                path={`${path}/dashboard`}
                exact
                component={Dashboard}
              />
              <EmployerProtectedRoute
                path={`${path}/documents`}
                exact
                component={Documents}
              />
              <EmployerProtectedRoute
                path={`${path}/documents/contract/preview/:customerId`}
                exact
                component={ContractPreview}
              />
              <EmployerProtectedRoute
                path={`${path}/settings/profile`}
                exact
                component={EmployerProfile}
              />
              <EmployerProtectedRoute
                path={`${path}/employee-onboarding`}
                exact
                component={EmployeeOnboarding}
              />
              <EmployerProtectedRoute
                path={`${path}/employee/view/:uuid`}
                exact
                component={ViewEmployee}
              />
              <EmployerProtectedRoute
                path={`${path}/notifications`}
                exact
                component={EmployerNotifications}
              />
              <EmployerProtectedRoute
                path={`${path}/training`}
                exact
                // component={SingleTraining}
                // component={CBSingleTraining}
                component={Training}
                noWrapper
              />
              <EmployerProtectedRoute
                path={`${path}/training/all`}
                exact
                component={AllTrainingPage}
                noWrapper
              />
              <EmployerProtectedRoute
                path={`${path}/transactions`}
                exact
                component={Transaction}
              />
              <EmployerProtectedRoute
                path={`${path}/contract`}
                exact
                component={ContractPage}
              />
              <EmployerProtectedRoute
                path={`${path}/contract/:id`}
                exact
                component={ViewContractPage}
              />
              <EmployerProtectedRoute
                path={`${path}/contract/edit/:id`}
                exact
                component={Contract}
              />
              <EmployerProtectedRoute
                path={`${path}/articles`}
                exact
                component={ArticlesPage}
              />
              <EmployerProtectedRoute
                path={`${path}/articles/:slug/:id`}
                exact
                component={ViewArticle}
              />
              <EmployerProtectedRoute
                path={`${path}/admin/editor`}
                exact
                component={AdminEditor}
              />
              {/* <EmployerProtectedRoute
                path={`${path}/subscription`}
                exact
                component={EmployerSubscribe}
                noWrapper
              /> */}
              {/* <EmployerProtectedRoute
      path={`${path}/faq`}
      exact
      component={FAQ}
    /> */}
              <EmployerProtectedRoute
                path={`${path}/contract/:id`}
                exact
                component={ViewContractPage}
              />
              <EmployerProtectedRoute
                path={`${path}/contract/edit/:id`}
                exact
                component={Contract}
              />

              {/* Employer Route Ends */}

              {/* ==================================================================== */}

              {/* Employee ROUTES BEGINS */}
              <EmployeeProtectedRoute
                path={`${path}/employee/contract-agreement/:customerId`}
                exact
                component={ContractAgreement}
              />
              <EmployeeProtectedRoute
                path={`${path}/employee/contract-signed`}
                exact
                component={ContractSigned}
              />
              <EmployeeProtectedRoute
                path={`${path}/employee/dashboard`}
                exact
                component={EmployeeDashboard}
              />
              <EmployeeProtectedRoute
                path={`${path}/employee/training`}
                exact
                // component={SingleTraining}
                component={EmployeeTraining}
                noWrapper
              />
              <EmployeeProtectedRoute
                path={`${path}/employee/profile`}
                exact
                component={EmployeeProfile}
              />
              <EmployeeProtectedRoute
                path={`${path}/contract/preview/:customerId`}
                exact
                component={EmployeeContractPreview}
              />
              <EmployeeProtectedRoute
                path={`${path}/employee/notifications`}
                exact
                component={EmployeeNotifications}
              />
              {/* Employee ROUTES ENDS */}

              {/* <Route path={`${path}/resources`} component={ResourcesNavigator} /> */}
            </Switch>
          </CSSTransition>
        </TransitionGroup>
      </Layout>
      {/* <SubscribeDialog open={openDialog} onClose={handleCloseDialog} /> */}
    </>
  );
};

export default CaringBlocksNavigator;
