import React from "react";
import useInput from "../../../hooks/use-input";
import * as validators from "../../../utils/validators";
import { Box, Button, Grid, Link, Typography } from "@mui/material";
import { SelectOption } from "@mui/base";
import MHFormControl from "../../Common/Form/MHFormControl";
import InputAdornment from "../../../components/Common/Form/InputAdornment";
import { MHSelect } from "../../Common/Form/MHSelect";
import MHButton from "../../Common/Button/MHButton";
import { resolveErrorMessage } from "../../../utils/utils";

import { ReactComponent as DeleteIcon } from "../../../static/svg/cancel-icon.svg";
import { ReactComponent as ArrowLeftIcon } from "../../../static/svg/arrow-left.svg";
import { ReactComponent as NairaIcon } from "../../../static/svg/nigeria-naira-icon.svg";
import { ReactComponent as VerifiedIcon } from "../../../static/svg/status/check-verified.svg";

import { useOnboardForm } from "../../../store/context/onboard-context";
import MHDatePicker from "../../Common/Form/MHDatePicker";
import dayjs, { Dayjs } from "dayjs";
import { MHSwitch } from "../../Common/Form/MHSwitch";
import MHRadioContent from "../../Common/Form/MHRadioContent";
import SnackbarContext from "../../../store/context/snackbar.context";
import AuthContext from "../../../store/context/auth-context";
import useHttp from "../../../hooks/use-http";
import MHAutocomplete, { Option } from "../../Common/Form/MHAutocomplete";
import { HttpResponse } from "../../../models/api.interface";
import { Organization } from "../../../models/employer.model";
import DashboardContext from "../../../store/context/dashboard.context";

export const FREQUENCY: SelectOption<string>[] = [
  // {
  //   value: "Weekly",
  //   label: "Weekly",
  // },
  // {
  //   value: "Bi-Weekly",
  //   label: "Bi-Weekly",
  // },
  {
    value: "Monthly",
    label: "Monthly",
  },
];

// interface CheckState {
//   [key: string]: boolean;
// }

interface TextField {
  id: number;
  value: string;
}

// type HMOCheck = {
//   lasma: boolean;
//   casava: boolean;
// };

// const healthArray = [
//   {
//     label: "Lagos State Health Management Agency (LASMA)",
//     amount: 2500,
//     location: "hmoCheck.lasma", //can only be used within Component
//     place: "lashma",
//   },
//   {
//     label: "Casava (Health insurance)",
//     amount: 2000,
//     location: "hmoCheck.casava",
//     place: "casava",
//   },
// ];

const EmployeeSalaryBenefit = () => {
  const { setActiveStep, form, addToOnboard, clearForm } = useOnboardForm();

  const snackbarCtx = React.useContext(SnackbarContext);
  const { toast } = snackbarCtx;

  const dashboardCtx = React.useContext(DashboardContext);
  const { organization, updateEmployerRecord } = dashboardCtx;

  const { value: enteredLastDay } = useInput([
    {
      validator: (value: string) => validators.required(value),
    },
  ]);

  const payFrequencyOptions: Option[] = FREQUENCY.map((role) => ({
    label: role.label as string,
    value: role.value,
  }));

  const {
    value: enteredPayment,
    valid: enteredPaymentIsValid,
    error: enteredPaymentHasError,
    onChange: paymentInputChangeHandler,
    onBlur: paymentInputBlurHandler,
  } = useInput([
    {
      validator: (value: string) => validators.required(value),
    },
  ]);

  const {
    value: enteredSalary,
    valid: enteredSalaryIsValid,
    onChange: salaryInputChangeHandler,
    onBlur: salaryInputBlurHandler,
  } = useInput([
    {
      validator: (value: string) => validators.required(value),
    },
  ]);

  const {
    value: enteredMeals,
    valid: enteredMealsIsValid,
    onChange: mealsInputChangeHandler,
    onBlur: mealsInputBlurHandler,
  } = useInput([
    {
      validator: (value: string) => validators.required(value),
    },
    // {
    //   validator: (value: string) => validators.email(value),
    // },
  ]);

  const {
    value: enteredTransport,
    valid: enteredTransportIsValid,
    onChange: transportInputChangeHandler,
    onBlur: transportInputBlurHandler,
  } = useInput([
    {
      validator: (value: string) => validators.required(value),
    },
  ]);

  const {
    value: enteredAccomodation,
    valid: enteredAccomodationIsValid,
    onChange: accomodationInputChangeHandler,
    onBlur: accomodationInputBlurHandler,
  } = useInput([
    {
      validator: (value: string) => validators.required(value),
    },
  ]);

  const {
    value: enteredAirtime,
    valid: enteredAirtimeIsValid,
    onChange: airtimeInputChangeHandler,
    onBlur: airtimeInputBlurHandler,
  } = useInput([
    {
      validator: (value: string) => validators.required(value),
    },
  ]);

  const { onBlur: benefitsInputBlurHandler } = useInput([
    {
      validator: (value: string) => validators.required(value),
    },
  ]);

  const { value: enteredBonus } = useInput([
    {
      validator: (value: string) => validators.required(value),
    },
  ]);

  const {
    value: enteredHmo,
    valid: enteredHmoIsValid,
    onChange: hmoInputChangeHandler,
  } = useInput([
    {
      validator: (value: string) => validators.required(value),
    },
  ]);

  const formIsValid =
    enteredPaymentIsValid &&
    // enteredMealsIsValid &&
    // enteredTransportIsValid &&
    // enteredAccomodationIsValid &&
    // enteredAirtimeIsValid &&
    enteredHmoIsValid &&
    // enteredProcessDateIsValid &&
    // enteredLastDayIsValid &&
    // enteredProrateSalaryIsValid &&
    // enteredBonusIsValid &&
    enteredSalaryIsValid;

  const [startDate, setStartDate] = React.useState<Dayjs | null>(
    dayjs("2024-04-17")
  );

  const [endDate, setEndDate] = React.useState<Dayjs | null>(
    dayjs("2024-04-17")
  );

  const [employeeState, setEmployeeState] = React.useState({
    checkedDate: false,
    // salaryState: false,
    saveState: false,
  });

  const handleDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    // setCheckedDate(event.target.checked);
    setEmployeeState({ ...employeeState, checkedDate: event.target.checked });
  };

  // const handleSalaryChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  //   setEmployeeState({ ...employeeState, salaryState: event.target.checked });
  // };

  // const [textFields, setTextFields] = React.useState<TextField[]>([
  //   { id: 0, value: "" },
  // ]);

  const [textFields, setTextFields] = React.useState<TextField[]>([]);
  const [addMoreClicked, setAddMoreClicked] = React.useState<boolean>(false);

  const handleAddTextField = () => {
    const newTextField: TextField = { id: textFields.length, value: "" };
    setTextFields([...textFields, newTextField]);
    setAddMoreClicked(true);
  };

  const handleDeleteTextField = (id: number) => {
    const updatedTextFields = textFields.filter(
      (textField) => textField.id !== id
    );
    setTextFields(updatedTextFields);
  };

  const handleChange = (id: number, value: string) => {
    const updatedTextFields = textFields.map((textField) =>
      textField.id === id ? { ...textField, value } : textField
    );
    setTextFields(updatedTextFields);
  };
  // console.log(
  //   "textFields",
  //   textFields.map((textField) => JSON.stringify(textField.value)).join(", ")
  // );
  // console.log("startDate", startDate);
  // console.log("process", enteredProcessDate);

  const handlePrev = () => {
    console.log("Submitted");
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const { loading, sendHttpRequest: onboardEmployee } = useHttp();

  const authCtx = React.useContext(AuthContext);
  const { user } = authCtx;

  const employeeData = {
    firstname: form.firstname,
    lastname: form.lastname,
    email: form.email,
    telephone: form.phone?.replace(/ /g, ""),
    address: form.address,
    stateOfOrigin: form.state,
    whatsAppNumber: form.whatsapp?.replace(/ /g, ""),
    jobTitle: form.role,
    role: "Employee",
    // password: "password",
    sex: form.sex,

    startDate: dayjs(startDate),
    endDate: dayjs(endDate),
    salary: Number(enteredSalary),
    payFrequency: enteredPayment,
    // processPayment: enteredProcessDate,
    // paymentDue: enteredLastDay,
    healthInsuranceName: enteredHmo,
    meals: Number(enteredMeals),
    transportation: Number(enteredTransport),
    Accomodation: Number(enteredAccomodation),
    airtimeOrData: Number(enteredAirtime),
    countryCode: "NG",
    status: "INACTIVE",
    // prorateSalary: enteredProrateSalary,
    // bonus: enteredBonus,
    otherBenefits: textFields.map((textField) => textField.value).join(","),

    // HMO
    lagosHmo: form.hmoCheck === "lashma" ? true : false,
    casavaHmo: form.hmoCheck === "casava" ? true : false,

    // HEALTH ASSESSMENT
    // healthVerification: form.healthVerify,
    // healthVerificationCost: form.healthVerifyAmount,

    // BG VERIFICATION BEGINS
    // customerId: 123452323,
    // vaccination: form.bgCheck.id,
    // vaccinationCost: form.bgCheck.id === true ? 2000 : 0,
    // communicableDiseaseTest: form.bgCheck.address,
    // commDiseaseTestCost: form.bgCheck.address === true ? 5000 : 0,
    // vasionScreenTest: form.bgCheck.guarantor,
    // vasionScreenTestCost: form.bgCheck.guarantor === true ? 4000 : 0,
    // drugTest: form.bgCheck.employment,
    // drugTestCost: form.bgCheck.employment === true ? 4000 : 0,
    // medHistoryCheck: form.bgCheck.education,
    // medHistoryCheckCost: form.bgCheck.education === true ? 4000 : 0,
    // BG VERIFICATION ENDS
  };

  // console.log("employeeData", employeeData);

  const updateEmployeeCount = async () => {
    if (!updateEmployerRecord) {
      console.error("updateEmployerRecord is not defined in DashboardContext");
      return;
    }

    // Ensure that organization is not null or undefined
    if (
      !organization ||
      typeof organization.numberOfEmployees === "undefined"
    ) {
      console.error("organization or numberOfEmployees is not defined");
      return;
    }

    const updatedOrganization: Organization = {
      ...organization,
      numberOfEmployees: (organization.numberOfEmployees || 0) + 1, // Safely increment numberOfEmployees
      // Add other properties as needed
    };

    console.log(updatedOrganization);

    await updateEmployerRecord(updatedOrganization);
    // toast({
    //   message: "Profile updated successfully.",
    //   variant: "success",
    // });
    clearForm();
    window.location.reload();
  };

  // updateEmployeeCount();

  const onboardEmployeeHandler = async (
    event: React.ChangeEvent<HTMLFormElement>
  ) => {
    // emailInputRef.current!.focus();
    event.preventDefault();
    if (!formIsValid) {
      // console.log("Complete Form");
      toast({
        message: "Please input all required field",
        variant: "warning",
      });
    }

    // handleNext();

    onboardEmployee(
      process.env.REACT_APP_API_BASE_URL + "/employee/create",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          employerRefId: user?.employerRefId,
          // employeeList: formdata,
          employeeList: [employeeData],
        }),
      },
      (response: HttpResponse<any>) => {
        // onClose();
        // eslint-disable-next-line @typescript-eslint/no-unused-expressions

        toast({
          message: "Successfully onboarded Employee.",
          variant: "success",
        });
        // console.log(response);
        updateEmployeeCount();
      }
    );
  };

  return (
    <React.Fragment>
      {/* <Box className="text-center">
        <Typography
          variant="h1"
          fontSize={{ xs: "24px", sm: "28px", md: "28px" }}
          component="div"
          gutterBottom
          mb={5}
        >
          Household Employee Details
        </Typography>
      </Box> */}

      <Grid
        container
        spacing={1}
        className="md:mx-auto md:place-content-center lg:gap-2"
        component="form"
        onSubmit={onboardEmployeeHandler}
      >
        <Grid item xs={12} sm={6} md={4.5} lg={4.5} className="">
          <label className=" font-areaSemi text-xs text-[#6B6B6B]">
            Start Date
          </label>

          <MHDatePicker
            // value={
            //   (startDate ?? dayjs("2024-04-17")) === dayjs("2024-04-17")
            //     ? form.startDate ?? dayjs("2024-04-17")
            //     : startDate
            // }
            value={startDate}
            onChange={(newvalue) => setStartDate(newvalue)}
          />
        </Grid>

        <Grid item xs={12} sm={6} md={4.5} lg={4.5} className="">
          <label className=" font-areaSemi text-xs text-[#6B6B6B]">
            End Date
          </label>
          <br />
          <Box className="grid grid-cols-7 gap-2">
            {employeeState.checkedDate === true ? (
              <Box className="col-span-6">
                <MHDatePicker
                  value={endDate} // Convert endDate to Dayjs if needed
                  onChange={(newvalue) => setEndDate(newvalue)}
                />
              </Box>
            ) : null}

            <MHSwitch
              value={employeeState.checkedDate}
              onChange={handleDateChange}
            />
          </Box>
        </Grid>

        <Grid item xs={12} sm={6} md={4.5} lg={4.5} className="">
          <MHFormControl
            id="salary"
            type="salary"
            label=" Basic Salary"
            placeholder="Basic Salary"
            value={enteredSalary}
            // value={enteredSalary === "" ? form.salary : enteredSalary}
            onChange={salaryInputChangeHandler}
            onBlur={salaryInputBlurHandler}
            startAdornment={
              <InputAdornment className="bg-[#EEEEEE] md:h-[43px]">
                <NairaIcon className="w-4 h-[43px] md:h-4" />
              </InputAdornment>
            }
            required
            autoFocus
          />
        </Grid>

        <Grid item xs={12} sm={6} md={4.5} lg={4.5} className="mt-[2px]">
          <MHAutocomplete
            label="Payment Frequency"
            placeholder="Select Payment Frequency"
            options={payFrequencyOptions}
            onInputChange={(val) => paymentInputChangeHandler(val as string)}
          />
          {/* <MHSelect
            label=" Payment Frequency"
            placeholder="Payment Frequency"
            options={FREQUENCY}
            value={
              enteredPayment === "" ? form.payFrequency ?? "" : enteredPayment
            }
            onChange={(val) => paymentInputChangeHandler(val as string)}
            onBlur={paymentInputBlurHandler}
            error={resolveErrorMessage(enteredPaymentHasError)(
              "Please select Payment Frequecy"
            )}
          /> */}
        </Grid>

        {/* <Grid item xs={12} sm={6} md={4.5} lg={4.5} className="">
          <MHSelect
            label="When will you like to process your payment"
            placeholder="Process Date"
            options={FREQUENCY}
            value={enteredProcessDate}
            onChange={(val) => processDateInputChangeHandler(val as string)}
            onBlur={processDateInputBlurHandler}
            error={resolveErrorMessage(enteredProcessDateHasError)(
              "Please select employee Process Date"
            )}
          />
        </Grid>

        <Grid item xs={12} sm={6} md={4.5} lg={4.5} className="">
          <MHFormControl
            id="lastday"
            type="text"
            label="Due for the first payment"
            placeholder="Last business day of the month"
            value={enteredLastDay}
            onChange={lastDayInputChangeHandler}
            onBlur={lastDayInputBlurHandler}
            required
            autoFocus
          />
        </Grid> */}

        <Grid item xs={12} sm={6} md={4.5} lg={4.5}>
          <Link
            className=" no-underline cursor-pointer"
            // onClick={() => setClickState(false)}
          >
            <Box
              className={`h-auto w-full md:w-[350px] lg:w-[400px] ring-1 ring-[#009688] rounded-lg md:mr-2 lg:mx-auto py-6 px-4 mb-3 md:mb-0  grayscale-0 opacity-100`}
            >
              <Typography
                variant="h1"
                fontSize={{ xs: "18px", sm: "20px", md: "20px" }}
                component="div"
                gutterBottom
                mb={2}
              >
                Health / HMO
              </Typography>
              <Typography
                variant="body1"
                fontSize={{ xs: "13px", sm: "13px", md: "13px" }}
                lineHeight={{ xs: "22px", sm: "22px" }}
                component="div"
                gutterBottom
                mb={3}
              >
                Health/HMO provides health insurance coverage for your employee.
              </Typography>
              <Box className=" space-y-6">
                {/* {healthArray.map((item, index) => (
                  <MHCheckboxContent
                    key={index}
                    label={item.label}
                    amount={item.amount}
                    value={hmoCheck}
                    // value={item.location}
                    handleCheck={(e) => {
                      setHmoCheck(!hmoCheck);
                    }}
                  />
                ))} */}

                <MHRadioContent
                  row
                  name="allocation-period"
                  id="allocation-period"
                  options={[
                    {
                      value: "lashma",
                      amount: 3000,
                      label: "Lagos State Health Management Agency (LASHMA)",
                    },
                    {
                      value: "casava",
                      amount: 3500,
                      label: "Casava MicroHealth Insurance",
                    },
                  ]}
                  value={enteredHmo}
                  onChange={hmoInputChangeHandler}
                  // color="default"
                  controlSx={{
                    mb: 0,
                  }}
                />
              </Box>
            </Box>
          </Link>
          <Box display="flex" gap={1} alignItems="top" mt={2}>
            <VerifiedIcon />

            <Typography
              variant="caption"
              fontSize={{ xs: "13px", sm: "13px", md: "13px" }}
              lineHeight={{ xs: "22px", sm: "22px" }}
              component="div"
              gutterBottom
            >
              LASRRA CARD
              <p>(For Lagos State Residents)</p>
            </Typography>
          </Box>
        </Grid>

        <Grid item xs={12} sm={6} md={4.5} lg={4.5} className="">
          <MHFormControl
            id="meals"
            type="meals"
            label=" Other Earnings"
            placeholder="Meals"
            value={enteredMeals}
            // value={enteredMeals === "" ? form.meals : enteredMeals}
            onChange={mealsInputChangeHandler}
            onBlur={mealsInputBlurHandler}
            startAdornment={
              <InputAdornment className="bg-[#EEEEEE] md:h-[43px]">
                <NairaIcon className="w-4 h-[43px] md:h-4" />
              </InputAdornment>
            }
            // required
            autoFocus
          />
          <MHFormControl
            id="transport"
            type="transport"
            label=""
            placeholder="Transportation"
            value={enteredTransport}
            // value={
            //   enteredTransport === "" ? form.transportation : enteredTransport
            // }
            onChange={transportInputChangeHandler}
            onBlur={transportInputBlurHandler}
            startAdornment={
              <InputAdornment className="bg-[#EEEEEE] md:h-[43px]">
                <NairaIcon className="w-4 h-[43px] md:h-4" />
              </InputAdornment>
            }
            // required
            autoFocus
          />
          <MHFormControl
            id="accomodation"
            type="accomodation"
            label=""
            placeholder="Accomodation"
            value={enteredAccomodation}
            // value={
            //   enteredAccomodation === ""
            //     ? form.accomodation
            //     : enteredAccomodation
            // }
            onChange={accomodationInputChangeHandler}
            onBlur={accomodationInputBlurHandler}
            startAdornment={
              <InputAdornment className="bg-[#EEEEEE] md:h-[43px]">
                <NairaIcon className="w-4 h-[43px] md:h-4" />
              </InputAdornment>
            }
            // required
            autoFocus
          />
          <MHFormControl
            id="airtime"
            type="airtime"
            label=""
            placeholder="Airtime/ Data"
            value={enteredAirtime}
            // value={enteredAirtime === "" ? form.airtime : enteredAirtime}
            onChange={airtimeInputChangeHandler}
            onBlur={airtimeInputBlurHandler}
            startAdornment={
              <InputAdornment className="bg-[#EEEEEE] md:h-[43px]">
                <NairaIcon className="w-4 h-[43px] md:h-4" />
              </InputAdornment>
            }
            // required
            autoFocus
          />

          <div>
            {addMoreClicked && (
              <>
                {textFields.map((textField) => (
                  <div key={textField.id} className="grid grid-cols-8">
                    <div className="col-span-7">
                      <MHFormControl
                        key={textField.id}
                        id={`benefits_${textField.id}`}
                        type="benefits"
                        label=""
                        placeholder={`Benefits ${textField.id}`}
                        value={textField.value}
                        onChange={(e) =>
                          handleChange(textField.id, e.target.value)
                        }
                        onBlur={benefitsInputBlurHandler} // Assuming you have defined this function
                        startAdornment={
                          <InputAdornment className="bg-[#EEEEEE] md:h-[43px]">
                            <NairaIcon className="w-4 h-[43px] md:h-4" />
                          </InputAdornment>
                        }
                        // required
                        autoFocus={textField.id === 0} // Focus only on the first field
                      />
                    </div>
                    {textFields.length > 0 && ( // Render delete button if there's more than one text field
                      <Button
                        onClick={() => handleDeleteTextField(textField.id)}
                      >
                        <DeleteIcon className="-mt-4 w-4 h-4 text-navy-900" />
                      </Button>
                    )}
                  </div>
                ))}
              </>
            )}
            <Box className="text-center" onClick={handleAddTextField}>
              <Button className="no-underline text-blue-100">+ Add More</Button>
            </Box>
          </div>
        </Grid>

        <Grid
          container
          spacing={1}
          className=" md:place-content-start lg:gap-2"
        >
          {/* <Grid item xs={12} sm={6} md={4.5} lg={4.5} className=" space-y-2">
            <div>
              <MHSwitch
                label={`Use instant calculator to prorate salary`}
                value={employeeState.salaryState}
                onChange={handleSalaryChange}
              />
            </div>
            {employeeState.salaryState && (
              <>
                <MHFormControl
                  id="prosalary"
                  type="prosalary"
                  label=""
                  placeholder="5,000"
                  value={enteredProrateSalary}
                  onChange={prorateSalaryInputChangeHandler}
                  onBlur={prorateSalaryInputBlurHandler}
                  startAdornment={
                    <InputAdornment className="bg-[#EEEEEE] md:h-[43px]">
                      <NairaIcon className="w-4 h-[43px] md:h-4" />
                    </InputAdornment>
                  }
                  autoFocus
                />
              </>
            )}
          </Grid> */}

          {/* <Grid
            item
            xs={12}
            sm={6}
            md={4.5}
            lg={4.5}
            className=" ml-[9rem] space-y-4"
          >
            <div>
              <Typography
                variant="body1"
                fontSize={{ xs: "13px", sm: "13px", md: "13px" }}
                lineHeight={{ xs: "22px", sm: "22px" }}
                component="div"
                gutterBottom
                mt={1}
              >
                Will you like to provide a 13th month bonus?
              </Typography>
            </div>
            <div>
              <MHFormControl
                id="bonus"
                type="bonus"
                label=""
                placeholder="Bonus"
                value={enteredBonus}
                onChange={bonusInputChangeHandler}
                onBlur={bonusInputBlurHandler}
                startAdornment={
                  <InputAdornment className="bg-[#EEEEEE] md:h-[43px]">
                    <NairaIcon className="w-4 h-[43px] md:h-4" />
                  </InputAdornment>
                }
                disabled={employeeState.saveState === false ? false : true}
                autoFocus
              />
              <MHSwitch
                label={`Save 8.3% monthly from wallet (13 month Salary)”`}
                value={employeeState.saveState}
                onChange={handleSaveChange}
              />
            </div>
          </Grid> */}
        </Grid>

        <Grid item xs={12} sm={6} md={12} lg={9}>
          <Box className="w-full flex  mt-6">
            <Box className=" ">
              <MHButton
                color="info"
                sx={{}}
                type="submit"
                className=" w-[120px] "
                startIcon={<ArrowLeftIcon />}
                onClick={handlePrev}
              >
                Back
              </MHButton>
            </Box>

            <Box className=" mr-0 ml-auto">
              <MHButton
                loading={loading}
                type="submit"
                className="  w-[120px] "
              >
                Onboard
              </MHButton>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default EmployeeSalaryBenefit;
