import React from "react";
import TrainingCardPattern from "../../../static/svg/training-pattern.svg";
import { ReactComponent as CheckBlue } from "../../../static/svg/check-blue.svg";
import { ReactComponent as PersonCheck } from "../../../static/svg/person-check.svg";
import { Box, SxProps, Theme, Typography } from "@mui/material";

export type TrainingTopCardProps = {
  //   theme: "dark" | "light" | "default";
  //   icon?: React.ReactElement;
  //   icon2?: React.ReactElement;
  onClick?: (e: React.MouseEvent) => void;
  title?: string;
  role?: string;
  stat?: string | number | any;
  noOfCourses?: string | number | any;
  noOfAssigned?: string | number | any;
  containerSx?: SxProps<Theme>;
};

const GradientCard = (props: TrainingTopCardProps) => {
  return (
    <Box
      p={3}
      className="linearColor"
      //   bgcolor={"#E4F0DE"}
      borderRadius={2}
      boxShadow="0px 5px 26px #C7D8E4"
      minHeight={225}
      sx={{
        ...props.containerSx,
        gap: 2,
        pt: 5,
        mb: 3,
        position: "relative",
        overflow: "hidden",
      }}
      display={"flex"}
    >
      <Box className=" absolute top-0 right-0">
        <img
          src={TrainingCardPattern}
          alt="error"
          className="object-cover h-fit w-fit z-40 "
        />
      </Box>

      <Box className=" z-50">
        <Typography
          variant="body1"
          fontSize={{ xs: "17px", sm: "17px", md: "17px" }}
          color={"primary.main"}
          gutterBottom
          className={`w-full md:w-[70%] lg:w-[60%] z-50`}
        >
          {props.title}
        </Typography>
      </Box>
      <Box className="absolute bottom-5 right-5 z-50">
        <Typography
          variant="subtitle1"
          fontSize={{ xs: "12px", sm: "12px", md: "12px" }}
          color={"primary.main"}
          gutterBottom
          className={` z-50`}
        >
          {props.role}
        </Typography>
        <Typography
          variant="body1"
          fontSize={{ xs: "10px", sm: "10px", md: "10px" }}
          color={"primary.main"}
          gutterBottom
          className={` z-50`}
        >
          {props.stat}
        </Typography>
      </Box>
    </Box>
  );
};

const CourseCard = (props: TrainingTopCardProps) => {
  return (
    <Box
      p={3}
      className=""
      bgcolor={"#F4F4F4"}
      borderRadius={2}
      boxShadow="0px 5px 26px #C7D8E4"
      minHeight={208}
      minWidth={291}
      sx={{
        ...props.containerSx,
        gap: 2,
        pt: 5,
        mb: 3,
        position: "relative",
        overflow: "hidden",
      }}
      display={"flex"}
    >
      <Box className=" absolute top-6 left-6">
        <CheckBlue />
      </Box>
      <Box className="mx-auto">
        <Box className="bg-white rounded-[50%] py-1 mx-2 text-center">
          <Typography
            variant="h1"
            fontSize={{ xs: "80px", sm: "80px", md: "80px" }}
            color={"primary.main"}
            gutterBottom
            className={`z-50`}
          >
            {props.noOfCourses || 0}
          </Typography>
        </Box>
        <Box className="">
          <Typography
            variant="subtitle1"
            fontSize={{ xs: "12px", sm: "12px", md: "12px" }}
            color={"#A9A9A9"}
            gutterBottom
            className={`mt-2 z-50`}
          >
            No. of trainings completed
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

const AssignCard = (props: TrainingTopCardProps) => {
  return (
    <Box
      p={3}
      className=""
      bgcolor={"#FFFFFF"}
      borderRadius={2}
      boxShadow="0px 5px 26px #C7D8E4"
      minHeight={208}
      minWidth={291}
      sx={{
        ...props.containerSx,
        gap: 2,
        pt: 5,
        mb: 3,
        position: "relative",
        overflow: "hidden",
      }}
      display={"flex"}
    >
      <Box className=" absolute top-6 left-6">
        <PersonCheck />
      </Box>
      <Box className="mx-auto">
        <Box className="bg-[#E4F0DE] rounded-[50%] py-1 mx-2 text-center">
          <Typography
            variant="h1"
            fontSize={{ xs: "80px", sm: "80px", md: "80px" }}
            color={"primary.main"}
            gutterBottom
            className={`z-50`}
          >
            {props.noOfAssigned || 0}
          </Typography>
        </Box>
        <Box className="">
          <Typography
            variant="subtitle1"
            fontSize={{ xs: "12px", sm: "12px", md: "12px" }}
            color={"#A9A9A9"}
            gutterBottom
            className={`mt-2 z-50`}
          >
            No. of trainings assigned
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

const TrainingTopCard = (props: TrainingTopCardProps) => {
  return (
    <Box className="grid grid-cols-8 lg:flex lg:flex-row justify-center gap-8 h-fit px-4 lg:px-0">
      <Box className=" col-span-8 md:col-span-8 lg:basis-[40%]">
        <GradientCard title={props.title} role={props.role} stat={props.stat} />
      </Box>
      <Box className=" col-span-8 md:col-span-4 lg:basis-[23%]">
        <CourseCard noOfCourses={props.noOfCourses} />
      </Box>
      <Box className=" col-span-8 md:col-span-4 lg:basis-[23%]">
        <AssignCard noOfAssigned={props.noOfAssigned} />
      </Box>
    </Box>
  );
};

export default TrainingTopCard;
