import React from "react";
import { NavLink, useHistory, useLocation } from "react-router-dom";

import MuiDrawer from "@mui/material/Drawer";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { styled, SxProps, useTheme, Theme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

import { ReactComponent as InsightsIcon } from "../../static/svg/insights.svg";
import { ReactComponent as SettingsIcon } from "../../static/svg/setting.svg";
import { ReactComponent as MemberIcon } from "../../static/svg/member.svg";
import { ReactComponent as WalletIcon } from "../../static/svg/wallet.svg";
import { ReactComponent as EventIcon } from "../../static/svg/events.svg";
import { ReactComponent as MaidIcon } from "../../static/svg/maid.svg";
import { ReactComponent as FamilyIcon } from "../../static/svg/family.svg";
import { ReactComponent as HouseIcon } from "../../static/svg/house.svg";
import { ReactComponent as CorporateIcon } from "../../static/svg/corporate.svg";
// import { ReactComponent as ResourcesIcon } from "../../static/svg/receipt.svg";
// import { ReactComponent as SearchIcon } from "../../static/svg/search.svg";

import { DRAWER_WIDTH } from "../../utils/constants";
import { FnComponent } from "../../models/component.model";
import LayoutContext from "../../store/context/layout.context";
import AuthContext from "../../store/context/auth-context";

interface RouterLinkProps {
  icon?: React.ReactElement;
  primary: string;
  to: string;
}

const SideDrawer = styled(MuiDrawer, {
  // shouldForwardProp: (prop) => prop !== "open", // **leave as commented**
})(({ theme, open }) => ({
  "& .MuiDrawer-paper": {
    position: "sticky",
    zIndex: theme.zIndex.appBar + 1000,
    whiteSpace: "nowrap",
    width: DRAWER_WIDTH,
    height: "100vh",
    paddingTop: theme.spacing(5),
    background: theme.palette.common.white,
    borderWidth: 0,
    boxShadow: "2px 4px 4px 0px #B7B7B740",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    boxSizing: "border-box",
    ...(!open && {
      overflowX: "hidden",
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: theme.spacing(7),
      [theme.breakpoints.up("sm")]: {
        width: theme.spacing(9),
      },
    }),
  },
}));

const ListStyled = styled(
  List,
  {}
)<{
  component?: React.ElementType;
}>(({ theme }) => ({
  // '&:before': {
  //   content: '""',
  //   position: 'absolute',
  //   height: 52,
  //   display: 'block',
  //   left: 0,
  //   top: 0,
  //   width: 6,
  //   backgroundColor: theme.palette.primary.main,
  //   borderTopRightRadius: '.4rem',
  //   borderBottomRightRadius: '.4rem',
  //   transition: '0.5s',
  //   transform: 'translateY(0)'
  // },
  // '& .MuiListItem-root.active:nth-child(2) ~ &:after': {
  //   transform: 'translateY(52px)'
  // }
}));

const Indicator = styled("div")(({ theme }) => ({
  position: "absolute",
  height: 52,
  display: "block",
  left: 0,
  top: 0,
  width: 5,
  backgroundColor: theme.palette.primary.main,
  borderTopRightRadius: ".4rem",
  borderBottomRightRadius: ".4rem",
  transition: "0.5s",
  transform: "translateY(0)",
  ".MuiListItem-root.active:nth-of-type(2) ~ &": {
    transform: `translateY(${52}px)`,
  },
  ".MuiListItem-root.active:nth-of-type(3) ~ &": {
    transform: `translateY(${52 * 2}px)`,
  },
  ".MuiListItem-root.active:nth-of-type(4) ~ &": {
    transform: `translateY(${52 * 3}px)`,
  },
  ".MuiListItem-root.active:nth-of-type(5) ~ &": {
    transform: `translateY(${52 * 4}px)`,
  },
  ".MuiListItem-root.active:nth-of-type(6) ~ &": {
    transform: `translateY(${52 * 5}px)`,
  },
  ".MuiListItem-root.active:nth-of-type(7) ~ &": {
    transform: `translateY(${52 * 6}px)`,
  },
  ".MuiListItem-root.active:nth-of-type(8) ~ &": {
    transform: `translateY(${52 * 7}px)`,
  },
  ".MuiListItem-root.active:nth-of-type(9) ~ &": {
    transform: `translateY(${52 * 8}px)`,
  },
  ".MuiListItem-root.active:nth-of-type(10) ~ &": {
    transform: `translateY(${52 * 9}px)`,
  },
}));

const ListItemStyled = styled(ListItem)<{
  component?: React.ElementType;
  to: string;
}>(({ theme }) => ({
  position: "relative",
  cursor: "pointer",
  height: 52,
}));

const RouterLink = (props: RouterLinkProps) => {
  const { icon, primary, to } = props;

  return (
    <ListItemStyled
      component={NavLink}
      to={to}
      alignItems="center"
      sx={{
        "& svg": {
          color: "secondary.light",
          transition: "0.5s",
          transform: "translateX(0) rotate(0)",
        },
        "&.active svg": {
          color: "primary.main",
          transition: ".5s",
        },
        "&:hover": {
          textDecoration: "none",
        },
        "&.active:after": {
          content: '""',
          position: "absolute",
          height: "100%",
          display: "block",
          zIndex: 1,
          left: 0,
          top: 0,
          width: 6,
          // bgcolor: 'primary.main',
          borderTopRightRadius: ".4rem",
          borderBottomRightRadius: ".4rem",
        },
      }}
    >
      <ListItemIcon
        sx={{
          minWidth: 40,
        }}
      >
        {icon}
      </ListItemIcon>
      <ListItemText
        primary={primary}
        primaryTypographyProps={{
          sx: {
            color: "secondary.light",
            ".active &": {
              color: "primary.main",
            },
            transition: "0.5s",
          },
        }}
      />
    </ListItemStyled>
  );
};

const MainSidebar: FnComponent<{ sx?: SxProps<Theme> }> = ({ sx }) => {
  const layoutCtx = React.useContext(LayoutContext);
  const { isSidebarOpen, sidebarVariant, anchor, toggleSidebar } = layoutCtx;

  const theme = useTheme();
  const matchesMdDown = useMediaQuery(theme.breakpoints.down("md"));

  const history = useHistory();
  const location = useLocation();
  let currentPath = location.pathname;
  // console.log("location", location.pathname);

  const authCtx = React.useContext(AuthContext);
  const { user } = authCtx;

  React.useEffect(() => {
    // close sidebar when route changes
    if (matchesMdDown) {
      history.listen((location, action) => {
        toggleSidebar(false)({} as React.MouseEvent<HTMLDivElement>);
      });
    }
  }, []);

  return (
    <SideDrawer
      variant={sidebarVariant}
      open={isSidebarOpen}
      anchor={anchor}
      onClose={toggleSidebar(false)}
      sx={{ ...sx }}
    >
      <Toolbar />

      {/* we should have 2 ListStyled,  1 would be hidden while user.role === Employee 
      while the other is shown and vice versa */}
      <ListStyled component="nav" sx={{}} disablePadding>
        {/* <ListItem component={NavLink} to="/worklife-dashboard/dashboard"></ListItem> */}
        {authCtx.isAuthenticated && currentPath.includes("organization") ? (
          authCtx.role === "Employer" ? (
            <>
              <RouterLink
                icon={<MemberIcon />}
                primary="Dashboard"
                to="/organization/dashboard"
              />
              <RouterLink
                icon={<InsightsIcon />}
                primary="Documents"
                to="/organization/documents"
              />
              <RouterLink
                icon={<EventIcon className="!w-fit" />}
                primary="Training"
                to="/organization/training"
              />
              <RouterLink
                icon={<WalletIcon />}
                primary="Transactions"
                to="/organization/transactions"
              />
              <RouterLink
                icon={<InsightsIcon />}
                primary="Articles"
                to="/organization/articles"
              />
            </>
          ) : authCtx.role === "Employee" ? (
            <>
              <RouterLink
                icon={<MemberIcon />}
                primary="Dashboard"
                to="/organization/employee/dashboard"
              />
              <RouterLink
                icon={<SettingsIcon />}
                primary="Profile"
                to="/organization/employee/profile"
              />
              <RouterLink
                icon={<EventIcon className="!w-fit" />}
                primary="Training"
                to="/organization/employee/training"
              />
            </>
          ) : null
        ) : (
          <>
            <RouterLink icon={<HouseIcon />} primary="Home" to="/" />
            <RouterLink
              icon={<FamilyIcon />}
              primary="Families"
              to="/families"
            />
            <RouterLink
              icon={<CorporateIcon />}
              primary="Corporate"
              to="/corporate"
            />
            <RouterLink
              icon={<MaidIcon />}
              primary="Caregivers"
              to="/caregivers"
            />
            {/* <RouterLink
              icon={<EventIcon className="!w-fit" />}
              primary="Training"
              to="/training"
            /> */}
          </>
        )}

        {/* <RouterLink
          icon={<CreditCardIcon className="!w-[70%]" />}
          primary="Payout Statement"
          to="/organization/statement"
        /> */}

        {/* <RouterLink
          icon={<WalletIcon />}
          primary="Wallet"
          to="/organization/wallet"
        />
        <RouterLink
          icon={<PiggyBankIcon width="1.6rem" />}
          primary="Care Grant"
          to="/organization/emergency-savings"
        />
        <RouterLink
          icon={<AccountingIcon />}
          primary="Accounting"
          to="/organization/accounting"
        /> */}

        {/* <RouterLink
          icon={<MemberIcon />}
          primary="Admin"
          to="/organization/team-members"
        /> */}

        {/* <RouterLink
          icon={<SearchIcon />}
          primary="FAQ"
          to="/organization/faq"
        /> */}
        {!matchesMdDown && <Indicator />}
      </ListStyled>
    </SideDrawer>
  );
};

export default MainSidebar;
