import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import { TrainingData } from "./TrainingCard";
import ResourceCard from "./ResourceCard";

type Props = {
  dataIn?: TrainingData[];
};

const TrainingPastVideos = (props: Props) => {
  let slicedData = props.dataIn?.slice(0, 4);
  return (
    <React.Fragment>
      <Box p={{ xs: 4, sm: 6, md: 8 }}>
        <Box className=" text-center mx-auto mb-6">
          <Typography
            variant="caption"
            color={"primary"}
            fontSize={{ xs: "20px", sm: "24px", md: "26px" }}
            width={{ xs: "300px", sm: "350px", md: "497px" }}
            lineHeight={{ xs: "40px", sm: "40px", md: "53px" }}
            textAlign={{ xs: "center", sm: "center" }}
            letterSpacing={"0.01em"}
            className=" capitalize"
          >
            lets do it together{" "}
          </Typography>
        </Box>
        <Box>
          <Grid
            item
            xs={12}
            sm={12}
            md={8}
            className=" mx-auto place-content-center"
            height={"100%"}
          >
            <Grid container spacing={4} className="">
              {props.dataIn && props.dataIn?.length < 1 ? (
                <Grid item xs={12} sm={6} md={4} className=" ">
                  <h1>....Coming Soon</h1>
                </Grid>
              ) : (
                slicedData?.map((data, index) => (
                  <Grid item xs={12} sm={6} md={3} className=" " key={index}>
                    <ResourceCard
                      // cardClass="relative w-[260px] h-[440px] shadow-none object-cover bg-cream-100 rounded-md"
                      // imageBackground="bg-navy-100 h-[260px]"
                      // imageBackground="bg-pink-700  h-[260px]"
                      topArticleCategory={data.category}
                      podTop="hidden"
                      cardContentBg="#F9F9F7"
                      imageBackground="#B4B2D7B2"
                      title={data.title}
                      imageSrc={data.imgSrc}
                      linkTo={"/organization/training/all"}
                      playUrl={"/organization/training/all"}
                      video
                      blackOverlay
                      // linkTo={`${resourcesPath}/articles/${res.slug}/${res.id}`}
                      // playUrl={`${resourcesPath}/articles/${res.slug}/${res.id}`}
                    />
                  </Grid>
                ))
              )}
            </Grid>
          </Grid>
        </Box>
      </Box>
    </React.Fragment>
  );
};

export default TrainingPastVideos;
