import { Box, Typography } from "@mui/material";
import React from "react";
import GradientCard from "../../components/Features/Household/TrainingTopCard";
import TrainingTopCard from "../../components/Features/Household/TrainingTopCard";
import InputAdornment from "../../components/Common/Form/InputAdornment";

import * as validators from "../../utils/validators";
import useInput from "../../hooks/use-input";
import MHFormControl from "../../components/Common/Form/MHFormControl";

import { ReactComponent as SearchIcon } from "../../static/svg/search.svg";
import CertificateCard from "../../components/Features/Household/CertificateCard";
import useHttp from "../../hooks/use-http";
import { ContractProps } from "../../models/contract.model";
import { getURLWithQueryParams } from "../../utils/utils";
import { HttpResponse } from "../../models/api.interface";
import { useHistory } from "react-router-dom";
import DashboardContext from "../../store/context/dashboard.context";
import { TrainingData } from "../../components/Features/Training/TrainingCard";

type Props = {};

const EmployeeTraining = (props: Props) => {
  const [word, setWord] = React.useState("");

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setWord(event.target.value);
  };

  const dashboardCtx = React.useContext(DashboardContext);
  const { organization } = dashboardCtx;
  let history = useHistory();

  const { loading, error, sendHttpRequest } = useHttp();
  const [contractDetails, setContractDetails] =
    React.useState<ContractProps | null>(null);

  // console.log("customerId", customerId);

  React.useEffect(() => {
    sendHttpRequest(
      getURLWithQueryParams(
        process.env.REACT_APP_API_BASE_URL + "/employer/contract",
        {
          customerId: String(organization?.customerId),
        }
      ),
      {
        method: "GET",
      },
      (response: HttpResponse<ContractProps>) => {
        if (response.status === 200) {
          // console.log(response.data);
          setContractDetails(response.data);
        } else {
          console.error("Failed to fetch contract details:", response);
        }
      }
    ).catch((err) => {
      console.error("Error fetching contract details:", err);
    });
  }, [organization?.customerId]);

  React.useEffect(() => {
    if (contractDetails?.status !== "COMPLETED") {
      if (contractDetails?.customerId) {
        history.push(
          `/organization/employee/contract-agreement/${contractDetails?.customerId}`
        );
      }
    }
  }, [contractDetails?.customerId]);

  const [training, setTraining] = React.useState<TrainingData[]>([]);

  const { sendHttpRequest: employeeTrainingReq } = useHttp();

  React.useEffect(() => {
    employeeTrainingReq(
      getURLWithQueryParams(
        process.env.REACT_APP_API_BASE_URL + "/training/employee/trainings",

        {
          customerId: String(organization?.customerId),
        }
      ),
      {
        method: "GET",
      },
      (response: HttpResponse<TrainingData[]>) => {
        setTraining(response.data);
      }
    );
  }, []);

  const attendedTraining =
    training &&
    training.filter(
      (train) => train.attendanceStatus?.toLowerCase() === "attended"
    );

  // console.log("training", training);
  // console.log("attendedTraining", attendedTraining);
  return (
    <React.Fragment>
      <Box className="bg-[#f5f5f5]">
        <Box
          display="flex"
          justifyContent="left"
          pt={{ xs: 4, sm: 8 }}
          className="pl-4 md:pl-10 lg:pl-20"
        >
          <Typography
            variant="h1"
            fontSize={{ xs: "30px", sm: "37px" }}
            align="center"
            mt={3}
            gutterBottom
            display={"flex"}
            alignSelf={"start"}
            className=" capitalize"
          >
            Training
          </Typography>
        </Box>

        <TrainingTopCard
          title={`If you continue to pursue additional training 
and refine your skills, you will find it easier 
to progress in your career.`}
          role={`Blessing Adesiyan`}
          stat={`Founder Caring Blocks`}
          noOfAssigned={training.length}
          noOfCourses={attendedTraining.length}
        />

        <Box className="ml-auto mr-[66px] w-[290px]">
          <MHFormControl
            type="text"
            id="text"
            label=""
            placeholder="Search certificates"
            value={word}
            onChange={handleSearch}
            // onBlur={textInputBlurHandler}
            startAdornment={
              <InputAdornment className="bg-[#EEEEEE] md:h-[43px]">
                <Typography
                  variant="body1"
                  fontSize={{ xs: "10px", sm: "10px", md: "10px" }}
                  lineHeight={"19px"}
                  component="div"
                  gutterBottom
                  className="text-center text-[#5D5D5D] mx-auto items-center align-middle"
                >
                  <SearchIcon />
                </Typography>
              </InputAdornment>
            }
            required
            autoFocus
          />
        </Box>

        <Box>
          <CertificateCard searchWord={word} trainings={attendedTraining} />
        </Box>
      </Box>
    </React.Fragment>
  );
};

export default EmployeeTraining;
