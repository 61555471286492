import { Box, SxProps, Theme, Typography } from "@mui/material";
import React from "react";
import { ReactComponent as DownloadIcon } from "../../../static/svg/download-rounded.svg";
import { ReactComponent as ShareIcon } from "../../../static/svg/share-rounded.svg";
import Lottie from "react-lottie";
import MuiLink from "@mui/material/Link";

import * as animationData from "../../../static/json-animate/Level 1 Certificate.json";
import { MHPagination } from "../../Common/UI/MHPagination";
import { convertUTCToWordDate } from "../../../utils/utils";

type Props = {
  onClick?: (e: React.MouseEvent) => void;
  Title?: string | undefined;
  Level?: string;
  IssuedBy?: string;
  Date?: string | number | any;
  containerSx?: SxProps<Theme>;
};

type ArrayProps = {
  trainings: Props[];
  searchWord?: string | undefined;
};

const IconCard = (props: Props) => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <Box
      px={3}
      py={1}
      className=""
      bgcolor={"#E4F0DE"}
      borderRadius={2}
      boxShadow="0px 5px 26px #C7D8E4"
      minHeight={208}
      minWidth={283}
      sx={{
        ...props.containerSx,
        gap: 2,
        pt: 5,
        mb: 3,
        position: "relative",
        overflow: "hidden",
      }}
      //   display={"flex"}
    >
      <Box className=" bg-white w-[238px] h-[150px] rounded-md text-center">
        {/* <CheckBlue /> */}

        <Lottie options={defaultOptions} height={"150px"} width={"150px"} />
      </Box>
      <Box className="flex justify-between items-center pt-4">
        <Box className="">
          <MuiLink
            // component={Link}
            // underline="always"
            // to="/auth/sign-in"
            // onCLick={handleView}
            // display="block"
            // align="left"
            className="text-blue-100 removeUnderline"
          >
            View
          </MuiLink>
        </Box>
        <Box className=" flex gap-2">
          <DownloadIcon
            className="w-6 h-6"
            //    onClick={handleDownload}
          />
          <ShareIcon
            className="w-6 h-6"

            //   onClick={handleShare}
          />
        </Box>
      </Box>
    </Box>
  );
};

const CardInfo = (props: Props) => {
  return (
    <Box
      px={3}
      py={1}
      className=""
      bgcolor={"#F4F4F4"}
      borderRadius={2}
      boxShadow="0px 5px 26px #C7D8E4"
      minHeight={238}
      minWidth={283}
      sx={{
        ...props.containerSx,
        gap: 2,
        pt: 5,
        mb: 3,
        position: "relative",
        overflow: "hidden",
      }}
      //   display={"flex"}
    >
      <Box className=" bg-[#C9D9E4] w-[70px] h-[30px] rounded-sm text-center ml-0 md:ml-10">
        <Typography
          variant="h1"
          fontSize={{ xs: "13px", sm: "13px", md: "13px" }}
          //   color={"primary.main"}
          gutterBottom
          className={`z-50 text-white p-2`}
        >
          Level {props.Level}
        </Typography>
      </Box>
      <Box className="grid grid-cols-5 md:flex md:justify-between items-center pt-8 md:px-10">
        <Box className=" col-span-5 pt-2">
          <Typography
            variant="body1"
            fontSize={{ xs: "12px", sm: "12px", md: "12px" }}
            color={"#28404A"}
            gutterBottom
            className={`z-50`}
          >
            Certificate of Completion
          </Typography>
          <Typography
            variant="subtitle1"
            fontSize={{ xs: "15px", sm: "15px", md: "17px" }}
            color={"#28404A"}
            gutterBottom
            className={`z-50`}
          >
            {props.Title}
          </Typography>
        </Box>
        <Box className=" col-span-5 pt-2">
          <Typography
            variant="body1"
            fontSize={{ xs: "12px", sm: "12px", md: "12px" }}
            color={"#28404A"}
            gutterBottom
            className={`z-50`}
          >
            Completion Date
          </Typography>
          <Typography
            variant="subtitle1"
            fontSize={{ xs: "15px", sm: "17px", md: "17px" }}
            color={"#28404A"}
            gutterBottom
            className={`z-50 capitalize`}
          >
            {convertUTCToWordDate(props.Date)}
          </Typography>
        </Box>

        <Box className=" col-span-5 pt-2">
          <Typography
            variant="body1"
            fontSize={{ xs: "12px", sm: "12px", md: "12px" }}
            color={"#28404A"}
            gutterBottom
            className={`z-50`}
          >
            Organization
          </Typography>
          <Typography
            variant="subtitle1"
            fontSize={{ xs: "15px", sm: "17px", md: "17px" }}
            color={"#28404A"}
            gutterBottom
            className={`z-50`}
          >
            {props.IssuedBy}
          </Typography>
        </Box>
      </Box>

      <Box className="  pt-4 px-0 md:px-10 lg:hidden">
        <Box className=" flex justify-start items-center gap-2">
          <MuiLink
            // component={Link}
            // underline="always"
            // to="/auth/sign-in"
            // onCLick={handleView}
            // display="block"
            // align="left"
            className="text-blue-100 removeUnderline"
          >
            View
          </MuiLink>
          <DownloadIcon
            className="w-6 h-6"
            //    onClick={handleDownload}
          />
          <ShareIcon
            className="w-6 h-6"

            //   onClick={handleShare}
          />
        </Box>
      </Box>
    </Box>
  );
};

const CertificateCard = (props: ArrayProps) => {
  const [filteredData, setFilteredData] = React.useState<Props[]>([]); // Initialize with an empty array

  const [currentPage, setCurrentPage] = React.useState(1);
  const [postsPerPage, setPostsPerPage] = React.useState(4);
  const lastPostIndex = currentPage * postsPerPage;
  const firstPostIndex = lastPostIndex - postsPerPage;
  const currentPosts = filteredData.slice(firstPostIndex, lastPostIndex);

  let itemsPerPage = 4;
  let noOfPage = Math.ceil((props.trainings?.length || 0) / itemsPerPage);

  const handlePageChange = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    setCurrentPage(value);
  };

  React.useEffect(() => {
    // console.log("Notifications:", props.trainings);
    // console.log("Search Word:", props.searchWord);

    if (props.searchWord) {
      const searchWord = props.searchWord.toLowerCase();
      const filtered = props.trainings.filter(
        (item) =>
          item.Title?.toLowerCase().includes(searchWord) ||
          // item.Level?.toLowerCase().includes(searchWord) ||
          item.IssuedBy?.toLowerCase().includes(searchWord) ||
          item.Date?.toLowerCase().includes(searchWord)
      );
      // console.log("Filtered Results:", filtered);
      setFilteredData(filtered);
    } else {
      setFilteredData(props.trainings);
    }
  }, [props.searchWord, props.trainings]);

  return (
    <Box>
      {currentPosts &&
        currentPosts.map((item, index) => (
          <Box
            className=" flex lg:flex-row gap-4 justify-center px-4 md:px-8 lg:px-0"
            key={index}
          >
            <Box className=" hidden lg:block basis-[20%]">
              <IconCard />
            </Box>
            <Box className="basis-[100%] lg:basis-[70%] ">
              <CardInfo
                Title={item.Title}
                Level={item.Level}
                IssuedBy={item.IssuedBy}
                Date={item.Date}
              />
            </Box>
          </Box>
        ))}

      {/* Pagination */}
      <Box display={"flex"} justifyContent={"center"} mt={2} pb={3}>
        <MHPagination
          count={noOfPage}
          variant="outlined"
          shape="rounded"
          page={currentPage}
          onChange={handlePageChange}
          color={"primary"}
        />
      </Box>
    </Box>
  );
};

export default CertificateCard;
