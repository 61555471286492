import { Box, Divider, Grid, Typography } from "@mui/material";
import React, { useState } from "react";

import MHFormControl from "../../Common/Form/MHFormControl";
import InputAdornment from "../../Common/Form/InputAdornment";
import * as validators from "../../../utils/validators";
import { ReactComponent as EditIcon } from "../../../static/svg/edit-black.svg";
import { ReactComponent as Visibility } from "../../../static/svg/visibility.svg";
import { ReactComponent as VisibilityOff } from "../../../static/svg/visibility-off.svg";

import useInput from "../../../hooks/use-input";
import MHTextInput from "../../Common/Form/MHTextInput";
import MHPasswordControl from "../../Common/Form/MHPasswordControl";
import MHButton from "../../Common/Button/MHButton";
import AuthContext from "../../../store/context/auth-context";
import DashboardContext from "../../../store/context/dashboard.context";
import useDialog from "../../../hooks/use-dialog";
import PinDialog from "./PinDialog";
import { Organization } from "../../../models/employer.model";
import SnackbarContext from "../../../store/context/snackbar.context";
import useHttp from "../../../hooks/use-http";
import { HttpResponse } from "../../../models/api.interface";
import MHAutocomplete, { Option } from "../../Common/Form/MHAutocomplete";
import { NIGERIA_STATES_LCDAS } from "../../../utils/constants";
import MHPhoneInput from "../../Common/Form/MHPhoneInput";
import usePhoneInput from "../../../hooks/use-phone";
import { SelectOption } from "../../Common/Onboarding/Information";
import { resolveErrorMessage } from "../../../utils/utils";
import { IDTYPE } from "../Employees/EmployeeInfo";
import MHDatePicker from "../../Common/Form/MHDatePicker";
import dayjs, { Dayjs } from "dayjs";
import { MHSwitch } from "../../Common/Form/MHSwitch";
type Props = {};

const GuarantorForm = (props: Props) => {
  const authCtx = React.useContext(AuthContext);
  const { user } = authCtx;
  const snackbarCtx = React.useContext(SnackbarContext);
  const { toast } = snackbarCtx;

  const dashboardCtx = React.useContext(DashboardContext);
  const { organization } = dashboardCtx;

  const { loading, error, sendHttpRequest } = useHttp();

  const {
    value: enteredFirstName,
    valid: enteredFirstNameIsValid,
    error: firstNameInputHasError,
    onChange: firstNameInputChangeHandler,
    onBlur: firstNameInputBlurHandler,
  } = useInput([
    {
      validator: (value: string) => validators.required(value),
    },
  ]);
  const {
    value: enteredLastName,
    valid: enteredLastNameIsValid,
    error: lastNameInputHasError,
    onChange: lastNameInputChangeHandler,
    onBlur: lastNameInputBlurHandler,
  } = useInput([
    {
      validator: (value: string) => validators.required(value),
    },
  ]);

  const {
    value: enteredHomeAddress,
    valid: enteredHomeAddressIsValid,
    onChange: homeAddressInputChangeHandler,
    onBlur: homeAddressInputBlurHandler,
  } = useInput([
    {
      validator: (value: string) => validators.required(value),
    },
  ]);

  const {
    value: enteredLcda,
    valid: enteredLcdaIsValid,
    error: enteredLcdaHasError,
    onChange: lcdaInputChangeHandler,
    onBlur: lcdaInputBlurHandler,
  } = useInput([
    {
      validator: (value: string) => validators.required(value),
    },
  ]);
  const {
    value: enteredComment,
    valid: enteredCommentIsValid,
    error: enteredCommentHasError,
    onChange: commentInputChangeHandler,
    onBlur: commentInputBlurHandler,
  } = useInput([
    {
      validator: (value: string) => validators.required(value),
    },
  ]);
  const {
    value: enteredLandmark,
    valid: enteredLandmarkIsValid,
    error: enteredLandmarkHasError,
    onChange: landmarkInputChangeHandler,
    onBlur: landmarkInputBlurHandler,
  } = useInput([
    {
      validator: (value: string) => validators.required(value),
    },
  ]);
  const {
    value: enteredIdentity,
    valid: enteredIdentityIsValid,
    error: enteredIdentityHasError,
    onChange: identityInputChangeHandler,
    onBlur: identityInputBlurHandler,
  } = useInput([
    {
      validator: (value: string) => validators.required(value),
    },
  ]);

  const {
    phoneNumber,
    phoneInfo,
    phoneNumberInputChangeHandler,
    isValid: phoneNumberIsValid,
  } = usePhoneInput([]);

  const [selectedState, setSelectedState] = React.useState<string>("");
  const [filteredLCDAs, setFilteredLCDAs] = React.useState<
    SelectOption<string>[]
  >([]);

  const handleStateChange = (state: string | null) => {
    if (state) {
      setSelectedState(state);
      const selected = NIGERIA_STATES_LCDAS.find((s) => s.state === state);
      setFilteredLCDAs(
        selected
          ? selected.lcda.map((lcda) => ({ label: lcda, value: lcda }))
          : []
      );
    }
  };

  const identityOptions: Option[] = IDTYPE.map((role) => ({
    label: role.label as string,
    value: role.value,
  }));

  const phoneUseNumberIsValid = phoneNumber.trim() !== ""; // Or use a more complex regex check

  const formIsValid =
    enteredFirstNameIsValid &&
    enteredLastNameIsValid &&
    enteredIdentityIsValid &&
    enteredHomeAddressIsValid &&
    enteredLcdaIsValid &&
    enteredLandmarkIsValid &&
    enteredCommentIsValid &&
    phoneUseNumberIsValid;

  const guarantorReq = {
    employerRefId: Number(organization?.employerRefId),
    customerId: Number(organization?.customerId),
    firstName: enteredFirstName,
    lastName: enteredLastName,
    phone: phoneNumber,
    street: enteredHomeAddress,
    lga: enteredLcda,
    comment: enteredComment,
    landmark: enteredLandmark,
    identityType: enteredIdentity,
    verifyAddress: true,
  };

  const handleGuarantor = async (event: React.ChangeEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (!formIsValid) {
      // console.log("Complete Form");
      toast({
        message: "Please input all required field",
        variant: "warning",
      });
    }

    console.log("guarantorReq", guarantorReq);

    sendHttpRequest(
      process.env.REACT_APP_API_BASE_URL + "/background-check/guarantor",
      {
        method: "POST",
        body: JSON.stringify(guarantorReq),
      },
      (response: HttpResponse<unknown>) => {
        toast({
          variant: "success",
          message: "Guarantor form submitted successful!",
        });
        window.location.reload();
      }
    );
    // console.log(
    //   enteredFirstName,
    //   enteredLastName,
    //   enteredComment,
    //   enteredIdentity,
    //   enteredHomeAddress,
    //   enteredLcda,
    //   enteredLandmark,
    //   phoneNumber
    // );
  };

  return (
    <React.Fragment>
      <Box
        className="bg-white w-full drop-shadow-lg py-6"
        id="accountsetting"
        component={"form"}
        onSubmit={handleGuarantor}
      >
        <Box className="pt-3 pl-6 lg:pl-12">
          <Typography
            variant="body1"
            mb={1}
            color="#28404A"
            gutterBottom
            // display={"flex"}
            // alignSelf={"start"}
            fontSize={{ xs: "15px", sm: "15px", md: "15px" }}
            className=""
          >
            Guarantor Form
          </Typography>
          <Typography
            variant="body1"
            mb={2}
            color="#A9A9A9"
            gutterBottom
            // display={"flex"}
            // alignSelf={"start"}
            fontSize={{ xs: "12px", sm: "12px", md: "12px" }}
            className=""
          >
            Kindly fill out this information
          </Typography>
        </Box>

        <Divider />

        <Grid container spacing={2} className=" my-4 place-content-center">
          <Grid item xs={11} sm={6} md={3.5} lg={3.5} className="">
            <MHFormControl
              id="fname"
              type="text"
              label=""
              placeholder="First Name"
              value={enteredFirstName}
              onChange={firstNameInputChangeHandler}
              onBlur={firstNameInputBlurHandler}
              required
              // autoFocus
            />
          </Grid>
          <Grid item xs={11} sm={6} md={3.5} lg={3.5} className="">
            <MHFormControl
              id="lname"
              type="text"
              label=""
              placeholder="Last name"
              value={enteredLastName}
              onChange={lastNameInputChangeHandler}
              onBlur={lastNameInputBlurHandler}
              required
              // autoFocus
            />
          </Grid>
          <Grid item xs={11} sm={6} md={3.5} lg={3.5} className="">
            <MHFormControl
              id="street"
              type="text"
              label=""
              placeholder="Street"
              value={enteredHomeAddress}
              onChange={homeAddressInputChangeHandler}
              onBlur={homeAddressInputBlurHandler}
              required
              // autoFocus
            />
          </Grid>
          <Grid item xs={11} sm={6} md={3.5} lg={3.5} className="">
            <MHFormControl
              id="landmark"
              type="text"
              label=""
              placeholder="Landmark (Popular Area e.g Ikeja)"
              value={enteredLandmark}
              onChange={landmarkInputChangeHandler}
              onBlur={landmarkInputBlurHandler}
              required
              // autoFocus
            />
          </Grid>
          <Grid item xs={11} sm={6} md={3.5} lg={3.5} className="">
            <MHFormControl
              id="comment"
              type="text"
              label=""
              placeholder="Comment"
              value={enteredComment}
              onChange={commentInputChangeHandler}
              onBlur={commentInputBlurHandler}
              required
              // autoFocus
            />
          </Grid>
          <Grid item xs={11} sm={6} md={3.5} lg={3.5} className="">
            <MHAutocomplete
              label=""
              placeholder="Select ID Type"
              options={identityOptions}
              onInputChange={(val) => identityInputChangeHandler(val as string)}
            />
          </Grid>
          <Grid item xs={11} sm={6} md={3.5} lg={3.5} className="">
            <MHAutocomplete
              label=""
              placeholder="Select State"
              options={NIGERIA_STATES_LCDAS.map((state) => ({
                label: state.state,
                value: state.state,
              }))}
              onInputChange={handleStateChange}
            />
          </Grid>
          <Grid item xs={11} sm={6} md={3.5} lg={3.5} className="">
            <MHAutocomplete
              label=""
              placeholder="Select LCDA"
              options={filteredLCDAs}
              onInputChange={(val) => lcdaInputChangeHandler(val as string)}
            />
          </Grid>
          <Grid item xs={11} sm={6} md={3.5} lg={3.5} className="">
            <MHPhoneInput
              value={phoneNumber}
              onChange={phoneNumberInputChangeHandler}
              // label="Phone Number"
              errorMessage={resolveErrorMessage(phoneNumberIsValid)(
                "Please enter a valid phone number"
              )}
            />
          </Grid>
        </Grid>

        <Divider />

        <Box
          display={{ xs: "flex", sm: "flex" }}
          justifyContent="end"
          width={{ xs: "full", sm: "full" }}
          className="gap-4 md:!pr-[50px] py-6 px-4 md:px-0 "
        >
          {/* <MHButton
            sx={{ height: "38px", width: "158px", color: "white" }}
            onClick={handleCancel}
            className=" bg-white text-navy-900 ring-1 ring-navy-900 rounded-sm"
            fullWidth
          >
            Cancel
          </MHButton> */}

          <MHButton
            sx={{ height: "38px", width: { xs: "full", sm: "158px" } }}
            // onClick={handleGuarantor}
            loading={loading}
            type="submit"
            fullWidth
            className="rounded-sm"
          >
            Submit
          </MHButton>
        </Box>
      </Box>
    </React.Fragment>
  );
};
const EmploymentHistoryForm = (props: Props) => {
  const authCtx = React.useContext(AuthContext);
  const { user } = authCtx;
  const snackbarCtx = React.useContext(SnackbarContext);
  const { toast } = snackbarCtx;

  const dashboardCtx = React.useContext(DashboardContext);
  const { organization } = dashboardCtx;

  const { loading, error, sendHttpRequest } = useHttp();

  const {
    value: enteredEmployer,
    valid: enteredEmployerIsValid,
    error: employerInputHasError,
    onChange: employerInputChangeHandler,
    onBlur: employerInputBlurHandler,
  } = useInput([
    {
      validator: (value: string) => validators.required(value),
    },
  ]);

  const {
    value: enteredEmail,
    valid: enteredEmailIsValid,
    onChange: emailInputChangeHandler,
    onBlur: emailInputBlurHandler,
  } = useInput([
    {
      validator: (value: string) => validators.required(value),
    },
  ]);
  const {
    value: enteredContactPerson,
    valid: enteredContactPersonIsValid,
    onChange: contactPersonInputChangeHandler,
    onBlur: contactPersonInputBlurHandler,
  } = useInput([
    {
      validator: (value: string) => validators.required(value),
    },
  ]);

  const {
    phoneNumber,
    phoneInfo,
    phoneNumberInputChangeHandler,
    isValid: phoneNumberIsValid,
  } = usePhoneInput([]);

  const [startDate, setStartDate] = React.useState<Dayjs | null>(
    dayjs("2024-04-17")
  );

  const [endDate, setEndDate] = React.useState<Dayjs | null>(
    dayjs("2024-04-17")
  );

  const [employmentState, setEmploymentState] = useState(false);

  const phoneUseNumberIsValid = phoneNumber.trim() !== ""; // Or use a more complex regex check

  const formIsValid = enteredEmployer && enteredEmail && phoneUseNumberIsValid;

  const employerReq = {
    employerRefId: Number(organization?.employerRefId),
    customerId: Number(organization?.customerId),
    employerName: enteredEmployer,
    employerEmail: enteredEmail,
    employerPhone: phoneNumber,
    startDate: startDate,
    endDate: endDate,
    currentlyEmployed: employmentState,
    contactPersonName: enteredContactPerson,
  };

  const handleEmployerHist = async (
    event: React.ChangeEvent<HTMLFormElement>
  ) => {
    event.preventDefault();
    if (!formIsValid) {
      // console.log("Complete Form");
      toast({
        message: "Please input all required field",
        variant: "warning",
      });
    }

    console.log("employerReq", employerReq);

    sendHttpRequest(
      process.env.REACT_APP_API_BASE_URL +
        "/background-check/employment-history",
      {
        method: "POST",
        body: JSON.stringify(employerReq),
      },
      (response: HttpResponse<unknown>) => {
        toast({
          variant: "success",
          message: "Employment history submitted successful!",
        });
        window.location.reload();
      }
    );
  };

  return (
    <React.Fragment>
      <Box
        className="bg-white w-full drop-shadow-lg py-6"
        id="accountsetting"
        component={"form"}
        onSubmit={handleEmployerHist}
      >
        <Box className="pt-3 pl-6 lg:pl-12">
          <Typography
            variant="body1"
            mb={1}
            color="#28404A"
            gutterBottom
            // display={"flex"}
            // alignSelf={"start"}
            fontSize={{ xs: "15px", sm: "15px", md: "15px" }}
            className=""
          >
            Employment History Form
          </Typography>
          <Typography
            variant="body1"
            mb={2}
            color="#A9A9A9"
            gutterBottom
            // display={"flex"}
            // alignSelf={"start"}
            fontSize={{ xs: "12px", sm: "12px", md: "12px" }}
            className=""
          >
            Kindly fill out this information
          </Typography>
        </Box>

        <Divider />

        <Grid container spacing={2} className=" my-4 place-content-center">
          <Grid item xs={11} sm={6} md={3.5} lg={3.5} className="">
            <MHFormControl
              id="employer"
              type="text"
              label=""
              placeholder="Employer Name"
              value={enteredEmployer}
              onChange={employerInputChangeHandler}
              onBlur={employerInputBlurHandler}
              required
              // autoFocus
            />
          </Grid>
          <Grid item xs={11} sm={6} md={3.5} lg={3.5} className="">
            <MHFormControl
              id="email"
              type="email"
              label=""
              placeholder="Employer email"
              value={enteredEmail}
              onChange={emailInputChangeHandler}
              onBlur={emailInputBlurHandler}
              required
              // autoFocus
            />
          </Grid>
          <Grid item xs={11} sm={6} md={3.5} lg={3.5} className="">
            <MHFormControl
              id="contactPerson"
              type="text"
              label=""
              placeholder="Contact Person"
              value={enteredContactPerson}
              onChange={contactPersonInputChangeHandler}
              onBlur={contactPersonInputBlurHandler}
              required
              // autoFocus
            />
          </Grid>
          <Grid item xs={11} sm={6} md={3.5} lg={3.5} className="">
            <label className=" font-areaSemi text-xs text-[#6B6B6B]">
              Start Date
            </label>

            <MHDatePicker
              value={startDate}
              onChange={(newvalue) => setStartDate(newvalue)}
            />
          </Grid>
          <Grid item xs={11} sm={6} md={3.5} lg={3.5} className="">
            <label className=" font-areaSemi text-xs text-[#6B6B6B]">
              End Date
            </label>

            <MHDatePicker
              value={endDate}
              onChange={(newvalue) => setEndDate(newvalue)}
            />
          </Grid>

          <Grid item xs={11} sm={6} md={3.5} lg={3.5} className="md:mt-3">
            <MHPhoneInput
              value={phoneNumber}
              onChange={phoneNumberInputChangeHandler}
              // label="Phone Number"
              errorMessage={resolveErrorMessage(phoneNumberIsValid)(
                "Please enter a valid phone number"
              )}
            />
          </Grid>
          <Grid item xs={10.5} className="">
            <MHSwitch
              label={`Are you currently employed`}
              value={employmentState}
              onChange={() => setEmploymentState(!employmentState)}
            />
          </Grid>
        </Grid>

        <Divider />

        <Box
          display={{ xs: "flex", sm: "flex" }}
          justifyContent="end"
          width={{ xs: "full", sm: "full" }}
          className="gap-4 md:!pr-[50px] py-6 px-4 md:px-0 "
        >
          {/* <MHButton
            sx={{ height: "38px", width: "158px", color: "white" }}
            onClick={handleCancel}
            className=" bg-white text-navy-900 ring-1 ring-navy-900 rounded-sm"
            fullWidth
          >
            Cancel
          </MHButton> */}

          <MHButton
            sx={{ height: "38px", width: { xs: "full", sm: "158px" } }}
            // onClick={handleGuarantor}
            loading={loading}
            type="submit"
            fullWidth
            className="rounded-sm"
          >
            Submit
          </MHButton>
        </Box>
      </Box>
    </React.Fragment>
  );
};

const BackgroundCheck = (props: Props) => {
  return (
    <React.Fragment>
      <Box className="space-y-8">
        <GuarantorForm />
        <EmploymentHistoryForm />
      </Box>
    </React.Fragment>
  );
};

export default BackgroundCheck;
