import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Dialog,
  Divider,
  TextField,
  Typography,
} from "@mui/material";
import React, { MouseEvent } from "react";
import MuiLink from "@mui/material/Link";
import { useLocation } from "react-router-dom";
import { ReactComponent as TrainOnline } from "../../../static/svg/train-online.svg";
import { ReactComponent as TrainInPerson } from "../../../static/svg/train-inPerson.svg";
import { ReactComponent as TrainOnJob } from "../../../static/svg/train-onJob.svg";
import { ReactComponent as CalendarIcon } from "../../../static/svg/calend.svg";
import { ReactComponent as NairaIcon } from "../../../static/svg/nigeria-naira-icon.svg";
import MHButton from "../../Common/Button/MHButton";
import useDialog from "../../../hooks/use-dialog";
import MHDialog from "../../Common/Dialog/MHDialog";
import {
  convertUTCToWordDate,
  formatAmount,
  getURLWithQueryParams,
} from "../../../utils/utils";
import AuthContext from "../../../store/context/auth-context";
import SnackbarContext from "../../../store/context/snackbar.context";
import useHttp from "../../../hooks/use-http";
import { EmployeeData } from "../../../models/employee.model";
import { HttpResponse } from "../../../models/api.interface";
export type TrainingData = {
  id?: number | any;
  level?: number | any;
  Level?: number | any;
  date?: string | undefined;
  Date?: string | undefined;
  category?: string | undefined;
  Category?: string | undefined;
  status?: string;
  Status?: string;
  trainingMode?: string;
  TrainingMode?: string;
  title?: string;
  Title?: string;
  instructor?: string;
  Instructor?: string;
  reference?: number | any;
  Reference?: number | any;
  price?: number | any;
  Price?: number | any;
  startDate?: string | undefined;
  StartDate?: string | undefined;
  endDate?: string | undefined;
  EndDate?: string | undefined;
  createdDate?: string | undefined;
  CreatedDate?: string | undefined;
  objectives?: string | undefined;
  Objectives?: string | undefined;
  attendanceStatus?: string | undefined;
  imgSrc?: string | any;
  onClicked?: () => void; // Ensure this is correctly typed
  handleChanger?: (course: CourseType) => void; // Updated to accept a course object
  handleTrainingViewClick?: (course: CourseType) => void; // Updated to accept a course object
};

export type CourseType = {
  id?: number | any;
  trainingMode?: string;
  TrainingMode?: string;
  title?: string;
  Title?: string;
  reference?: number | any;
  Reference?: number | any;
  price?: number;
  Price?: number;
  startDate?: string | any;
  StartDate?: string | any;
  address?: string | any;
  Address?: string | any;
  date?: string | undefined;
  Date?: string | undefined;
  instructor?: string;
  Instructor?: string;
  endDate?: string | any;
  EndDate?: string | any;
  createdDate?: string;
  CreatedDate?: string;
  objectives?: string;
  Objectives?: string;
};
const TrainingCard: React.FC<TrainingData> = ({
  trainingMode,
  title,
  reference,
  price,
  startDate,
  endDate,
  createdDate,
  objectives,
  onClicked,
  handleChanger,
  handleTrainingViewClick,
}) => {
  const location = useLocation();
  let currentPath = location.pathname;
  const [checked, setChecked] = React.useState(false);
  const { openDialog, handleOpenDialog, handleCloseDialog } = useDialog();
  const handleChanges = (event: React.ChangeEvent<HTMLInputElement>) => {
    const isChecked = event.target.checked;
    setChecked(isChecked);
    if (handleChanger) {
      handleChanger({
        trainingMode,
        title,
        price,
        startDate,
        endDate,
        createdDate,
        objectives,
      });
    }
  };
  const handleViewDetails = (event: MouseEvent<HTMLButtonElement>) => {
    if (handleTrainingViewClick) {
      handleTrainingViewClick({
        trainingMode,
        title,
        price,
        startDate,
        endDate,
        createdDate,
        objectives,
      });
    }
  };
  const obj = objectives?.split(",");
  // console.log("obj", obj);
  const objects = obj?.slice(0, 3);
  // console.log("objects", objects);
  const [employees, setEmployees] = React.useState<EmployeeData[]>([]);

  // console.log("employees", employees);
  const authCtx = React.useContext(AuthContext);
  const { user } = authCtx;
  const snackbarCtx = React.useContext(SnackbarContext);
  const { toast } = snackbarCtx;
  const { loading, error, sendHttpRequest } = useHttp();
  // console.log("RefID", user?.employerRefId);
  React.useEffect(() => {
    sendHttpRequest(
      getURLWithQueryParams(
        process.env.REACT_APP_API_BASE_URL + "/employee/employer_refId",
        {
          employerRefId: String(user?.employerRefId),
        }
      ),
      {
        method: "GET",
      },
      (response: HttpResponse<EmployeeData[]>) => {
        setEmployees(response.data);
      }
    );
  }, []);

  // const handleSingleAssign = (value: number) => {
  //   console.log("Employee ID", value);
  //   window.console.log("Test message");
  //   console.log("Training Title", title);
  //   handleCloseDialog();
  //   toast({
  //     message: "Training assigned to Employee",
  //     variant: "success",
  //   });
  // };

  const url = process.env.REACT_APP_API_BASE_URL + `/training/employee`;

  const handleSingleAssign = async (employee: any) => {
    const requestBody = {
      reference: reference,
      employerRefId: Number(employee.employerRefId),
      customerId: Number(employee.uuid),
      uuid: Number(employee.uuid),
      firstName: employee.firstName,
      lastName: employee.lastName,
      attendanceStatus: "ASSIGNED",
      image: "https://image.png",
      date: createdDate,
      startDate: startDate,
      endDate: endDate,
    };

    try {
      // console.log("Employee ID", employee);
      // alert(employee.employerRefId);
      // console.log("Training Ref", reference);
      // alert(reference);
      // alert(startDate);

      console.log("requestBody", requestBody);

      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(requestBody),
      });

      if (response.ok) {
        // Handle success
        handleCloseDialog();
        toast({
          message: "Training assigned to Employee",
          variant: "success",
        });
      } else {
        const errorData = await response.json();
        // console.error("Request submission failed:", errorData);
        // console.log("requestBody", requestBody);
        handleCloseDialog();
        toast({
          message: `${errorData.error}`,
          variant: "error",
        });
      }
    } catch (error) {
      console.error("Error in handleSingleAssign:", error);
    }
  };

  return (
    <React.Fragment>
      <Box
        width={{ xs: "full", sm: "294px" }}
        height={"396px"}
        my={1}
        position={"relative"}
        className=" rounded-md drop-shadow-md shadow-md place-content-center"
      >
        <Box
          className=" trainingHeader relative rounded-t-md"
          //   bgcolor={"#D2E0CB"}
          bgcolor={
            trainingMode?.toLowerCase() === "online"
              ? "#C7D8E4"
              : trainingMode?.toLowerCase() === "in-person"
              ? "#D2E0CB"
              : trainingMode?.toLowerCase() === "on the job"
              ? "#E3CEAE"
              : "#D2E0CB"
          }
          height={"53px"}
          width={{ xs: "full", sm: "294px" }}
        >
          <Box className=" absolute left-4 top-5">
            {trainingMode?.toLowerCase() === "online" ? (
              <TrainOnline />
            ) : trainingMode?.toLowerCase() === "in-person" ? (
              <TrainInPerson />
            ) : trainingMode?.toLowerCase() === "on the job" ? (
              <TrainOnJob />
            ) : null}
          </Box>
          <Typography
            variant="body2"
            fontSize={{ xs: "12px", sm: "14px", md: "14px" }}
            lineHeight={{ xs: "29px", sm: "29px" }}
            width={{ xs: "full", sm: "264px", md: "264px" }}
            textAlign={{ xs: "center", sm: "center" }}
            color="primary"
            className=" absolute inset-x-2 top-5"
            gutterBottom
            ml={{ xs: 1, sm: 0 }}
          >
            {trainingMode?.toLowerCase() === "online"
              ? "Online Training"
              : trainingMode?.toLowerCase() === "in-person"
              ? "In-Person Training"
              : trainingMode?.toLowerCase() === "on the job"
              ? "On-the-Job Training"
              : ""}
          </Typography>
          {currentPath.includes("/employee") ? null : (
            <Checkbox
              checked={checked}
              onChange={handleChanges}
              inputProps={{ "aria-label": "controlled" }}
              className=" absolute top-2 left-auto right-2"
            />
          )}
        </Box>
        <Box
          className=" trainingBody relative"
          bgcolor={""}
          height={"273px"}
          width={{ xs: "full", sm: "294px" }}
          pt={5}
        >
          <Typography
            variant="subtitle1"
            fontSize={{ xs: "12px", sm: "14px", md: "14px" }}
            lineHeight={{ xs: "29px", sm: "29px" }}
            // width={{ xs: "300px", sm: "264px", md: "264px" }}
            textAlign={{ xs: "start", sm: "start" }}
            ml={{ xs: 5, sm: 5 }}
            height="50px"
            color="primary"
            mb={3}
            className=" line-clamp-2"
            gutterBottom
          >
            {title}
          </Typography>
          {objects &&
            objects.map((text: string, index: any) => (
              <Typography
                variant="body2"
                fontSize={{ xs: "12px", sm: "14px", md: "14px" }}
                lineHeight={{ xs: "29px", sm: "29px" }}
                width={{ xs: "300px", sm: "264px", md: "264px" }}
                textAlign={{ xs: "start", sm: "start" }}
                color="primary"
                className=" capitalize"
                ml={{ xs: 5, sm: 5 }}
                gutterBottom
                key={index}
              >
                • {" " + text}
              </Typography>
            ))}
          <Divider className=" mt-5" />
          <Box className=" flex space-x-3 " ml={{ xs: 5, sm: 5 }} mt={1}>
            <CalendarIcon />
            <Typography
              variant="body1"
              fontSize={{ xs: "10px", sm: "10px", md: "10px" }}
              lineHeight={{ xs: "22px", sm: "22px" }}
              width={{ xs: "300px", sm: "264px", md: "264px" }}
              //   textAlign={{ xs: "start", sm: "start" }}
              color="#A9A9A9"
              className="capitalize"
              gutterBottom
            >
              {convertUTCToWordDate(createdDate)} | {startDate} - {endDate}
            </Typography>
          </Box>
        </Box>
        <Box
          className=" trainingBottom flex justify-between items-center rounded-b-md"
          bgcolor={"#ffff"}
          height={"70px"}
          width={{ xs: "full", sm: "294px" }}
        >
          {currentPath.includes("/employee") ? (
            <Box className=" mx-auto">
              <Box
                width={{
                  xs: "100%",
                  sm: "221px",
                }}
              >
                <MHButton
                  color="primary"
                  sx={{ borderRadius: "5px" }}
                  type="submit"
                  className="  "
                  fullWidth
                  onClick={(event) =>
                    handleViewDetails(event as MouseEvent<HTMLButtonElement>)
                  }
                >
                  View Details
                </MHButton>
              </Box>
            </Box>
          ) : (
            <Box
              px={2}
              className=" flex  justify-between items-center "
              width={{ xs: "100%", sm: "100%", md: "100%" }}
            >
              <Typography
                variant="h1"
                fontSize={{ xs: "16px", sm: "19px", md: "19px" }}
                lineHeight={{ xs: "120.5%", sm: "120.5%" }}
                //   textAlign={{ xs: "start", sm: "start" }}
                color="primary"
                className="flex align-middle"
                gutterBottom
              >
                {price === 0 ? (
                  ""
                ) : (
                  <NairaIcon className=" self-center h-4 w-4 mt-[2px]" />
                )}
                {price === 0 ? "FREE" : "" + price}
              </Typography>
              <MuiLink
                // component={Link}
                underline="always"
                // to=""
                onClick={handleOpenDialog}
                // display="block"
                // align="left"
                className="text-blue-100 removeUnderline cursor-pointer"
              >
                Assign
              </MuiLink>
            </Box>
          )}
        </Box>
      </Box>
      <Dialog
        disableEscapeKeyDown
        open={openDialog}
        onClose={handleCloseDialog}
        sx={{ height: "100%" }}
      >
        {openDialog && (
          <Box
            height={{ xs: "full", sm: "100%" }}
            width={{ xs: "full", sm: "350px" }}
            p={5}
            mx={"auto"}
          >
            <Typography
              variant="subtitle1"
              fontSize={{ xs: "12px", sm: "14px", md: "14px" }}
              lineHeight={{ xs: "29px", sm: "29px" }}
              // width={{ xs: "300px", sm: "264px", md: "264px" }}
              textAlign={{ xs: "start", sm: "start" }}
              color="primary"
              className=""
              gutterBottom
            >
              Assign course(s) to Household
            </Typography>
            <Typography
              variant="subtitle2"
              fontSize={{ xs: "10px", sm: "12px", md: "12px" }}
              lineHeight={{ xs: "21px", sm: "21px" }}
              // width={{ xs: "300px", sm: "264px", md: "264px" }}
              textAlign={{ xs: "start", sm: "start" }}
              color="#A9A9A9"
              className=""
              gutterBottom
            >
              Search for staff by name and assign them to the selected course{" "}
            </Typography>
            <Autocomplete
              id="employee-select-1sdd"
              sx={{
                width: "fit",
                mt: 2,
                height: "100%",
              }}
              options={employees}
              autoHighlight
              getOptionLabel={(option) => option.firstName || ""}
              renderOption={(item, option) => (
                <Box
                  component="li"
                  //   height={{ xs: "200px", sm: "200px" }}
                  sx={{
                    display: "flex",
                    gap: 2,
                    justifyContent: "space-between",
                  }}
                  padding={2}
                  {...item}
                  className=" space-x-6"
                >
                  <Typography
                    variant="subtitle2"
                    fontSize={{ xs: "12px", sm: "12px", md: "12px" }}
                    lineHeight={{ xs: "21px", sm: "21px" }}
                    textAlign={{ xs: "start", sm: "start" }}
                    color="primary"
                    className=""
                    gutterBottom
                  >
                    {option.firstName}
                  </Typography>
                  <Button
                    // onClick={() => handleSingleAssign(option.uuid || 0)}
                    onClick={() => handleSingleAssign(option || [])}
                    className="ring-1 removeUnderline"
                    sx={{ border: 1, borderRadius: 3, fontSize: "12px" }}
                  >
                    Assign
                  </Button>
                </Box>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={
                    <Typography
                      variant="subtitle2"
                      fontSize={{ xs: "10px", sm: "10px", md: "10px" }}
                      lineHeight={{ xs: "21px", sm: "21px" }}
                      // width={{ xs: "300px", sm: "264px", md: "264px" }}
                      textAlign={{ xs: "start", sm: "start" }}
                      color="#A9A9A9"
                      className=""
                      gutterBottom
                    >
                      Search for employee
                    </Typography>
                  }
                  inputProps={{
                    ...params.inputProps,
                    autoComplete: "employee-to-be-assigned", // disable autocomplete and autofill
                  }}
                  sx={{}}
                />
              )}
            />
          </Box>
        )}
      </Dialog>
    </React.Fragment>
  );
};
export default TrainingCard;
