import React, { Fragment } from "react";
import { Link, useHistory } from "react-router-dom";

import Box from "@mui/material/Box";
import Zoom from "@mui/material/Zoom";
import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";

import { ReactComponent as PlayIcon } from "../../../static/svg/play.svg";
import RoundedLogoIcon from "../../../theme/icons/RoundedLogo";
import StackedColumn from "../../Common/UI/StackedColumn";
import { ItemList } from "../../../models/resources.interface";

export type CardProps = {
  cardClass?: string;
  iconClass?: string;
  createdAt?: string | undefined;
  imageSrc: string | undefined;
  podTop?: string;
  eventDate?: string;
  season?: string;
  episode?: string;
  title: string | undefined;
  text?: string | undefined;
  category?: ItemList[];
  imageBackground?: string;
  cardContentBg: string;
  linkTo: string;
  playUrl: string;
  blackOverlay?: boolean;
  video?: boolean;
  podcast?: boolean;
  topArticleCategory?: string;
};

const ResourceCard = (props: CardProps) => {
  const history = useHistory();

  let CONTENT_HEIGHT = props.text ? 530 : 470;

  if (props.podcast || props.eventDate) {
    CONTENT_HEIGHT = 490;
  }

  return (
    <Fragment>
      <Zoom in style={{ transitionDelay: "300ms" }}>
        <Card
          className={`${props.cardClass}`}
          sx={{
            minHeight: CONTENT_HEIGHT,
            width: "100%",
            position: "relative",
            borderRadius: 2,
            objectFit: "cover",
            bgcolor: props.cardContentBg || "#F9F9F7",
            alignSelf: "stretch",
            cursor: "pointer",
          }}
          onClick={() => {
            history.push(props.linkTo);
          }}
          raised
        >
          <StackedColumn height={CONTENT_HEIGHT + "px !important"}>
            <Box position="relative" height={340}>
              <CardMedia
                component="img"
                // image="https://res.cloudinary.com/mother-honestly/image/upload/v1665657584/employers-banner_xbxbyu.png"
                image={props.imageSrc}
                alt="Resource Image"
                sx={{
                  bgcolor: props.imageBackground,
                  height: 340,
                }}
              />

              {props.blackOverlay && (
                <Box
                  sx={{
                    position: "absolute",
                    width: "100%",
                    height: "100%",
                    left: "50%",
                    top: "50%",
                    transform: "translate(-50%, -50%)",
                    bgcolor: "#194049",
                    opacity: "72%",
                  }}
                ></Box>
              )}
              {props.video && (
                <RoundedLogoIcon
                  sx={{
                    position: "absolute",
                    width: "4rem",
                    height: "4rem",
                    left: "50%",
                    top: "50%",
                    transform: "translate(-50%, -50%)",
                  }}
                >
                  <PlayIcon />
                </RoundedLogoIcon>
              )}
            </Box>

            <CardContent sx={{ flexGrow: 1 }}>
              {props.eventDate && (
                <Typography
                  variant="subtitle1"
                  textTransform="uppercase"
                  fontSize=".75rem"
                  gutterBottom
                >
                  {props.eventDate}
                </Typography>
              )}
              {props.podcast && (
                <div>
                  <Typography
                    variant="subtitle1"
                    textTransform="uppercase"
                    fontSize=".75rem"
                    gutterBottom
                  >
                    Episode {props.season}:{props.episode}
                  </Typography>
                </div>
              )}
              {props.topArticleCategory && (
                <div>
                  <Typography
                    variant="subtitle1"
                    textTransform="uppercase"
                    fontSize=".6rem"
                    className="line-clamp-1"
                    gutterBottom
                  >
                    {props.topArticleCategory ===
                    "driver/chauffeur,chef/cook,nanny,pet caregiver,domestic couple,laundress,butler,adult caregiver,personal assistant,security guard,home tutor,gardener,housekeeper"
                      ? "GENERAL"
                      : props.topArticleCategory}
                  </Typography>
                </div>
              )}

              <Link to={props.linkTo} className="no-underline">
                <Typography
                  className="line-clamp-2"
                  variant="subtitle2"
                  textTransform="capitalize"
                  fontSize=".875rem"
                  lineHeight="1.25rem"
                  paragraph
                  sx={{
                    whiteSpace: "initial",
                    visibility: "visible",
                  }}
                >
                  {props.title}
                </Typography>
              </Link>

              <Typography
                className="line-clamp-2 leading-[22px]"
                variant="body2"
                fontSize=".75rem"
                maxWidth="100%"
                sx={{
                  whiteSpace: "initial",
                  visibility: "visible",
                }}
              >
                {props.text}
              </Typography>
            </CardContent>

            <Divider light variant="middle" />

            <CardActions
              sx={{
                px: 2,
                py: "12px",
              }}
            >
              {props.category ? (
                <Typography
                  variant="body2"
                  fontSize=".75rem"
                  className="truncate line-clamp-1"
                  // lineHeight="180%"
                  textTransform="capitalize"
                  title={props.category
                    .map((item) => item.interestName)
                    .join(", ")}
                >
                  {props.category.map((item) => item.interestName).join(", ")}
                </Typography>
              ) : null}
            </CardActions>
          </StackedColumn>
        </Card>
      </Zoom>
    </Fragment>
  );
};

export default ResourceCard;
