import React from "react";

import Box from "@mui/material/Box";

import MHDataTable, { GridColDef } from "../../Common/DataTable/MHDataTable";
// import { TransactionProps } from "../../../models/employee.model";
import useHttp from "../../../hooks/use-http";
import { HttpResponse } from "../../../models/api.interface";
import BackdropLoader from "../../Common/Loading/BackdropLoader";
import SnackbarContext from "../../../store/context/snackbar.context";
import { convertUTCToDate, getURLWithQueryParams } from "../../../utils/utils";
import AuthContext from "../../../store/context/auth-context";
import { ReactComponent as SearchIcon } from "../../../static/svg/search.svg";
import { Typography } from "@mui/material";
import MHFormControl from "../../Common/Form/MHFormControl";
import InputAdornment from "../../Common/Form/InputAdornment";
import DashboardContext from "../../../store/context/dashboard.context";
import { TransactionProps } from "../../../models/transaction.model";

const TransactionForEmployer = () => {
  const [transactions, setTransactions] = React.useState<TransactionProps[]>(
    []
  );

  const [searchQuery, setSearchQuery] = React.useState<string>("");

  const columns: GridColDef<TransactionProps>[] = [
    {
      headerName: "Date",
      type: "text",
      field: "date",
      width: 100,
      cellRenderer: (row: TransactionProps) => (
        <Typography
          variant="body1"
          color="primary"
          fontSize={{ xs: "10px", sm: "10px", md: "10px" }}
          lineHeight={{ xs: "100%", sm: "100%" }}
          letterSpacing={"0.01em"}
          alignSelf={"center"}
          className=" opacity-40 capitalize"
        >
          {convertUTCToDate(row?.CreatedDate)}
        </Typography>
      ),
    },
    {
      headerName: "Reference",
      type: "text",
      field: "reference",
      width: 400,
      cellRenderer: (row: TransactionProps) => (
        <Typography
          variant="body1"
          color="primary"
          fontSize={{ xs: "10px", sm: "10px", md: "10px" }}
          lineHeight={{ xs: "120%", sm: "120%" }}
          width={400}
          height="auto"
          letterSpacing={"0.01em"}
          alignSelf={"center"}
          noWrap
          className=" line-clamp-1 "
        >
          {row?.reference}
        </Typography>
      ),
    },

    {
      headerName: "Bank Name",
      type: "text",
      field: "bankName",
      width: 200,
      valueGetter: (row: TransactionProps) => {
        return row?.bankName;
      },
    },

    {
      headerName: "Account Number",
      type: "text",
      field: "accountNumber",
      width: 400,
      valueGetter: (row: TransactionProps) => {
        return row?.accountNumber;
        // Hi, {authCtx.user?.firstName}
      },
    },
    {
      headerName: "Amount",
      type: "text",
      field: "amount",
      width: 200,
      valueGetter: (row: TransactionProps) => {
        return "₦" + row?.amount;
      },
    },

    // {
    //   headerName: "Processed",
    //   type: "text",
    //   field: "processed",
    //   width: 100,
    //   valueGetter: (row: TransactionProps) => {
    //     return row?.processed;
    //   },
    // },
    {
      headerName: "Type",
      type: "text",
      field: "type",
      width: 100,
      valueGetter: (row: TransactionProps) => {
        return row?.type;
      },
    },
  ];

  const dashboardCtx = React.useContext(DashboardContext);
  const { organization } = dashboardCtx;

  const authCtx = React.useContext(AuthContext);
  const { user } = authCtx;
  const snackbarCtx = React.useContext(SnackbarContext);
  const { toast } = snackbarCtx;

  const { loading, error, sendHttpRequest } = useHttp();

  // console.log("customerId", organization?.customerId);

  // console.log("transactions", transactions);

  React.useEffect(() => {
    sendHttpRequest(
      getURLWithQueryParams(
        process.env.REACT_APP_API_BASE_URL + "/tranx/history",
        {
          customerId: String(organization?.customerId),
          // fromDate: String(startDate),
          // toDate: String(presentDate),
        }
      ),
      {
        method: "GET",
      },
      (response) => {
        setTransactions(response.data);
      }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organization]);

  React.useEffect(() => {
    if (error) {
      toast({
        variant: "error",
        message: error.message,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  // const filteredEmployees = employees.filter(
  //   (employee) =>
  //     employee.firstName &&
  //     employee.firstName.toLowerCase().includes(searchQuery.toLowerCase())
  // );

  const filteredTransactions = transactions
    ? transactions.filter(
        (trans) =>
          (trans.reference &&
            trans.reference
              .toLowerCase()
              .includes(searchQuery.toLowerCase())) ||
          (trans.bankName &&
            trans.bankName.toLowerCase().includes(searchQuery.toLowerCase()))
      )
    : [];

  return (
    <React.Fragment>
      <BackdropLoader open={loading} />
      <Box className=" flex justify-center">
        <Typography
          variant="h1"
          fontSize={{ xs: "20px", sm: "30px" }}
          align="center"
          mt={3}
          gutterBottom
          display={"flex"}
          alignSelf={"center"}
        >
          Transactions
        </Typography>
      </Box>
      <Box className=" w-[226px] mr-0 ml-auto">
        <MHFormControl
          id="text"
          type="text"
          label=""
          placeholder="Search transactions"
          onChange={(event) => setSearchQuery(event.target.value)}
          startAdornment={
            <InputAdornment>
              <SearchIcon width="1rem" />
            </InputAdornment>
          }
          required
          // autoFocus
        />
      </Box>
      <MHDataTable
        title=""
        rows={searchQuery ? filteredTransactions : transactions}
        columns={columns}
        frontEndPagination
        // hidePagination
      />
    </React.Fragment>
  );
};

export default TransactionForEmployer;
