import React from "react";
import { TrainingHeader } from "../../components/Features/Training/TrainingHeader";
import TrainingModule from "../../components/Features/Training/TrainingModule";
import { testTraining } from "../../utils/constants";
import TrainingPastVideos from "../../components/Features/Training/TrainingPastVideos";
import { TrainingData } from "../../components/Features/Training/TrainingCard";
import { HttpResponse } from "../../models/api.interface";
import { getURLWithQueryParams } from "../../utils/utils";
import useHttp from "../../hooks/use-http";
type Props = {};
const Training = (props: Props) => {
  const [selectedRole, setSelectedRole] = React.useState("");
  const [training, setTraining] = React.useState<TrainingData[]>([]);
  const { loading, error, sendHttpRequest } = useHttp();
  const handleRoleChange = (role: string) => {
    setSelectedRole(role);
  };
  React.useEffect(() => {
    sendHttpRequest(
      getURLWithQueryParams(
        process.env.REACT_APP_API_BASE_URL + "/training/all",
        {
          employerRefId: String("ABC"),
        }
      ),
      {
        method: "GET",
      },
      (response: HttpResponse<TrainingData[]>) => {
        setTraining(response.data);
      }
    );
  }, []);
  const filteredTraining =
    training && training.filter((train) => train.status !== "INACTIVE");

  // console.log("trainings", training);

  // console.log("filteredTraining", filteredTraining);
  return (
    <React.Fragment>
      <TrainingHeader
        onRoleChange={handleRoleChange}
        title="A trained Household staff is a win for the family."
        content="Having a trained household staff is more than just a practical
            solution – it's a lifeline of warmth, compassion, and support for
            your family."
      />
      <TrainingModule selectedRole={selectedRole} dataIn={filteredTraining} />
      <TrainingPastVideos dataIn={training} />
    </React.Fragment>
  );
};
export default Training;
